<template>
  <div class="future-alego-section">
    <div class="container">
      <div class="title-section">
        <h2 class="section-title">Le futur d’Alego</h2>
      </div>
      <div class="content-section">
        <div class="card">
          <div class="card-content">
            <h3 class="card-title">Planification de Roadtrips : l’aventure sans limites 🚗</h3>
            <p class="card-text">
              Prochainement, Alego te permettra de planifier des roadtrips personnalisés, adaptés à ton rythme et à tes envies. De la sélection des meilleures routes panoramiques aux arrêts incontournables, en passant par la découverte des pépites locales, nous te guiderons pour transformer chaque trajet en une expérience unique. Plus besoin de te perdre dans des cartes ou des guides, Alego s’occupe de tout pour que tu profites pleinement de la route.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <h3 class="card-title">Application de Découverte en Temps Réel : ton guide personnel sur place 📱</h3>
            <p class="card-text">
              Nous travaillons également sur le développement d’une application qui t’accompagne même après avoir réservé ton voyage. Grâce à cette app, tu pourras découvrir instantanément des activités autour de toi qui correspondent à tes critères et à tes envies du moment. Que tu aies envie de déguster la meilleure cuisine locale, de trouver une randonnée secrète ou d’assister à un événement culturel, Alego sera ton allié sur le terrain pour des expériences en parfaite adéquation avec tes goûts.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <h3 class="card-title">Alego, ton compagnon de voyage au quotidien ✨</h3>
            <p class="card-text">
              Nous ne voulons pas juste t’aider à réserver ton voyage ; nous voulons être là tout au long de ton aventure. Alego évolue pour devenir ton compagnon de voyage idéal, celui qui comprend tes attentes et qui sait te surprendre, avant, pendant, et même après ton périple.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AboutUsSecond',
};
</script>


<style scoped>
.future-alego-section {
  width: 80%;
  margin: auto;
  padding: 100px 0 60px 0;
}

.container {
  display: flex;
  align-items: flex-start;
}

.title-section {
  flex: 1;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--dark-gray);
  margin-bottom: 20px;
}

.section-title::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background-color: var(--dark-gray);
  margin-top: 10px;
  border-radius: 2px;
}

.content-section {
  flex: 2;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.content-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card {
  margin-bottom: 1.5rem;
}

.card-content {
  padding: 0;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
  color: var(--dark-gray);
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 0.8rem;
  color: var(--dark-gray);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .future-alego-section {
    width: 80%;
    margin: auto;
    padding: 30px 0;
  }

  .section-title::after {
    margin: 20px auto;
  }

  .title-section {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .content-section {
    width: 85%;
    padding: 30px;
  }

  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.8rem;
    text-align: justify;
  }
}

</style>


