<template>
  <div id="app">
    <SiteHeader v-if="!isAccueilPage" />
    <router-view />
    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import { useHead } from '@vueuse/head';

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  },
  computed: {
    isAccueilPage() {
      return this.$route.name === 'Accueil'
    }
  },
  setup() {
    useHead({
      title: 'Alego: Planifiez vos voyages personnalisés grâce à l’IA',
      meta: [
        {
          property: 'og:title',
          content: 'Alego: Planifiez vos voyages personnalisés grâce à l’IA'
        },
        {
          property: 'og:site_name',
          content: 'Alego'
        },
        {
          property: 'og:description',
          content: "Simplifiez l'organisation de vos vacances en quelques clics : trouvez les meilleures destinations, hôtels, options de transport et activités en fonction de vos centres d'intérêts, budget et dates."
        },
        {
          name: 'description',
          content: "Simplifiez l'organisation de vos vacances en quelques clics : trouvez les meilleures destinations, hôtels, options de transport et activités en fonction de vos centres d'intérêts, budget et dates."
        }
      ],
      link: [
        {
          rel: 'icon',
          href: 'favicon.ico'
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Alego",
            "url": "https://alego.app",
            "logo": "https://alego.app/favico.ico",
            "sameAs": [
              "https://www.facebook.com/alegoapp",
              "https://www.instagram.com/alegoapp",
              "https://www.twitter.com/alegoapp"
            ],
            "description": "Simplifiez l'organisation de vos vacances en quelques clics : trouvez les meilleures destinations, hôtels, options de transport et activités en fonction de vos centres d'intérêts, budget et dates.",
            "contactPoint": {
              "@type": "ContactPoint",
              "contactType": "Customer service",
              "email": "contact@alego.app",
              "url": "http://alego.app/about"
            }
          }
        },
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://alego.app",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://alego.app/voyage",
              "query-input": "required name=voyage"
            }
          }
        },
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "SiteNavigationElement",
            "name": "Créer mon voyage",
            "url": "https://alego.app/voyage",
            "description": "Donne nous ton budget et tes envies et Alego s'occupe de te trouver une destination parfaitement adaptée !"
          }
        },
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "SiteNavigationElement",
            "name": "A propos",
            "url": "https://alego.app/about",
            "description": "Alego est en constante évolution ! Viens voir ce qu'on prépare pour la suite et nous donner ton avis !"
          }
        }
      ]
    });
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 0;
  background-color: var(--light-gray);
}

router-view {
  flex: 1;
}
</style>
