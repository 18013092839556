<template>
  <div class="testimonial-block">

    <div class="separator-title">
      <div class="separator-accueilStep4"></div>
      <h2 class="title">Notre équipe fondatrice</h2>
    </div>

    <div class="testimonials-desktop">
      <div class="testimonial-row">
        <div class="testimonial">
          <div class="profile-pic">
            <img src="@/assets/img/landing/maxime.jpg" alt="Profile Picture 1">
          </div>
          <div class="testimonial-content">
            <p>“J'ai toujours adoré voyager mais depuis que j'ai commencé à travailler, le temps me manque pour tout organiser. Passer des soirées entières à comparer les vols, les hébergements, les activités et à jongler entre les différents sites web devenait une véritable corvée.”</p>
            <p class="author">Maxime - Cofondateur et président</p>
          </div>
        </div>
        <img src="@/assets/img/landing/quote.png" class="quote-icon" style="transform: rotate(180deg);" alt="Quote Icon">
      </div>
      
      <div class="testimonial-row">
        <img src="@/assets/img/landing/quote.png" class="quote-icon" alt="Quote Icon">
        <div class="testimonial">
          <div class="testimonial-content">
            <p>“Quand j'organisais mes voyages, je savais ce que je voulais (un budget serré, une escapade à la mer entre amis, un séjour romantique en montagne...) mais trouver l'offre qui remplissait tous les critères relevait du parcours du combattant.”</p>
            <p class="author">Emile - Cofondateur et directeur général</p>
          </div>
          <div class="profile-pic">
            <img src="@/assets/img/landing/emile.jpg" alt="Profile Picture 2">
          </div>
        </div>
      </div>
    </div>

    <div class="testimonials-mobile">
      <div class="testimonial-mobile">
        <div class="profile-pic-mobile">
          <img src="@/assets/img/landing/maxime.jpg" alt="Profile Picture 1">
        </div>
        <div class="testimonial-content-mobile">
          <p>“J'ai toujours adoré voyager mais depuis que j'ai commencé à travailler, le temps me manque pour tout organiser. Passer des soirées entières à comparer les vols, les hébergements, les activités et à jongler entre les différents sites web devenait une véritable corvée.”</p>
          <p class="author">Maxime - Cofondateur et président</p>
        </div>
      </div>
      <div class="testimonial-mobile">
        <div class="profile-pic-mobile">
          <img src="@/assets/img/landing/emile.jpg" alt="Profile Picture 2">
        </div>
        <div class="testimonial-content-mobile">
          <p>“Quand j'organisais mes voyages, je savais ce que je voulais (un budget serré, une escapade à la mer entre amis, un séjour romantique en montagne...) mais trouver l'offre qui remplissait tous les critères relevait du parcours du combattant.”</p>
          <p class="author">Emile - Cofondateur et directeur général</p>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'AccueilStep4',

}
</script>

<style scoped>
.title {
  color: var(--vert); /* Couleur adaptée au design */
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.separator-title {
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.separator-accueilStep4 {
  height: 4px;
  border-radius: 25px;
  width: 40%;
  background-color: var(--jaune);
  margin-bottom: 40px;
}

/* Styles pour la version desktop */
.testimonials-desktop {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.testimonial-block {
  width: 70%; /* Le conteneur global prend 70% de la largeur */
  margin: 0 auto 100px auto;
}

.testimonial-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Pour permettre le positionnement absolu de l'image */
}

.testimonial {
  background-color: #7a9fa1; /* Couleur du fond du témoignage */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 20px;
  width: 70%; /* Chaque témoignage prend 70% de la largeur de la ligne */
  position: relative; /* Pour permettre le positionnement absolu de l'image */
}

.profile-pic {
  position: absolute;
  top: -40px;
  right: -40px;
  border-radius: 50%;
  padding: 0px; /* Un petit padding pour un effet de bordure blanche */
}

.testimonial-row:nth-child(2) .profile-pic {
  left: -40px;
}

.profile-pic img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4px solid var(--vert); /* Bordure autour de la photo */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.testimonial-content {
  margin-right: 60px; /* Décalage pour le second témoignage */
  margin-left: 20px;
  flex: 1;
}

.testimonial-row:nth-child(2) .testimonial-content {
  margin-left: 60px;
}

.testimonial-content p {
  margin: 0;
  color: #333;
  font-size: 0.9rem;
}

.testimonial-content .author {
  margin-top: 10px;
  font-weight: bold;
  color: #000;
}

.quote-icon {
  width: 90px;
  height: 90px;
  opacity: 0.7;
}

/* Styles pour la version mobile */
.testimonials-mobile {
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 30px;
  width: 100%;
}

.testimonial-mobile {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(18, 71, 75, 0.3);
}

.profile-pic-mobile {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.profile-pic-mobile img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-content-mobile p {
  font-size: 0.8rem;
  margin: 10px 0;
  text-align: justify;
}

.testimonial-content-mobile .author {
  font-weight: bold;
  font-size: 0.9rem;
  margin-top: 20px;
  text-align: center;
}

/* Media Queries */
@media (max-width: 768px) {
  .testimonials-desktop {
    display: none;
  }

  .testimonials-mobile {
    display: flex;
  }

  .title {
    color: var(--vert); /* Couleur adaptée au design */
    font-size: 1.3rem;
    margin: 0px auto;
    text-align: center;
  }

  .separator-title {
    margin: 0 auto 20px auto;
  }

}
</style>

