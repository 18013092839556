// loadHotelRates.js

export async function loadHotelRates(data) {
    try {
        // Définir l'URL de l'API
        const apiUrl = 'https://europe-west1-alegotravel.cloudfunctions.net/api/checkHotelRates';

        // Appeler l'API avec les données fournies
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        // Vérifier si la réponse est OK
        if (!response.ok) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        // Retourner directement le résultat de l'API
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error fetching hotel rates:', error);
        return { 
            dailyPrice: 0, 
            paymentAmount: 0
        };
    }
}

