<template>
  <div class="activities-questions">
    <!-- Overlay de chargement -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-content">
        <p>Chargement de la liste d'activités</p>
        <img src="https://media.tenor.com/wpSo-8CrXqUAAAAi/loading-loading-forever.gif" width="24" height="24" alt="Chargement...">
      </div>
    </div>
    <div class="progress-bar">
      <div
        v-for="(step, index) in steps"
        :key="index"
        :class="['progress-step', { 'progress-step-active': index <= currentStep }]"
      ></div>
    </div>
    <div class="questions-container" v-if="currentStep === 0">
      <h2>Quel est le type de séjour ?</h2>
      <div class="options">
        <div
          v-for="(option, index) in options"
          :key="index"
          :class="['option', { 'option-selected': selectedOption === option.value }]"
          @click="selectOption(option.value)"
        >
          <img :src="getIcon(option.value)" :alt="option.label" />
          <p>{{ option.label }}</p>
        </div>
      </div>
    </div>
    <div class="questions-container" v-if="currentStep === 1">
      <h2>Combien d'activités souhaites-tu faire par jour ?</h2>
      <div class="options activities-options">
        <div
          v-for="(activityOption, index) in activityOptions"
          :key="index"
          :class="['option activity-option', { 'option-selected': selectedActivityOption === activityOption.value }]"
          @click="selectActivityOption(activityOption.value)"
        >
          <p>{{ activityOption.label }}</p>
        </div>
      </div>
    </div>
    <div class="questions-container" v-if="currentStep === 2">
      <h2>Quels types d'activités souhaites-tu faire ?<br /><span>(tu peux faire plusieurs choix)</span></h2>
      <div class="activityTypes">
        <div
          v-for="(typeOption, index) in typeOptions"
          :key="index"
          :class="['option', { 'option-selected': selectedTypeOptions.includes(typeOption.value) }]"
          @click="toggleTypeOption(typeOption.value)"
        >
          <img :src="getTypeIcon(typeOption.value)" :alt="typeOption.label" />
          <p>{{ typeOption.label }}</p>
        </div>
      </div>
    </div>
    <div class="questions-container" v-if="currentStep === 3">
      <h2>Quel type d'activités pouvons-nous te proposer ?</h2>
      <div class="options cost-options">
        <div
          v-for="(costOption, index) in costOptions"
          :key="index"
          :class="['option', 'cost-option', { 'option-selected': selectedCostOption === costOption.value }]"
          @click="selectCostOption(costOption.value)"
        >
          <img :src="getCostIcon(costOption.value)" :alt="costOption.label" />
          <p>{{ costOption.label }}</p>
        </div>
      </div>
    </div>
    <div class="questions-container" v-if="currentStep === 4">
      <h2>Souhaites-tu faire uniquement des activités dans la ville ?</h2>
      <div class="options city-options">
        <div
          v-for="(cityOption, index) in cityOptions"
          :key="index"
          :class="['option', 'city-option', { 'option-selected': selectedCityOption === cityOption.value }]"
          @click="selectCityOption(cityOption.value)"
        >
          <img :src="getCityIcon(cityOption.value)" :alt="cityOption.label" :class="{ rotate: cityOption.value === 'no' }" />
          <p>{{ cityOption.label }}</p>
        </div>
      </div>
    </div>
    <div class="questions-container" v-if="currentStep === 5">
      <h2>Avant de créer ton planning d'activités, as-tu des activités en tête ?</h2>
      <p class="note">Tu n'es pas obligé de préciser une activité, il s'agit juste d'inclure celles que tu veux absolument faire en priorité !</p>
      <div class="activities-inputs">
        <div
          class="activity-input-container"
          v-for="(activity, index) in activities"
          :key="index"
        >
          <input
            type="text"
            v-model="activities[index]"
            class="activity-input"
            placeholder="Visite du Colisée"
          />
          <button
            class="remove-activity-button"
            @click="removeActivity(index)"
          >-</button>
        </div>
        <button
          class="add-activity-button"
          @click="addActivity"
        >+</button>
      </div>
    </div>
    <p v-if="error" class="error-message">{{ error }}</p>
    <div class="navigation-buttons">
      <button @click="goBack" class="btn-back">Retour</button>
      <button v-if="currentStep < steps.length - 1" @click="goNext" class="btn-next">Suivant</button>
      <button v-else @click="validate" class="btn-next">Valider</button>
    </div>
  </div>
</template>

<script>
import { fetchAndStoreProposedActivities } from '@/functions/storeActivitiesStyle.js';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase.js';

export default {
  name: "ActivitiesQuestions",
  props: {
    tripId: {
      type: String,
      required: true
    },
    activitiesDocument: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentStep: 0,
      steps: [0, 1, 2, 3, 4, 5],
      isLoading: false,
      selectedOption: '',
      selectedActivityOption: '',
      selectedTypeOptions: [],
      selectedCostOption: '',
      selectedCityOption: '',
      selectedRestaurantOption: '',
      activities: [''],
      tripType: '',
      error: '',
      options: [
        { label: 'Entre ami(e)s', value: 'friends' },
        { label: 'En couple', value: 'couple' },
        { label: 'En famille', value: 'family' },
        { label: 'Seul(e)', value: 'alone' }
      ],
      activityOptions: [
        { label: 'Entre 1 et 2', value: '1-2' },
        { label: 'Entre 2 et 3', value: '2-3' },
        { label: 'Entre 3 et 4', value: '3-4' },
      ],
      typeOptions: [
        { label: 'Balade', value: 'walk' },
        { label: 'Culture', value: 'culture' },
        { label: 'Repos', value: 'rest' },
        { label: 'Gastronomique', value: 'gastronomie' },
        { label: 'Loisirs', value: 'loisirs' },
      ],
      costOptions: [
        { label: 'Principalement gratuites', value: 'free' },
        { label: 'Payantes si nécessaire', value: 'paid' }
      ],
      cityOptions: [
        { label: 'Oui', value: 'yes' },
        { label: 'Non', value: 'no' }
      ],
      restaurantOptions: [
        { label: 'Oui', value: 'yes' },
        { label: 'Non', value: 'no' }
      ]
    };
  },
  methods: {
    selectOption(value) {
      this.selectedOption = value;
      this.error = ''; // Clear error when an option is selected
    },
    selectActivityOption(value) {
      this.selectedActivityOption = value;
      this.error = ''; // Clear error when an option is selected
    },
    toggleTypeOption(value) {
      const index = this.selectedTypeOptions.indexOf(value);
      if (index === -1) {
        this.selectedTypeOptions.push(value);
      } else {
        this.selectedTypeOptions.splice(index, 1);
      }
      this.error = ''; // Clear error when an option is selected
    },
    selectCostOption(value) {
      this.selectedCostOption = value;
      this.error = ''; // Clear error when an option is selected
    },
    selectCityOption(value) {
      this.selectedCityOption = value;
      this.error = ''; // Clear error when an option is selected
    },
    selectRestaurantOption(value) {
      this.selectedRestaurantOption = value;
      this.error = ''; // Clear error when an option is selected
    },
    addActivity() {
      this.activities.push('');
    },
    removeActivity(index) {
      this.activities.splice(index, 1);
    },
    goBack() {
      if (this.currentStep > 0) {
        this.currentStep--;
      }
    },
    goNext() {
      if (this.currentStep === 0) {
        if (this.selectedOption) {
          this.tripType = this.selectedOption;
          this.currentStep++;
          this.error = '';
        } else {
          this.error = 'Veuillez sélectionner une option avant de continuer.';
        }
      } else if (this.currentStep === 1) {
        if (this.selectedActivityOption) {
          this.currentStep++;
          this.error = '';
        } else {
          this.error = 'Veuillez sélectionner une option avant de continuer.';
        }
      } else if (this.currentStep === 2) {
        if (this.selectedTypeOptions.length > 0) {
          this.currentStep++;
          this.error = '';
        } else {
          this.error = 'Veuillez sélectionner au moins une option avant de continuer.';
        }
      } else if (this.currentStep === 3) {
        if (this.selectedCostOption) {
          this.currentStep++;
          this.error = '';
        } else {
          this.error = 'Veuillez sélectionner une option avant de continuer.';
        }
      } else if (this.currentStep === 4) {
        if (this.selectedCityOption) {
          this.currentStep++;
          this.error = '';
        } else {
          this.error = 'Veuillez sélectionner une option avant de continuer.';
        }
      } else if (this.currentStep === 5) {
        if (this.selectedRestaurantOption) {
          this.currentStep++;
          this.error = '';
        } else {
          this.error = 'Veuillez sélectionner une option avant de continuer.';
        }
      } else if (this.currentStep === 6) {
        this.currentStep++;
        this.error = '';
      }
    },
    getIcon(value) {
      switch (value) {
        case 'friends':
          return require('@/assets/img/activities/friends.png');
        case 'couple':
          return require('@/assets/img/activities/couple.png');
        case 'family':
          return require('@/assets/img/activities/family.png');
        case 'alone':
          return require('@/assets/img/activities/alone.png');
        default:
          return '';
      }
    },
    getTypeIcon(value) {
      switch (value) {
        case 'gastronomie':
          return require('@/assets/img/activities/gastronomie-yellow.png');
        case 'loisirs':
          return require('@/assets/img/activities/loisirs-yellow.png');
        case 'walk':
          return require('@/assets/img/activities/walk.png');
        case 'culture':
          return require('@/assets/img/activities/culture.png');
        case 'rest':
          return require('@/assets/img/activities/rest.png');
        case 'no_idea':
          return require('@/assets/img/activities/mystery.png');
        default:
          return '';
      }
    },
    getCostIcon(value) {
      switch (value) {
        case 'free':
          return require('@/assets/img/activities/free.png');
        case 'paid':
          return require('@/assets/img/activities/not-only-free.png');
        default:
          return '';
      }
    },
    getCityIcon(value) {
      switch (value) {
        case 'yes':
          return require('@/assets/img/activities/thumb-up.png');
        case 'no':
          return require('@/assets/img/activities/thumb-up.png'); // Same icon for 'no'
        default:
          return '';
      }
    },
    getRestaurantIcon(value) {
      switch (value) {
        case 'yes':
          return require('@/assets/img/activities/thumb-up.png');
        case 'no':
          return require('@/assets/img/activities/thumb-up.png'); // Same icon for 'no'
        default:
          return '';
      }
    },

    async validate() {
      this.isLoading = true;
      const activitiesProfile = {
        whoWith: this.selectedOption,
        activityFrequency: this.selectedActivityOption,
        activityType: this.selectedTypeOptions.join(', '),
        freeOrPaid: this.selectedCostOption,
        onlyInTheCity: this.selectedCityOption,
        needRestaurant: this.selectedRestaurantOption,
        wantedActivitiesList: this.activities.join(', '),
      };

      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          let activityDocRef;
          let activitiesDocumentId = this.activitiesDocument;

          if (activitiesDocumentId) {
            activityDocRef = doc(db, "activitiesInformations", activitiesDocumentId);
          } else {
            // Créer un nouveau document `activitiesInformations` si `activitiesDocumentId` est vide
            const newDocRef = await addDoc(collection(db, "activitiesInformations"), {
              tripId: this.tripId,
              userId: user.uid,
              ...activitiesProfile,
            });
            activitiesDocumentId = newDocRef.id; // Utilisez l'ID du nouveau document créé
            activityDocRef = newDocRef;
          }
          
          // Récupérer le document existant pour obtenir le planning
          const activitySnapshot = await getDoc(activityDocRef);
          let currentProposedActivities = [];
          let planning = [];

          if (activitySnapshot.exists()) {
            const activityData = activitySnapshot.data();
            planning = activityData.planning || [];
            currentProposedActivities = activityData.proposedActivities || [];
          }

          // Parcourir le planning pour récupérer les activités
          const planningActivities = planning.reduce((acc, day) => {
            if (day.morning) {
              acc.push(...day.morning);
            }
            if (day.afternoon) {
              acc.push(...day.afternoon);
            }
            return acc;
          }, []);

          // Appeler la fonction pour récupérer et store les activités proposées
          const tripRef = doc(db, "tripdatas", this.tripId);
          const tripSnapshot = await getDoc(tripRef);

          if (tripSnapshot.exists()) {
            const tripData = tripSnapshot.data();
            await fetchAndStoreProposedActivities(activitiesDocumentId, tripData, activitiesProfile);
            
            // Récupérer les activités proposées après fetchAndStoreProposedActivities
            const updatedActivitySnapshot = await getDoc(activityDocRef);
            if (updatedActivitySnapshot.exists()) {
              currentProposedActivities = updatedActivitySnapshot.data().proposedActivities || [];
            }

            // Ajouter les activités du planning à la liste des activités proposées sans duplication
            const existingActivityNames = new Set(currentProposedActivities.map(activity => activity.name));
            const newProposedActivities = [
              ...currentProposedActivities,
              ...planningActivities.filter(activity => !existingActivityNames.has(activity.name))
            ];

            // Mise à jour du document avec les nouvelles activités proposées
            await updateDoc(activityDocRef, {
              proposedActivities: newProposedActivities,
            });

            // Mettre à jour le document `tripdatas` avec `activitiesDocumentId` si nécessaire
            if (!tripData.activitiesDocumentId) {
              await updateDoc(tripRef, {
                activitiesDocumentId: activitiesDocumentId,
              });
            }

            this.$emit('handleActivityDocument', activitiesDocumentId);
            this.$emit('updateStep', "planningGenerator");
          } else {
            console.error('Trip document does not exist');
          }
        } else {
          this.error = 'User not authenticated';
        }
      } catch (error) {
        console.error(error);
        this.error = 'Erreur lors de la validation. Veuillez réessayer.';
      } finally {
        this.isLoading = false; // Masquer l'overlay de chargement
      }
    }


  }
};
</script>



<style scoped>
.activities-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 75vh;
  width: 70%;
  margin: 40px auto;
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.progress-step {
  flex: 1;
  height: 8px;
  background-color: #f0e4d7;
  margin-right: 10px;
  border-radius: 4px;
}

.progress-step:last-child {
  margin-right: 0;
}

.progress-step-active {
  background-color: var(--jaune);
}

.activities-questions h2 {
  margin: 50px 0 30px 0;
  color: var(--vert);
  font-size: 2.2rem;
  text-align: center;
}

.activities-questions h2 span {
  font-size: 1rem;
  color: #666;
  font-weight: normal;
}

.options {
  display: flex;
  width: 100%;
  margin: 60px auto;
  gap: 4vh;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  justify-content: center;
  width: 130px;
  text-align: center;
}

.activities-options {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: 60px auto;
}

.activity-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  justify-content: space-around;
  text-align: center;
  margin: auto;
  width: 50%;
}

.cost-options {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 60px auto;
}

.city-options {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 60px auto;
}

.restaurant-options {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 60px auto;
}

.option-selected {
  border-color: var(--jaune);
  background-color: #fff5e6;
}

.option img,
.activity-option img,
.city-option img,
.restaurant-option img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.option p,
.activity-option p,
.city-option p,
.restaurant-option p {
  font-size: 1rem;
  font-weight: bold;
  color: var(--dark-gray);
}

.error-message {
  color: red;
  margin-bottom: 20px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.btn-back,
.btn-next {
  background-color: var(--light-gray);
  color: var(--jaune);
  border: 2px solid var(--jaune);
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-back:hover,
.btn-next:hover {
  background-color: var(--jaune);
  color: white;
}

.questions-container {
  height: 50vh;
}

.activityTypes {
  margin: auto !important;
  display: flex;
  width: 100%;
  margin: 100px auto;
  justify-content: space-between;
  gap: 20px;
}

.cost-option {
  width: 200px !important;
}

.city-option {
  width: 150px !important;
}

.restaurant-option {
  width: 150px !important;
}

.city-option img.rotate,
.restaurant-option img.rotate {
  transform: rotate(180deg); /* Rotate the 'no' icon */
}

.activities-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.activity-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 50%;
}

.activity-input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid var(--jaune);
  border-radius: 8px;
}

.remove-activity-button,
.add-activity-button {
  width: 40px;
  height: 40px;
  background-color: var(--jaune);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: 13px;
}

.add-activity-button {
  margin-top: 20px;
}

.note {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-content {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.loading-content p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.loading-content img {
  margin-top: 10px; /* Ajoute un espace au-dessus de l'image */
}

@media screen and (max-width: 768px) {
  .activities-questions {
    width: 95%;
    padding: 5px;
  }
  .questions-container {
    max-width: 100%;
    padding: 0 10px;
    height: auto;
  }
  .questions-container h2 {
    font-size: 1.2rem;
    margin-top: 0px;
  }
  .questions-container h2 span, .questions-container p {
    font-size: 0.8rem;
  }
  .option p {
    margin: 0;
    font-size: 0.7rem;
  }
  .progress-bar {
    width: 90%;
  }
  .options,
  .activityTypes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 10px auto;
    width: 100%; /* S'assurer que les options prennent la largeur complète du conteneur */
    max-width: 100%;
  }
  .cost-options {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 10px auto;
    width: 100%;
    max-width: 95%;
  }
  .option,
  .activity-option,
  .cost-option,
  .city-option,
  .restaurant-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px; /* Définir une hauteur fixe pour chaque option */
    margin-bottom: 10px;
    text-align: center;
    box-sizing: border-box;
  }
  .option img,
  .activity-option img,
  .cost-option img,
  .city-option img,
  .restaurant-option img {
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 10px;
  }
  .navigation-buttons {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    margin-top: 0px;
  }
  .btn-back,
  .btn-next {
    width: 40%;
    padding: 8px;
  }

  body {
    overflow-x: hidden; /* Empêche le débordement horizontal */
  }

  .activities-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .activity-input-container {
    display: flex;
    align-items: center;
    justify-content: center; /* Centrer les éléments horizontalement */
    margin-bottom: 10px;
    width: 90%; /* Ajuster la largeur pour centrer */
  }

  .activity-input {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid var(--jaune);
    border-radius: 8px;
    margin-right: 10px; /* Ajouter un espace entre l'input et le bouton - */
    font-size: 0.8rem;
  }

  .remove-activity-button,
  .add-activity-button {
    width: 40px;
    height: 40px;
    background-color: var(--jaune);
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    padding: 0px;
    margin: 0;
  }

  .remove-activity-button:hover,
  .add-activity-button:hover {
    background-color: darken(var(--jaune), 10%); /* Assombrir légèrement au survol */
  }

  .add-activity-button {
    margin-top: 20px;
  }

  .loading-content {
    width: 90%;
  }
}




</style>

