<template>
  <!-- Composant UserAuth -->
  <UserAuth v-if="showAuth" @close="showAuth = false" />


  <div v-if="!loading">

    <div class="hotel-return" @click="backToHotelList" >
      <img src="@/assets/img/voyage/hotels/arrow.png" alt="Retour" />
      <p>Retour</p>
    </div>

    <div class="hotel-details-container">
      <div class="hotel-first-section">
        <div class="first-name-and-fav">
          <div class="first-name-and-address">
            <div class="first-name">
              <h2>{{ selectedHotel.name }}</h2>
              <p>{{ generateStarRating(selectedHotel.starRating) }}</p>
            </div>
            <div class="hotel-score" v-if="selectedHotel.ratings.rating && selectedHotel.ratings.rating > 0">
                  {{ selectedHotel.ratings.rating }}/10
            </div>
            <p>{{ selectedHotel.address }}</p>
          </div>
          <!--<div class="first-fav-button">
            <img src="@/assets/img/voyage/hotels/yellow-fav.png" alt="Favori" />
            <p>Ajouter aux favoris</p>
          </div>-->
        </div>

        <div class="first-section-parent">
          <div class="first-section-div1" @click="showImage(0)">
            <img :src="processHotelImage(selectedHotel.images[0])" alt="Image 1" v-if="selectedHotel.images.length > 0">
          </div>
          <div class="first-section-div2" @click="showImage(1)">
            <img :src="processHotelImage(selectedHotel.images[1])" alt="Image 2" v-if="selectedHotel.images.length > 1">
          </div>
          <div class="first-section-div3" @click="showImage(2)">
            <img :src="processHotelImage(selectedHotel.images[2])" alt="Image 3" v-if="selectedHotel.images.length > 2">
          </div>
          <div class="first-section-div4" @click="showImage(3)">
            <img :src="processHotelImage(selectedHotel.images[3])" alt="Image 4" v-if="selectedHotel.images.length > 3">
          </div>
          <div class="first-section-div5" @click="showImage(4)">
            <img :src="processHotelImage(selectedHotel.images[4])" alt="Image 5" v-if="selectedHotel.images.length > 4">
          </div>
        </div>
      </div>

      <div class="hotel-second-section">
        <div class="second-columns">
          <div class="second-description">
            <div class="section-separator"></div>
            <h2>Description</h2>
            <div class="description-content" ref="descriptionContent">
              <div v-for="(item, itemIndex) in selectedHotel.description" :key="itemIndex">
                <h3>{{ item.title }}</h3>
                <p v-for="(paragraph, paraIndex) in item.paragraphs" :key="paraIndex">{{ paragraph }}</p>
              </div>
              <button class="show-more-button" ref="voirPlusBtn">Voir plus</button>
            </div>
          </div>
          <div class="second-sum-up">
            <div class="second-sum-up-prices">
              <h3>{{ Math.round(totalHotelPrice) }} €</h3>
              <div class="sum-up-paragraph">
                <p>Les {{ nights }} nuits</p>
              </div>
            </div>
            <div class="paragraph-separator"></div>
            <div class="second-sum-up-prices">
              <h3>{{ Math.round(hotelAndFlightPricePerPerson) }} €</h3>
              <div class="sum-up-paragraph">
                <p>{{ priceLabel}}</p>
                <p>Par personne</p>
              </div>
            </div>
            <div class="sum-up-dates">
              <div class="sum-up-dates-bloc">
                <p>Arrivée</p>
                <h3>{{ checkin }}</h3>
              </div>
              <div class="sum-up-dates-bloc">
                <p>Départ</p>
                <h3>{{ checkout }}</h3>
              </div>
            </div>
            <div class="sum-up-choose">
              <button ref="chooseButton" @click="chooseHotel">Choisir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="section-separator"></div>

      <div class="hotel-third-section">
        <h2>Prestations</h2>
        <div class="third-icon-categories" ref="thirdIconCategories">
          <div v-for="(amenitiesGroup, groupIndex) in selectedHotel.amenities" :key="groupIndex">
            <h3>{{ amenitiesGroup.group_name }}</h3>
            <div class="third-icon-list">
              <div class="amenity-item" v-for="(amenity, index) in amenitiesGroup.amenities" :key="index">
                <img src="@/assets/img/voyage/hotels/icon.png" alt="Icon" />
                <span>{{ amenity }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="overlay" ref="overlay-availability"></div>
        <div id="popup" class="popup" ref="popUp">
          <span class="close-popup" ref="closePopUp">&times;</span>
          <h2 style="text-align: center; margin-bottom: 40px;">Toutes les prestations</h2>
          <div class="popup-content">
            <div class="amenities-group-popUp" v-for="(amenitiesGroup, groupIndex) in selectedHotel.amenities" :key="groupIndex">
              <h3>{{ amenitiesGroup.group_name }}</h3>
              <div class="amenity-item-popUp">
                <div class="aminity-item-individual" v-for="(amenity, index) in amenitiesGroup.amenities" :key="index">
                  <img src="@/assets/img/voyage/hotels/icon.png" :alt="'Icon ' + (index + 1)" />
                  <span>{{ amenity }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button ref="voirPlusBtn2" class="show-more-btn">Voir plus</button>
      </div>

      <div class="section-separator" style="margin-top: 150px;"></div>

      <div class="hotel-fourth-section">
        <h2>Emplacement</h2>
        <div class="fourth-address">
          <img src="@/assets/img/voyage/location-pin.png" alt="Location" />
          <p>{{ selectedHotel.address }}</p>
        </div>
        <div class="fourth-map-bloc" id="map" style="height: 400px;"></div>
      </div>
    </div>
    <div v-if="showNoAvailability" class="popup-overlay">
      <div class="popup-container">
        <h2>Aucune chambre disponible aux dates demandées</h2>
        <div class="hotel-return" @click="backToHotelList">
          <img src="@/assets/img/voyage/hotels/arrow.png" alt="Retour" />
          <p>Retour</p>
        </div>
      </div>
    </div>


  </div>

  <div v-if="imagesFullScreen" class="overlay" ref="overlay">
      <div class="carousel-content">
        <button class="close-modal" @click="closeImageModal">&times;</button>
        <button class="prev-image" @click="updateImageIndex(-1)">
          <img src="@/assets/img/voyage/hotels/previous.png" alt="Précédent" />
        </button>
        <img :src="processHotelImage(selectedHotel.images[imageIndex])" alt="Image en grand" class="modal-image">
        <button class="next-image" @click="updateImageIndex(1)">
          <img src="@/assets/img/voyage/hotels/next.png" alt="Suivant" />
        </button>
      </div>
       
  </div>
</template>

<script>
import { doc, getFirestore, getDoc, runTransaction, updateDoc  } from "firebase/firestore";
import { db } from "../firebase.js";
import L from "leaflet";
import { loadHotelRates } from "@/functions/loadHotelRates"; // Importez la fonction
import UserAuth from "@/views/UserAuth.vue";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useToast } from 'vue-toastification';

export default {
  name: "HotelMain",
  props: ["docRef", "hotelRef", "fromProfile"],
  components: { UserAuth },
  data() {
    return {
      map: null,
      selectedHotel: {},
      tripData: {},
      loading: true,
      nights: 0,
      totalHotelPrice: 0,
      hotelAndFlightPricePerPerson: 0,
      checkin: "",
      checkout: "",
      imagesFullScreen: false,
      imageIndex: 0,
      showNoAvailability: false,
      showAuth: false,
    };
  },
  created() {
    this.toast = useToast();
  },
  mounted: async function() {

      await this.checkUserConnection(); 
      if (this.hotelRef) {
        await this.loadHotel(this.hotelRef, this.docRef);
        await this.hotelRates(); 
        
        this.loading = false;

        this.$nextTick(() => {
          const content = this.$refs.descriptionContent;
          const amenitiesGroupHeight = this.$refs.thirdIconCategories;
          const button = this.$refs.voirPlusBtn;
          const button2 = this.$refs.voirPlusBtn2;
          const popUp = this.$refs.popUp;
          const overlay = this.$refs.overlay;
          const closePopUp = this.$refs.closePopUp;

          if (content.scrollHeight > content.clientHeight) {
            button.style.display = 'block';
          }

          if (amenitiesGroupHeight.scrollHeight > amenitiesGroupHeight.clientHeight) {
            button2.style.display = 'block';
          }

          button.addEventListener('click', () => {
            if (content.style.maxHeight) {
              content.style.maxHeight = null;
              button.textContent = 'Voir plus';
            } else {
              content.style.maxHeight = content.scrollHeight + 'px';
              button.textContent = 'Voir moins';
            }
          });

          button2.addEventListener('click', () => {
            popUp.style.display = 'block';
            overlay.style.display = 'block';
          });

          closePopUp.addEventListener('click', function() {
            popUp.style.display = 'none';
            overlay.style.display = 'none';
          });

          this.initializeMap();
        });

      } else {
        console.error('docRef or hotelRef is missing');
      }
  },

  computed: {
    priceLabel() {
      return (this.tripData.selectedFlight && this.tripData.selectedFlight.price) || this.tripData.selectedGroundTransportation ? 'Transport + Hôtel' : "Total pour l'hôtel";
    }
  },

  methods: {
    async addTripIdToUser(userId, tripId) {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userTripIds = userDoc.data().tripIds || [];
          userTripIds.push({ tripId: tripId, dateAdded: new Date() }); // Ajoutez l'ID du voyage et la date
          await updateDoc(userDocRef, {
            tripIds: userTripIds
          });
          console.log(`TripId ${tripId} ajouté à l'utilisateur ${userId}`);
        } else {
          console.error('No such user document!');
        }
      } catch (error) {
        console.error('Erreur lors de l\'ajout de tripId à l\'utilisateur:', error);
      }
    },
    async checkUserConnection() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          this.isConnected = true;

          // Si docRef a une valeur et que l'utilisateur vient de se connecter, ajoutez-le à tripIds
          if (this.docRef) {
            await this.addTripIdToUser(user.uid, this.docRef);
          }
        } else {
          this.isConnected = false;
        }
      });
    },
    showAuthModal() {
      this.showAuth = true;
    },
    async loadHotel(hotelRef, docRef) {
      try {
        const tripDataRef = doc(db, "hotelInformations", hotelRef);
        const tripDataSnapshot = await getDoc(tripDataRef);
        const allTripDataRef = doc(db, "tripdatas", docRef);
        const allTripDataSnapshot = await getDoc(allTripDataRef);

        console.log("DocRef", docRef);
        if (tripDataSnapshot.exists()) {
          this.selectedHotel = tripDataSnapshot.data();
        } else {
          console.log("Problème de chargement des données de l'hôtel");
        }
        if (allTripDataSnapshot.exists()) {
          this.tripData = allTripDataSnapshot.data();
        } else {
          console.log("Problème lors du chargement des données du voyage");
        }
      } catch (error) {
        console.error("Problème lors du chargement des données : ", error);
      }
    },

    async hotelRates() {
      // Déterminer les dates de checkin et checkout
      if (this.tripData.selectedFlight) {
        this.checkin = this.convertDateFormat(this.tripData.selectedFlight.departureDateLandingDisplay);
        this.checkout = this.convertDateFormat(this.tripData.selectedFlight.returnDateTakeOffDisplay);
      } else if (this.tripData.selectedGroundTransportation) {
        this.checkin = this.convertDateFormat(this.tripData.selectedGroundTransportation.outboundTrip.arrivalDateDisplay);
        this.checkout = this.convertDateFormat(this.tripData.selectedGroundTransportation.returnTrip.departureDateDisplay);
      } else {
        this.checkin = this.formatDatesFromTimeStamp(this.tripData.departureDate);
        this.checkout = this.formatDatesFromTimeStamp(this.tripData.returnDate);
      }
      console.log({
          checkin: this.checkin,
          checkout: this.checkout,
          adults: this.tripData.nbPerson,
          hotelId: this.selectedHotel.hotelId
        });
      // Appel à loadHotelRates
      try {
        const rates = await loadHotelRates({
          checkin: this.checkin,
          checkout: this.checkout,
          adults: this.tripData.nbPerson,
          hotelId: this.selectedHotel.hotelId
        });

        console.log('Rates loaded:', rates);
        this.selectedHotel.dailyPrice = rates.dailyPrice;
        this.selectedHotel.paymentAmount = rates.paymentAmount;

        // Vérification des tarifs
        if (!rates.dailyPrice || !rates.paymentAmount || rates.dailyPrice === 0 || rates.paymentAmount === 0) {
          //this.$refs.chooseButton.disabled = true; // Désactive le bouton "Choisir"
          console.log("Prix = 0");
          this.showNoAvailabilityPopup(); // Affiche le pop-up
          console.log("valeur de showNo...", this.showNoAvailability);
        }
        this.calculatePrices();
      } catch (error) {
        console.error('Error loading hotel rates:', error);
      }
    },


    parseDate(date) {
      if (typeof date === 'string' || date instanceof String) {
        return new Date(date);
      } else if (date instanceof Date) {
        return date;
      } else if (date && typeof date.toDate === 'function') {
        return date.toDate();
      } else {
        return new Date(date); // Fallback to creating a new Date object
      }
    },

    formatDates(date) {
      const options = { weekday: 'short', day: 'numeric', month: 'short' };
      const parsedDate = this.parseDate(date);
      return parsedDate.toLocaleDateString('fr-FR', options);
    },

    formatDatesFromTimeStamp(date) {
      if (!date || typeof date.toDate !== 'function') {
        throw new Error('Invalid Firestore timestamp');
      }

      const jsDate = date.toDate(); // Convertir le timestamp Firestore en objet Date JavaScript
      const year = jsDate.getFullYear();
      const month = String(jsDate.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
      const day = String(jsDate.getDate()).padStart(2, '0'); // Ajout de zéro au début si nécessaire

      return `${year}-${month}-${day}`;
    },

    processHotelImage(image) {
      if (image) {
        return image.replace("{size}", "1024x768");
      } else {
        return require('@/assets/img/voyage/hotel.jpg');
      }
    },

    generateStarRating(score) {
      const roundedScore = Math.round(score);
      return "★".repeat(roundedScore);
    },

    initializeMap() {
      const lat = this.selectedHotel.location.latitude;
      const lng = this.selectedHotel.location.longitude;

      this.map = L.map('map', {
        center: [lat, lng],
        zoom: 15,
        zoomControl: false,
        dragging: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        touchZoom: false
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);

      const customIcon = L.icon({
        iconUrl: require('@/assets/img/voyage/location-pin.png'),
        iconSize: [50, 50],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
      });

      L.marker([lat, lng], { icon: customIcon }).addTo(this.map)
        .bindPopup(`<b>${this.selectedHotel.name}</b><br>${this.selectedHotel.address}`)
        .openPopup();
    },

    calculatePrices() {
      if (this.tripData.selectedFlight) {
        this.checkin = this.tripData.selectedFlight.departureDateLandingDisplay;
        this.checkout = this.tripData.selectedFlight.returnDateTakeOffDisplay;
      } else if (this.tripData.selectedGroundTransportation) {
        this.checkin = this.tripData.selectedGroundTransportation.outboundTrip.arrivalDateDisplay;
        this.checkout = this.tripData.selectedGroundTransportation.returnTrip.departureDateDisplay;
      } else {
        this.checkin = `Entre ${this.formatDates(this.tripData.departureDate)}`;
        this.checkout = `Et ${this.formatDates(this.tripData.returnDate)}`;
      }

      this.nights = this.tripData.nightsInDest;

      //const nbRooms = Math.ceil(this.tripData.nbPerson / 2);
      this.totalHotelPrice = this.selectedHotel.paymentAmount;

      const flightPrice = this.tripData.selectedFlight
      ? this.tripData.selectedFlight.price || 0
      : this.tripData.selectedGroundTransportation
      ? (this.tripData.selectedGroundTransportation.outboundTrip.price + this.tripData.selectedGroundTransportation.returnTrip.price) || 0
      : 0;

      const hotelPricePerPerson = this.totalHotelPrice / (this.tripData.nbPerson || 1);
      this.hotelAndFlightPricePerPerson = hotelPricePerPerson + flightPrice;
    },

    generateReservationLink(hotelId, checkinDate, checkoutDate, guests) {
      const formattedCheckin = this.formatDateForURL(checkinDate);
      const formattedCheckout = this.formatDateForURL(checkoutDate);
      return `https://www.zenhotels.com/rooms/${hotelId}/?cur=EUR&dates=${formattedCheckin}-${formattedCheckout}&guests=${guests}&lang=fr&partner_extra=None&partner_slug=153159.affiliate.6de6&utm_campaign=fr-fr%2C+deeplink%2C+affiliate&utm_medium=api2&utm_source=153159.affiliate.6de6&utm_term=None`;
    },

    formatDateForURL(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}.${day}.${year}`;
    },
    async chooseHotel() {

      const auth = getAuth();
      const user = auth.currentUser;
      console.log('User:', user); 
      if (!user) {
        this.toast.info("Connectez-vous pour que le voyage soit sauvegardé et accéder au résumé de votre voyage", {
          timeout: 10000, // Durée d'affichage du toast en millisecondes
          position: "top-right", // Position du toast
          closeOnClick: true, // Fermer en cliquant dessus
          pauseOnHover: true, // Pause lorsqu'on survole le toast
        });
        this.showAuthModal();
        return;
      }
      try {
        const tripDataRef = doc(db, 'tripdatas', this.docRef);
        await runTransaction(db, async (transaction) => {
          const tripDataSnapshot = await transaction.get(tripDataRef);
          if (!tripDataSnapshot.exists()) {
            throw new Error(`Le document tripdatas avec l'ID ${this.docRef} n'existe pas.`);
          }

          const tripData = tripDataSnapshot.data();
          const reservationLink = this.generateReservationLink(
            this.selectedHotel.hotelId,
            new Date(this.checkin),
            new Date(this.checkout),
            this.tripData.nbPerson || 1
          );
          this.selectedHotel.reservationLink = reservationLink;
          tripData.selectedHotel = this.selectedHotel;
          transaction.update(tripDataRef, { selectedHotel: this.selectedHotel });
        });

        console.log('Hôtel choisi avec succès.');
        if (this.fromProfile) {
          this.$router.push({ name: 'UserTrips', query: { user: JSON.stringify(this.user) } });
        } else {
          this.$emit('updateStep', 'sumUp');
        }  
      } catch (error) {
        console.error('Erreur lors du choix de l\'hôtel :', error);
        alert('Une erreur est survenue lors du choix de l\'hôtel. Veuillez réessayer.');
      }
    },

    backToHotelList() {
      this.$emit('updateStep', 'selectHotels');
    },

    showImage(index) {
      this.imageIndex = index;
      this.imagesFullScreen = true;
      this.$nextTick(() => {
        this.$refs.overlay.style.display = 'block'; // Affiche l'overlay
      });
    },
    closeImageModal() {
      this.imagesFullScreen = false;
      this.$refs.overlay.style.display = 'none'; // Cache l'overlay
    },
    updateImageIndex(change) {
      this.imageIndex = (this.imageIndex + change + this.selectedHotel.images.length) % this.selectedHotel.images.length;
    },
    showNoAvailabilityPopup() {
      this.showNoAvailability = true;
    },
  
    hideNoAvailabilityPopup() {
      this.showNoAvailability = false;
    },
    convertDateFormat(dateString) {
      const [day, month, year] = dateString.split('/');
      return `${year}-${month}-${day}`;
    },

  }
};
</script>


<style scoped>

/* Hotel Details */

.section-separator {
  width: 100%;
  height: 1px;
  background-color: var(--gray);
  margin: 40px 0;
}

.hotel-return {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 10vh;
  gap: 15px;
  font-weight: 600;
  font-size: 1rem;
  color: var(--dark-gray);
  cursor: pointer;
}

.hotel-return img {
  height: 25px;
  width: 25px;
}

.hotel-details-container {
  width: 90%;
  margin: auto;
}

.hotel-first-section {
  width: 100%;
}

.first-name-and-fav {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 10px 5px;
}

.first-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  font-size: 1rem;
  margin-bottom: -20px;
}

.first-name p {
  color: var(--jaune);
}

.first-fav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.first-fav-button img {
  height: 25px;
  width: 25px;
}

.first-section-parent {
    display: grid;
    grid-template-columns: 1fr repeat(2, 0.5fr);
    grid-template-rows: repeat(2, 0.5fr);
    grid-column-gap: 10px; /* Espace horizontal entre les colonnes */
    grid-row-gap: 10px; /* Espace vertical entre les rangées */
    width: 100%;
    height: auto;
    margin: 40px 0; /* Ajout d'un margin général pour les sections si nécessaire */
}

.first-section-div1 { grid-area: 1 / 1 / 3 / 2; cursor: pointer; height: 410px;}
.first-section-div2 { grid-area: 1 / 2 / 2 / 3; cursor: pointer; height: 200px;}
.first-section-div3 { grid-area: 1 / 3 / 2 / 4; cursor: pointer; height: 200px;}
.first-section-div4 { grid-area: 2 / 2 / 3 / 3; cursor: pointer; height: 200px;}
.first-section-div5 { grid-area: 2 / 3 / 3 / 4; cursor: pointer; height: 200px;}


.first-section-div1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px; /* Coin en haut à gauche */
    border-bottom-left-radius: 10px; /* Coin en bas à gauche */
}

.first-section-div3 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 10px; /* Coin en haut à droite */
}

.first-section-div2 img,
.first-section-div4 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.first-section-div5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-right-radius: 10px; /* Coin en bas à droite */
}

.second-columns {
  display: flex;
  flex-direction: row;
}

.second-description {
  width: 65%; /* Colonne de gauche */
  padding-right: 90px; 
  overflow: hidden;
  position: relative; 
  padding-bottom: 90px; /* Espace en bas pour le bouton */
}

.second-sum-up {
  width: 35%; /* Colonne de droite */
  background-color: var(--white);
  border-radius: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  align-self: flex-start; /* Alignement en haut */
  padding: 20px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Bordure légère */
}

.show-more-button {
  position: absolute;
  bottom: 30px; /* Positionner le bouton en bas */
  left: 0px; /* Décalage vers la gauche */
  background-color: var(--vert); /* Couleur de fond */
  color: var(--white); /* Couleur du texte */
  border: none;
  font-size: 0.9rem;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  display: none; 
}

.show-more-button:hover {
  background-color: var(--jaune); /* Couleur de fond au survol */
}

.description-content {
  max-height: 250px; /* Hauteur maximale du contenu visible */
  overflow: hidden; /* Masquer le contenu qui dépasse */
  text-align: justify;
  font-size: 1rem;
}

.second-sum-up-prices {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.second-sum-up-prices h3 {
  font-size: 1.5rem;
  font-weight: 800;
}

.second-sum-up-prices p {
  font-size: 0.9rem;
  color: var(--dark-gray);
}

.paragraph-separator {
  width: 100%;
  height: 1px;
  background-color: var(--jaune);
  margin: 10px 0;
}

.sum-up-paragraph {
  margin: auto;
}

.sum-up-dates {
  border: 1px solid var(--jaune);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 0 5px;
  margin: 10px 0;
  position: relative; /* Position relative pour contenir le pseudo-élément */
}

.sum-up-dates::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%; /* Position au milieu */
  width: 1px; /* Largeur de la barre */
  background-color: var(--jaune); /* Couleur de la barre */
}

.sum-up-dates-bloc {
  width: 50%;
  padding: 0 10px;
}

.sum-up-dates-bloc p {
  font-size: 0.9rem;
}

.sum-up-dates-bloc h3 {
  font-size: 0.9rem;
}

.sum-up-choose {
  width: 100%;
  text-align: center;
  margin: 30px 0 10px 0;
}

.sum-up-choose button {
  padding: 15px 25px;
  background-color: var(--jaune);
  color: var(--white);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 20px;
  font-weight: 700;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* Ombre douce pour le relief */
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
}

.sum-up-choose button:hover {
  transform: scale(1.02); /* Légère mise à l'échelle au survol */
}

.hotel-third-section {
  margin: 40px 0;
  position: relative;
}

.third-icon-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.amenity-item {
  flex: 1 1 20%; /* Chaque item occupe 25% de la largeur jusqu'à une taille minimale */
  min-width: 150px; /* Taille minimale pour chaque item */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.amenity-item img {
  width: 20px; /* Ajuster la taille des icônes */
  height: 20px;
}

.amenity-item span {
  font-size: 1rem; /* Ajuster la taille de la police selon vos besoins */
}

.show-more-btn {
  display: none; /* Caché par défaut */
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  background-color: var(--vert); /* Couleur de fond */
  color: var(--white); /* Couleur du texte */
  border: none;
  font-size: 0.9rem;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.show-more-btn:hover {
  background-color: var(--jaune); /* Couleur de fond au survol */
}

/* Styles pour le popup */
.overlay {
  display: none; /* Caché par défaut */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Couleur sombre avec opacité */
  z-index: 999; /* Juste en dessous du popup */
}


/* Styles pour le popup */
.popup {
  display: none; /* Caché par défaut */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100000;
  max-height: 80vh;
  overflow-y: auto;
  width: 80%; /* Ajustez la largeur selon vos besoins */
  max-width: 900px; /* Largeur maximale */
  border-radius: 15px;
}

.popup-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
}

/* Styles pour les items dans le popup */
.popup-content .amenity-item {
  flex: 1 1 30%; /* Ajuste les items pour qu'ils occupent 30% de la largeur */
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin: 10px 0;
}

.third-icon-categories {
  display: flex;
  flex-direction: column;
  max-height: 200px; /* Limite de hauteur pour la liste des amenities */
  overflow: hidden;
  position: relative; /* Pour positionner correctement le bouton Voir plus */
}

.amenity-item img {
  width: 20px;
  height: 20px;
}

.amenity-item span {
  font-size: 1rem; /* Ajustez la taille de la police selon vos besoins */
}

.amenities-group-popUp {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.amenity-item-popUp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}

.amenity-item-popUp img {
  height: 20px;
  width: 20px;
}

.aminity-item-individual {
  display: flex;
  align-items: center;
  gap: 10px;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.hotel-fourth-section {
  margin-bottom: 40px;
}

.fourth-address {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.fourth-address img {
  height: 35px;
  width: 35px;
}

.fourth-map-bloc {
  width: 100%;
  height: 300px;
  background-color: white;
  margin: 30px 0 90px 0;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Ajout d'une ombre pour un effet de relief */
}

.daily-price-bubble {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}

/* Fin Hotel Details */

/* Styles for fullscreen image modal */

.overlay {
  display: none; /* Caché par défaut */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Fond sombre */
  z-index: 9999; /* Assurez-vous que l'overlay a un z-index élevé pour apparaître au-dessus des autres éléments */
}

.carousel-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  max-height: 70vh;
  overflow-y: hidden;
  width: 80%;
  max-width: 900px;
  border-radius: 15px;
}

.carousel-content .modal-image {
  width: 100%;
  height: auto;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  background: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.4); /* Fond grisé */
  padding: 5px 10px;
}

.prev-image, .next-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4); /* Fond grisé */
  padding: 5px;
}

.prev-image {
  left: 10px;
}

.next-image {
  right: 10px;
}

.prev-image img, .next-image img {
  width: 30px;
  height: 30px;
}

.hotel-score {
  background-color: var(--jaune);
  color: white;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 1.1rem;
  width: fit-content;
  margin-top: 5px;
}
/* Media Query pour mobile */
@media (max-width: 768px) {
  .hotel-details-container {
    width: 90%;
  }

  .first-name-and-fav {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px; /* Réduction de l'espace */
  }

  .first-name {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px; /* Réduction de l'espace */
    font-size: 1.2rem;
    margin-bottom: 5px; /* Réduction de l'espace */
  }

  .first-fav-button {
    align-self: center;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px; /* Réduction de l'espace */
  }

  .first-section-parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 150px 100px;
    grid-gap: 5px; /* Réduction de l'espace */
    height: auto;
    margin: 20px 0;
  }

  .first-section-div1 {
    grid-area: 1 / 1 / 2 / 5;
    height: 150px;
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
  }

  .first-section-div2,
  .first-section-div3,
  .first-section-div4,
  .first-section-div5 {
    height: 100px;
  }

  .first-section-div2 {
    grid-area: 2 / 1 / 3 / 2;
    border-bottom-left-radius: 10px;
  }

  .first-section-div3 {
    grid-area: 2 / 2 / 3 / 3;
  }

  .first-section-div4 {
    grid-area: 2 / 3 / 3 / 4;
  }

  .first-section-div5 {
    grid-area: 2 / 4 / 3 / 5;
    border-bottom-right-radius: 10px;
    position: relative;
    overflow: hidden;
  }

  .first-section-div1 img,
  .first-section-div2 img,
  .first-section-div3 img,
  .first-section-div4 img,
  .first-section-div5 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  .first-section-div5 img {
    filter: grayscale(100%);
  }

  .first-section-div5::before {
    content: "+ d'images";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.7rem;
    text-align: center;
    z-index: 200;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .second-columns {
    flex-direction: column-reverse;
  }

  .second-description {
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
    font-size: 1rem;
    margin-top: 0;
  }

  .second-description h2 {
    font-size: 1.3rem;
  }

  .second-sum-up {
    width: 80%;
    margin: 40px auto;
    max-width: none;
  }

  .hotel-third-section {
    margin: 20px 0;
  }

  .show-more-button {
    bottom: 60px;
  }

  .popup {
    width: 90%;
    padding: 20px;
  }

  .carousel-content {
    width: 90%;
  }

  .close-modal, .prev-image, .next-image {
    padding: 5px;
  }

  .first-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    font-size: 0.9rem;
    margin-bottom: -20px;
    margin-top: -20px;
    width: 100%;
  }

  .first-name h2 {
    font-size: 1.2rem;
  }

  .first-name p {
    font-size: 1rem;
  }

  .first-name-and-address {
    font-size: 0.8rem;
  }

  .first-fav-button {
    font-size: 0.7rem;
  }

  .description-content {
    margin-bottom: 100px;
    font-size: 0.8rem;
  }



  .third-icon-list {
    gap: 15px;
  }

  .third-icon-list span {
    font-size: 0.8rem;
  }

  .section-separator {
    margin: 20px 0;
  }

  .hotel-return {
    margin-left: 25px;
  }
}

/* Pop-up Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Fond noir semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Assurez-vous qu'il apparaît au-dessus de tout */
}

/* Pop-up Container */
.popup-container {
  background-color: white;
  padding: 30px 40px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Ajout d'une ombre pour l'effet de relief */
  width: 100%;
  max-width: 500px; /* Limite de taille pour les grands écrans */
  text-align: center;
  z-index: 10000;

}

/* Pop-up Title */
.popup-container h2 {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

/* Retour Button inside Popup */
.popup-container .hotel-return {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.popup-container .hotel-return img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.popup-container .hotel-return p {
  font-size: 1rem;
  font-weight: bold;
  color: var(--dark-gray);
}


</style>



