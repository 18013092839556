<template>
  <div class="page-container" v-if="!loading">
    <div class="sumUp">
      <div class="sumUp-title">
        <div class="return-back" @click="backToTrips">
          <img src="@/assets/img/voyage/hotels/arrow.png" alt="Retour" />
          <p>Retour</p>
        </div>
        <h2>Modifier le voyage</h2>
      </div>

      <div class="sumUp-banner">
        <div class="banner-cities">
          <img src="@/assets/img/voyage/sumUp/location.png" alt="Location">
          <p>{{ tripData.cityFrom }}</p>
          <img src="@/assets/img/voyage/sumUp/arrows.png" alt="fleches">
          <p>{{ tripData.cityTo }}</p>
        </div>
        <div class="banner-separator"></div>
        <div class="nb-adults">
          <img src="@/assets/img/voyage/sumUp/profile.png" alt="profilIcon">
          <p>{{ tripData.nbPerson }}</p>
        </div>
        <div class="banner-separator"></div>
        <div class="banner-dates">
          <img src="@/assets/img/voyage/sumUp/calendar.png" alt="calendar">
          <p>{{ formatDate(tripData.departureDate) }} - {{ formatDate(tripData.returnDate) }}</p>
        </div>
      </div>

      <div class="sumUp-first-section">
        <div class="sumUp-dates">
          <h2>Jour 1</h2>
          <h4>{{ formatDateLong(tripData.departureDate) }}</h4>
        </div>

        <div class="sumUp-info-bloc">
          <div class="vertical-bar"></div>
          <div class="sumUp-infos">
            <!-- Section Vol -->
            <div class="sumUp-info-cadre flight-info">
              <div class="parent">
                <div class="div1 flight-time">
                  <span class="flight-city">{{ selectedFlight.departure_airport }}</span>
                </div>
                <div class="div2 flight-details">
                  <span>{{ selectedFlight.departure_airline_name }}</span>
                </div>
                <div class="div3 flight-time">
                  <span class="flight-city">{{ selectedFlight.departure_landing_airport }}</span>
                </div>
                <div class="div4 more-details">
                  <div class="more-details-price">
                    <p><strong>{{ selectedFlight.price }}€</strong> /pers. (A/R)</p>
                    <button class="more-details-button">
                      <a :href="selectedFlight.reservation_link" style="text-decoration: none; color: white;">Réserver</a>
                    </button>
                  </div>
                </div>
                <div class="div5 flight-duration">
                  <div class="flight-duration-container">
                    <div class="flight-point"></div>
                    <div class="flight-duration-line"></div>
                    <div class="flight-duration-text">{{ selectedFlight.departure_flight_duration }}</div>
                    <div class="flight-duration-line"></div>
                    <div class="flight-point"></div>
                  </div>
                </div>
                <div class="div6">
                  <span class="flight-time-detail">{{ selectedFlight.departureHour }}</span>
                </div>
                <div class="div7 flight-status">
                  <span>{{ selectedFlight.correspondanceAller === 0 ? 'Direct' : selectedFlight.correspondanceAller + ' escale' + (selectedFlight.correspondanceAller > 1 ? 's' : '') }}</span>
                </div>
                <div class="div8">
                  <span class="flight-time-detail">{{ selectedFlight.departureHourLanding }}</span>
                </div>
              </div>
            </div>

            <!-- Section Hôtel -->
            <div class="sumUp-info-cadre hotel-info">
              <div class="parent">
                <div class="div1 hotel-image">
                  <img v-if="selectedHotel.images && selectedHotel.images.length > 0" :src="processHotelImage(selectedHotel.images[0])" alt="Hotel Image">
                  <img v-else src="@/assets/img/voyage/hotel.jpg" alt="Default Hotel Image">
                </div>
                <div class="div2 hotel-details">
                  <h3 class="hotel-name">{{ selectedHotel.name }}</h3>
                  <div class="hotel-rating">
                    <span>{{ generateStarRating(selectedHotel.starRating) }}</span>
                    <span v-if="selectedHotel.ratings && selectedHotel.ratings.rating" class="hotel-score">{{ selectedHotel.ratings.rating }}</span>
                  </div>
                  <p class="hotel-address">{{ selectedHotel.address }}</p>
                </div>
                <div class="div3 more-details">
                  <div class="more-details-price">
                    <p><strong>{{ selectedHotel.dailyPrice }}€</strong> / nuit</p>
                    <p><strong>{{ totalHotelPrice }}€</strong> les {{ nights }} nuits</p>
                    <button class="more-details-button"><a style="text-decoration: none; color: white;" href="">Réserver</a></button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Button for adding activities -->
            <div class="add-activities-button-container">
              <button class="add-activities-button" @click="navigateToActivities">
                <img src="@/assets/img/profile/plus-icon.png" alt="Plus Icon" class="plus-icon">
                Ajouter des activités
              </button>
            </div>


          </div>
        </div>

        <div class="sumUp-dates">
          <h2>Jour 13</h2>
          <h4>{{ formatDateLong(tripData.returnDate) }}</h4>
        </div>
        
        <div class="sumUp-info-bloc">
          <div class="vertical-bar" style="height: auto;"></div>
          <div class="sumUp-infos">
            <!-- Section Vol -->
            <div class="sumUp-info-cadre flight-info">
              <div class="parent">
                <div class="div1 flight-time">
                  <span class="flight-city">{{ selectedFlight.return_takeOff_airport }}</span>
                </div>
                <div class="div2 flight-details">
                  <span>{{ selectedFlight.return_airline_name }}</span>
                </div>
                <div class="div3 flight-time">
                  <span class="flight-city">{{ selectedFlight.return_airport }}</span>
                </div>
                <div class="div4 more-details">
                  <div class="more-details-price">
                    <p><strong>{{ selectedFlight.price }}€</strong> /pers. (A/R)</p>
                    <button class="more-details-button"><a style="text-decoration: none; color: white;" :href="selectedFlight.reservation_link">Réserver</a></button>
                  </div>
                </div>
                <div class="div5 flight-duration">
                  <div class="flight-duration-container">
                    <div class="flight-point"></div>
                    <div class="flight-duration-line"></div>
                    <div class="flight-duration-text">{{ selectedFlight.return_flight_duration }}</div>
                    <div class="flight-duration-line"></div>
                    <div class="flight-point"></div>
                  </div>
                </div>
                <div class="div6">
                  <span class="flight-time-detail">{{ selectedFlight.returnHourTakeOff }}</span>
                </div>
                <div class="div7 flight-status">
                  <span>{{ selectedFlight.correspondanceRetour === 0 ? 'Direct' : selectedFlight.correspondanceRetour + ' escale' + (selectedFlight.correspondanceRetour > 1 ? 's' : '') }}</span>
                </div>
                <div class="div8">
                  <span class="flight-time-detail">{{ selectedFlight.returnHour }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sumUp-info-bloc">
          <div class="vertical-bar" style="height: 0px;"></div>
          <div class="sumUp-total-price">
            <h4>Tarif total</h4>
            <div class="sumUp-dot-line"></div>
            <div class="sumUp-end-total-price">
              <h4>{{ hotelAndFlightPricePerPerson }}€</h4>
              <p>les {{ nights + 1 }} jours</p>
            </div>
          </div>
        </div>

        <div class="sumUp-separation-line"></div>

        <div class="activity-planning">
          <p>Vous pouvez créer votre <strong>planning d’activités</strong> directement sur notre plateforme :</p>
          <button class="start-button">Commencer</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Chargement des données...</p>
  </div>
</template>


<script>
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/firebase.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: "ModifyTrip",
  props: ["tripId"],
  data() {
    return {
      selectedFlight: {},
      selectedHotel: {},
      tripData: {},
      loading: true,
      nights: 0,
      totalHotelPrice: 0,
      hotelAndFlightPricePerPerson: 0,
      user: null,
    };
  },

  async mounted() {
    try {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.user = user;
        }
      });

      if (this.tripId) {
        console.log("Trip ID:", this.tripId); // Log the tripId to verify it's received
        await this.loadTripDatas(this.tripId);
        this.calculatePrices();
        this.loading = false;
      } else {
        console.error("tripId is missing");
      }
    } catch (error) {
      console.error("Error loading informations:", error);
    }
  },

  methods: {
    async loadTripDatas(tripId) {
      try {
        const tripDataRef = doc(db, "tripdatas", tripId);
        const tripDataSnapshot = await getDoc(tripDataRef);
        if (tripDataSnapshot.exists()) {
          const data = tripDataSnapshot.data();
          this.tripData = data;
          this.selectedFlight = data.selectedFlight || {};
          this.selectedHotel = data.selectedHotel || {};
        } else {
          console.log("Problème de chargement des données");
        }
      } catch (error) {
        console.error("Problème lors du chargement des données : ", error);
      }
    },

    processHotelImage(image) {
      if (image) {
        return image.replace("{size}", "x500");
      } else {
        return require("@/assets/img/voyage/hotel.jpg");
      }
    },

    generateStarRating(score) {
      const roundedScore = Math.round(score);
      return "★".repeat(roundedScore);
    },

    backToTrips() {
      this.$router.push('/profile/trips');
    },

    formatDate(date) {
        if (!date) return "Date non définie";

        const dateObj = date instanceof Date ? date : date.toDate();
        if (isNaN(dateObj.getTime())) return "Date invalide";

        const jours = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        const mois = ["janv", "févr", "mars", "avr", "mai", "juin", "juil", "août", "sept", "oct", "nov", "déc"];

        const jourSemaine = jours[dateObj.getDay()];
        const jourMois = dateObj.getDate();
        const moisAnnee = mois[dateObj.getMonth()];

        return `${jourSemaine}. ${jourMois} ${moisAnnee}`;
    },

    formatDateLong(date) {
        if (!date) return "Date non définie";

        const dateObj = date instanceof Date ? date : date.toDate();
        if (isNaN(dateObj.getTime())) return "Date invalide";

        const jours = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
        const mois = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

        const jourSemaine = jours[dateObj.getDay()];
        const jourMois = dateObj.getDate();
        const moisAnnee = mois[dateObj.getMonth()];

        return `${jourSemaine} ${jourMois} ${moisAnnee}`;
    },

    calculatePrices() {
      if (this.selectedFlight && this.selectedFlight.departureDateLanding && this.selectedFlight.returnDateTakeOff) {
        const departureDate = new Date(this.selectedFlight.departureDateLanding);
        const returnDate = new Date(this.selectedFlight.returnDateTakeOff);
        const oneDay = 24 * 60 * 60 * 1000;
        this.nights = Math.round(Math.abs((returnDate - departureDate) / oneDay));

        this.totalHotelPrice = this.nights * (this.selectedHotel ? this.selectedHotel.dailyPrice : 0);

        const hotelPricePerPerson = this.totalHotelPrice / (this.tripData.nbPerson || 1);
        this.hotelAndFlightPricePerPerson = hotelPricePerPerson + (this.selectedFlight ? this.selectedFlight.price : 0);
      }
    },

    navigateToActivities() {
      this.$router.push({ name: 'ActivitiesPage', params: { tripId: this.tripId } });
    }
    
  }
};
</script>


<style scoped>

.page-container {
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
}

.sumUp {
    width: 80%;
}

.sumUp-title {
    margin: 20px auto;
    display: flex;
    align-items: center;
    width: 95%;

}

.sumUp-title h2 {
    color: var(--vert);
    font-size: 1.3rem;
    margin: auto;
}

.return-back {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: #525252;
    cursor: pointer;
}



.return-back img {
    width: 20px;
    height: 20px;
}

.save-Trip {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    background-color: var(--vert);
    padding: 2px 15px;
    border-radius: 50px;
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
}

.save-Trip img {
    width: 35px;
    height: 35px;
}

.sumUp-banner {
    width: 70%;
    border-radius: 50px;
    padding: 0px 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #525252;
    background-color: var(--white);
    margin: 60px auto;
}

.sumUp-banner img {
    width: 20px;
    height: 20px;
}

.banner-separator {
    width: 1px;
    height: 40px;
    background-color: #525252;
    display: block;
}

.banner-cities {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vh;
    font-weight: 600;
    font-size: 0.9rem;
    color: #525252;
}

.nb-adults {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #525252;
}

.banner-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vh;
    font-size: 0.9rem;
    color: #525252;
    font-weight: 600;
}

.sumUp-first-section {
    width: 90%;
    margin: auto;
}

.sumUp-dates {
    display: flex;
    flex-direction: row;
    color: #434343;
    margin: 20px 0 20px 30px;
    align-items: center;
    gap: 70px;
}

.sumUp-dates h4 {
    color: var(--vert);
    font-weight: 500;
    font-size: 1rem;
}

.sumUp-dates h2 {
    font-weight: 500;
    font-size: 1.3rem;
}

.sumUp-info-bloc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 70px;
    margin-left: 30px;
}

.vertical-bar {
    width: 1px;
    height: auto;
    background-color: #525252;
    display: block;
    margin-bottom: 20px;
}

.sumUp-infos {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 20px;
}

.sumUp-info-cadre {
    background-color: var(--white);
    border-radius: 30px;
    border: 1px solid #a9a9a9;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
}

/* Section Vol */
.flight-info .parent {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 1.3fr; /* Fixed percentage widths */
    grid-template-rows: 0.7fr 0.5fr 0.7fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.flight-info .div1 { grid-area: 1 / 1 / 2 / 2; display: flex; text-align: left; justify-content: center;}
.flight-info .div2 { grid-area: 1 / 2 / 2 / 3; display: flex; text-align: center; justify-content: center;}
.flight-info .div3 { grid-area: 1 / 3 / 2 / 4; display: flex; text-align: right ; justify-content: center;}
.flight-info .div4 { grid-area: 1 / 4 / 4 / 5; border-left: 1px solid gray; padding-left: 20px; }
.flight-info .div5 { grid-area: 2 / 1 / 3 / 4; padding: 15px 0 20px 0}
.flight-info .div6 { grid-area: 3 / 1 / 4 / 2; }
.flight-info .div7 { grid-area: 3 / 2 / 4 / 3; }
.flight-info .div8 { grid-area: 3 / 3 / 4 / 4; text-align: right;}

.flight-time {
    display: flex;
    flex-direction: column;
}

.flight-city {
    font-size: 1.1rem;
    font-weight: bold;
}

.flight-time-detail {
    font-size: 1rem;
}

.flight-details, .flight-duration, .flight-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    color: #434343;
}

.more-details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    font-size: 1rem;
}

.more-details strong {
    font-size: 1.2rem;
    margin-right: 25px;
}

.more-details-button {
    width: 100% !important;
    background-color: var(--jaune);
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 60px;
}

.flight-duration-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px; /* Adjust as needed for padding */
}

.flight-point {
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    position: relative;
}

.left {
    margin-right: -5px; /* Adjust to bring point closer to line */
}

.right {
    margin-left: -5px; /* Adjust to bring point closer to line */
}

.flight-duration-line {
    flex-grow: 1;
    height: 2px;
    background-color: #000;
}

.flight-duration-text {
    font-size: 0.8rem;
    font-weight: bold;
    color: #000;
    padding: 0 10px; /* Adjust padding around text */
    white-space: nowrap;
}

/* Section Hôtel */
.hotel-info .parent {
    display: grid;
    grid-template-columns: 0.7fr 1.5fr 0.1fr 1.3fr; /* Fixed percentage widths to match flight info */
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.hotel-info .div1 { grid-area: 1 / 1 / 2 / 2; display: flex; align-items: center; justify-content: center;}
.hotel-info .div2 { grid-area: 1 / 2 / 2 / 3; display: flex; flex-direction: column; justify-content: space-between; margin-left: 10%;}
.hotel-info .div3 { grid-area: 1 / 4 / 2 / 5; display: flex; flex-direction: column; justify-content: center; align-items: center; border-left: 1px solid gray; padding-left: 40px;}

.hotel-image {
    width: 100%;
    height: 100%; /* Make sure it occupies the full height of the container */
    display: flex;
    align-items: center;
    justify-content: center;
}

.hotel-image img {
    width: 120%;
    height: 110%;
    object-fit: cover; /* Ensures the image covers the entire container */
    border-radius: 20px;
}

.hotel-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.hotel-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
}

.hotel-rating {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    margin-left: 25px;
}

.hotel-rating span:first-child {
    color: gold;
}

.hotel-score {
    background-color: #d6a85c;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    width: 30px;
    text-align: center;
}

.hotel-address {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
}

.hotel-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.hotel-price p {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.reserve-button {
    background-color: #d6a85c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

.reserve-button:hover {
    background-color: #c5954b;
}

.sumUp-total-price {
    background-color: #d6a85c; /* Use the same color as in the image */
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-radius: 25px;
    margin: 55px 0px 25px 20px;
}

.sumUp-total-price h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
}

.sumUp-dot-line {
    flex-grow: 1;
    border-bottom: 2px dotted white;
    margin: 0 20px;
}

.sumUp-end-total-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.sumUp-end-total-price h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
}

.sumUp-end-total-price p {
    margin: 0;
    font-size: 0.9rem;
}

.sumUp-separation-line {
    width: 100%;
    border-bottom: 1px solid darkgray;
    margin: 40px 0;
}

.activity-planning {
    text-align: center;
    margin: 50px 0;
}

.activity-planning p {
    font-size: 1.3rem;
    color: #264653; /* Use the same color as the button */
    font-weight: 300;
    width: 70%;
    margin: 30px auto;
    line-height: 3rem;
}

.start-button {
    background-color: #264653; /* Button color */
    color: white;
    border: none;
    border-radius: 40px; /* Rounded corners */
    padding: 20px 35px;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.start-button:hover {
    background-color: #1f3b47; /* Darker shade for hover effect */
}

.add-activities-button-container {
  display: flex;
  justify-content: left;
  margin-bottom: 30px;
}

.add-activities-button {
  background-color: var(--white);
  color: var(--jaune);
  border: 1px solid var(--jaune);
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.add-activities-button:hover {
  background-color: var(--jaune);
  color: var(--white);
}

.add-activities-button .plus-icon {
  margin-right: 20px;
  width: 30px;
  height: 30px;
}

.add-activities-button:hover .plus-icon {
  content: url("@/assets/img/profile/plus-icon-white.png");
}

</style>
