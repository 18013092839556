import { db } from '../firebase.js';
import { collection, addDoc, doc, setDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';

async function saveActivitiesInformations(activitiesProfile, docRef, userId) {
  try {
    console.log("dofRef", docRef);
    const newDocRef = await addDoc(collection(db, 'activitiesInformations'), {
      ...activitiesProfile,
      linkedDocumentId: docRef,
      userId: userId,
    });
    console.log(`Informations sur les préférences enregistrées`);
    return newDocRef.id; // Retourner l'ID du nouveau document
  } catch (error) {
    console.error("Erreur lors de l'enregistrement des informations sur les activités :", error);
    throw new Error("Erreur lors de l'enregistrement des informations relatives aux préférences sur les activités");
  }
}

async function updateActivitiesInformations(activitiesProfile, docId) {
  try {
    const docRef = doc(db, 'activitiesInformations', docId);
    await setDoc(docRef, activitiesProfile, { merge: true });
    console.log(`Informations sur les préférences mises à jour`);
  } catch (error) {
    console.error("Erreur lors de la mise à jour des informations sur les activités :", error);
    throw new Error("Erreur lors de la mise à jour des informations relatives aux préférences sur les activités");
  }
}

async function fetchAndStoreProposedActivities(docId, tripData, activitiesProfile) {
  try {
    const activitiesDocRef = doc(db, 'activitiesInformations', docId);
    const activitiesDocSnapshot = await getDoc(activitiesDocRef);
    let includedActivities = '';

    if (activitiesDocSnapshot.exists() && activitiesDocSnapshot.data().includedActivities) {
      includedActivities = activitiesDocSnapshot.data().includedActivities.join(', ');
    }

    const oneDay = 24 * 60 * 60 * 1000;
    const departureDate = tripData.departureDate.toDate();
    const returnDate = tripData.returnDate.toDate();

    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    const nbDays = tripData.availableDays && tripData.availableDays.numberOfDays
      ? tripData.availableDays.numberOfDays
      : tripData.duration || (isValidDate(departureDate) && isValidDate(returnDate))
      ? Math.round(Math.abs((returnDate - departureDate) / oneDay)) + 1
      : 0;
  

    const apiPayload = {
      nbActivities: activitiesProfile.activityFrequency,
      activitiesType: activitiesProfile.activityType,
      freeOrPaid: activitiesProfile.freeOrPaid,
      needRestaurants: activitiesProfile.needRestaurant,
      onlyInTheCity: activitiesProfile.onlyInTheCity,
      activitiesToInclude: activitiesProfile.wantedActivitiesList,
      nbDays: nbDays,
      month: isValidDate(departureDate) ? departureDate.getMonth() + 1 : NaN, // Les mois sont de 0 à 11
      city: tripData.cityTo,
      whoWith: activitiesProfile.whoWith
    };

    if (includedActivities) {
      apiPayload.includedActivities = includedActivities;
    }

    const response = await axios.post('https://europe-west1-alegotravel.cloudfunctions.net/api/getActivitiesList', apiPayload);
    const activitiesList = response.data;

    await setDoc(activitiesDocRef, { proposedActivities: activitiesList }, { merge: true });

    console.log('Proposed activities have been stored successfully');
  } catch (error) {
    console.error('Error fetching and storing proposed activities:', error);
    throw new Error('Error fetching and storing proposed activities');
  }
}

export { saveActivitiesInformations, updateActivitiesInformations, fetchAndStoreProposedActivities };
