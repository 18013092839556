import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import generateHTMLContentTRIP from './generateHTMLContentTRIP';
import generateHTMLContentPlanning from './generateHTMLContentPlanning';

export const generatePDF = async (tripData) => {
  // Générer le contenu HTML pour TRIP et Planning
  const tripHtmlContent = await generateHTMLContentTRIP(tripData);
  
  let planningHtmlContent = '';
  if (tripData.activitiesDocumentId) {
    planningHtmlContent = await generateHTMLContentPlanning(tripData);
  }  
  

  // Créer des éléments div temporaires pour les deux contenus
  const tempDivTrip = document.createElement('div');
  tempDivTrip.style.position = 'absolute';
  tempDivTrip.style.left = '-9999px';
  tempDivTrip.style.width = '210mm'; // Largeur pour une page A4 en mm
  tempDivTrip.innerHTML = tripHtmlContent;
  document.body.appendChild(tempDivTrip);

  const tempDivPlanning = document.createElement('div');
  tempDivPlanning.style.position = 'absolute';
  tempDivPlanning.style.left = '-9999px';
  tempDivPlanning.style.width = '210mm'; // Largeur pour une page A4 en mm
  tempDivPlanning.innerHTML = planningHtmlContent;
  document.body.appendChild(tempDivPlanning);

  // Ajouter crossOrigin="anonymous" à toutes les images pour éviter les problèmes de tainted canvas
  const images = [...tempDivTrip.getElementsByTagName('img'), ...tempDivPlanning.getElementsByTagName('img')];

  images.forEach(img => {
    img.setAttribute('crossOrigin', 'anonymous');
  });

  // Attendre le chargement des images
  const imageLoadPromises = Array.from(images).map(img => new Promise(resolve => {
    img.onload = resolve;
    img.onerror = resolve;
  }));

  await Promise.all(imageLoadPromises);

  const pdf = new jsPDF('p', 'mm', 'a4');
  const topLeftMargin = 10;
  const pdfWidth = 210; // Largeur d'une page A4 en mm
  const pdfHeight = 297; // Hauteur d'une page A4 en mm
  const canvasImageWidth = pdfWidth - (topLeftMargin * 2);

  // Capture et ajout du contenu TRIP sur la première page
  const canvasTrip = await html2canvas(tempDivTrip, {
    allowTaint: true,
    useCORS: true,
    scale: 2
  });

  const imgDataTrip = canvasTrip.toDataURL('image/jpeg', 1.0);
  const canvasImageHeightTrip = (canvasTrip.height * canvasImageWidth) / canvasTrip.width;

  pdf.addImage(imgDataTrip, 'PNG', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeightTrip);

  // Ajouter une nouvelle page pour commencer le planning
  pdf.addPage();

  // Capture et ajout du contenu Planning sur les pages suivantes
  const canvasPlanning = await html2canvas(tempDivPlanning, {
    allowTaint: false,
    useCORS: true,
    scale: 2
  });

  const imgDataPlanning = canvasPlanning.toDataURL('image/jpeg', 1.0);
  const canvasPlanningHeight = (canvasPlanning.height * canvasImageWidth) / canvasPlanning.width;
  const totalPDFPages = Math.ceil(canvasPlanningHeight / pdfHeight);

  // Ajout de la première page du planning
  //pdf.addImage(imgDataPlanning, 'PNG', topLeftMargin, 0, canvasImageWidth, canvasPlanningHeight);

  // Ajout des pages suivantes si nécessaire
  for (let i = 1; i < totalPDFPages; i++) {
    pdf.addPage();
    pdf.addImage(imgDataPlanning, 'PNG', topLeftMargin, -(pdfHeight * i), canvasImageWidth, canvasPlanningHeight);
  }

  // Sauvegarde du PDF
  pdf.save(`Document_${new Date().toLocaleString()}.pdf`);

  // Nettoyer le DOM
  document.body.removeChild(tempDivTrip);
  document.body.removeChild(tempDivPlanning);
};
