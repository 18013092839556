// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBxL24kntYgRwvaKCCliTdO_dH9y7N_DL8",
  authDomain: "alegotravel.firebaseapp.com",
  projectId: "alegotravel",
  storageBucket: "alegotravel.appspot.com",
  messagingSenderId: "812109652722",
  appId: "1:812109652722:web:0a5e6f41d0b7f13eda266b"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, storage };
export default firebaseConfig;
