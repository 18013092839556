<template>


  <div :class="['sumUp', { 'reduceWidth': !isFromProfile }]" v-if="!loading">

    <div v-if="showActivityPopUp" class="activity-popup-overlay">
      <div class="activity-popup-content">
        <h2>Vous pouvez créer votre planning d’activités directement sur Alego !</h2>
        <img src="@/assets/img/voyage/sumUp/planning.png" alt="Planning Preview" class="activity-popup-image">
        <div class="activity-popup-buttons">
          <button class="activity-popup-button" @click="createActivityPlan">Accéder aux activités</button>
          <button class="activity-popup-button-secondary" @click="closeActivityPopUp">Plus tard</button>
        </div>
      </div>
    </div>

    <UserAuth v-if="showAuthForm" @close="closeAuthForm"/>
    <div class="sumUp-title">
      <div class="return-back" @click="backToProfile">
        <img src="@/assets/img/voyage/hotels/arrow.png" alt="Retour" />
        <p>Retour</p>
      </div>
      <h2 style="margin: auto;">Récapitulatif du voyage</h2>
    </div>

    <div class="sumUp-banner">
      <div class="banner-cities">
        <img src="@/assets/img/voyage/sumUp/location.png" alt="Location">
        <p>{{ tripData.cityFrom }}</p>
        <img src="@/assets/img/voyage/sumUp/arrows.png" alt="fleches">
        <p>{{ tripData.cityTo }}</p>
      </div>
      <!--<div class="banner-separator"></div>-->
      <div class="on-line">
        <div class="nb-adults">
          <img src="@/assets/img/voyage/sumUp/profile.png" alt="profilIcon">
          <p>{{ tripData.nbPerson }} </p>
        </div>
        <!--<div class="banner-separator"></div>-->
        <div class="nb-adults">
          <img src="@/assets/img/voyage/sumUp/lit.png" alt="profilIcon">
          <p>{{ nbRooms }} chambre(s)</p>
        </div>
      </div>
      <!--<div class="banner-separator"></div>-->
      <div class="banner-dates">
        <img src="@/assets/img/voyage/sumUp/calendar.png" alt="calendar">
        <p>{{ formatDate(firstDayDate) }} - {{ formatDate(lastDayDate) }}</p>
      </div>
    </div>

    <div class="sumUp-first-section">
      <div class="sumUp-dates">
        <h2>Jour 1</h2>
        <h4>{{ formatDateLong(firstDayDate) }}</h4>
      </div>

      <div class="sumUp-info-bloc">
        <div class="vertical-bar"></div>
        <div class="sumUp-infos">
          
          <!-- PopUp -->
          <div id="transport-popup" class="flight-popup" v-if="showPopup">
            <div class="flight-popup-content">
              <span class="flight-close" @click="closePopup">&times;</span>
              <div class="flight-popup-header">
                <h2>
                  {{ selectedFlight ? selectedFlight.from : selectedGroundTransportation.outboundTrip.origine.city }}
                  <span class="flight-arrow">↔</span>
                  {{ selectedFlight ? selectedFlight.to : selectedGroundTransportation.outboundTrip.destination.city }}
                </h2>
              </div>

              <!-- Display departure segments only when selectedDate is 'departure' -->
              <div v-if="selectedDate === 'departure'">
                <div class="flight-selected-date">
                  <span class="flight-arrow-icon"><img style="margin-bottom: 5px; width: 20px; height: 20px;" src="@/assets/img/voyage/down-arrow-yellow.png" alt="↓"></span>
                  <span class="flight-date-text">
                    {{ selectedFlight ? selectedFlight.departureDateDisplay : selectedGroundTransportation.outboundTrip.departureDateDisplay }}
                  </span>
                </div>

                <template v-for="(segment, index) in (selectedFlight ? selectedFlight.segments.filter(seg => seg.segmentAller) : outboundTrip.segments)" :key="segment.id">
                  <div class="flight-segment">
                    <div class="segments-container">
                      <div class="segment-first-column">
                        <div class="segment-first-row">
                          <h3>{{ segment.departureTime || formatHourISO(segment.departureLocalISO) }}</h3>
                        </div>
                        <div class="segment-second-row">
                          <h4>{{ segment.flightDuration || calculateDuration(segment.departureLocalISO, segment.arrivalLocalISO) }}</h4>
                        </div>
                        <div class="segment-third-row">
                          <h3>{{ segment.arrivalTime || formatHourISO(segment.arrivalLocalISO) }}</h3>
                        </div>
                      </div>
                      <div class="segment-second-column">
                        <div class="segment-first-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                        <div class="segment-second-row">
                          <img src="@/assets/img/voyage/plane-top.png" v-if="selectedFlight" alt="plane">
                          <img style="transform: rotate(0deg);" v-if="!selectedFlight" src="@/assets/img/profile/train-grey.png" alt="train">
                        </div>
                        <div class="segment-third-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                      </div>
                      <div class="segment-third-column">
                        <div class="segment-first-row">
                          <h3>{{ segment.departureCity || segment.origin.city }} {{ segment.departureAirportCode ? '(' + segment.departureAirportCode + ')' : '' }}</h3>
                        </div>
                        <div class="segment-second-row">
                          <h4>{{ segment.airline || segment.provider.company }}</h4>
                        </div>
                        <div class="segment-third-row">
                          <h3>{{ segment.arrivalCity || segment.destination.city }} {{ segment.arrivalAirportCode ? '(' + segment.arrivalAirportCode + ')' : '' }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="escale" v-if="segment.layoverDuration && checkStopoverIndex(index)">
                    <img src="@/assets/img/voyage/clock.png" alt="clock">
                    <p><strong>Escale :</strong> {{ segment.layoverDuration }}</p>
                  </div>
                  <!-- Ajout de l'affichage des escales pour les groundTransportations -->
                  <div class="escale" v-if="!selectedFlight && outboundTrip.segments.length > index + 1">
                    <img src="@/assets/img/voyage/clock.png" alt="clock">
                    <p><strong>Escale :</strong> {{ calculateDuration(segment.arrivalLocalISO, outboundTrip.segments[index + 1].departureLocalISO) }}</p>
                  </div>
                </template>
              </div>

              <!-- Display return segments only when selectedDate is 'return' -->
              <div v-if="selectedDate === 'return'">
                <div class="flight-selected-date">
                  <span class="flight-arrow-icon">⏷</span>
                  <span class="flight-date-text">
                    {{ selectedFlight ? selectedFlight.returnDateTakeOffDisplay : selectedGroundTransportation.returnTrip.departureDateDisplay }}
                  </span>
                </div>

                <template v-for="(segment, index) in (selectedFlight ? selectedFlight.segments.filter(seg => !seg.segmentAller) : returnTrip.segments)" :key="segment.id">
                  <div class="flight-segment">
                    <div class="segments-container">
                      <div class="segment-first-column">
                        <div class="segment-first-row">
                          <h3>{{ segment.departureTime || formatHourISO(segment.departureLocalISO) }}</h3>
                        </div>
                        <div class="segment-second-row">
                          <h4>{{ segment.flightDuration || calculateDuration(segment.departureLocalISO, segment.arrivalLocalISO) }}</h4>
                        </div>
                        <div class="segment-third-row">
                          <h3>{{ segment.arrivalTime || formatHourISO(segment.arrivalLocalISO) }}</h3>
                        </div>
                      </div>
                      <div class="segment-second-column">
                        <div class="segment-first-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                        <div class="segment-second-row">
                          <img src="@/assets/img/voyage/plane-top.png" v-if="selectedFlight" alt="plane">
                          <img style="transform: rotate(0deg);" v-if="!selectedFlight" src="@/assets/img/profile/train-grey.png" alt="train">
                        </div>
                        <div class="segment-third-row"><img src="@/assets/img/voyage/line-dot.png" alt="line-dot"></div>
                      </div>
                      <div class="segment-third-column">
                        <div class="segment-first-row">
                          <h3>{{ segment.departureCity || segment.origin.city }} {{ segment.departureAirportCode ? '(' + segment.departureAirportCode + ')' : '' }}</h3>
                        </div>
                        <div class="segment-second-row">
                          <h4>{{ segment.airline || segment.provider.company }}</h4>
                        </div>
                        <div class="segment-third-row">
                          <h3>{{ segment.arrivalCity || segment.destination.city }} {{ segment.arrivalAirportCode ? '(' + segment.arrivalAirportCode + ')' : '' }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="escale" v-if="segment.layoverDuration && checkStopoverIndex(index)">
                    <img src="@/assets/img/voyage/clock.png" alt="clock">
                    <p><strong>Escale :</strong> {{ segment.layoverDuration }}</p>
                  </div>
                  <!-- Ajout de l'affichage des escales pour les groundTransportations -->
                  <div class="escale" v-if="!selectedFlight && returnTrip.segments.length > index + 1">
                    <img src="@/assets/img/voyage/clock.png" alt="clock">
                    <p><strong>Escale :</strong> {{ calculateDuration(segment.arrivalLocalISO, returnTrip.segments[index + 1].departureLocalISO) }}</p>
                  </div>
                </template>
              </div>

              <!-- Arrival date display -->
              <div class="flight-selected-date" v-if="selectedDate === 'departure'">
                <img src="@/assets/img/voyage/location.png" alt="pin">
                <span class="flight-date-text">{{ selectedFlight ? selectedFlight.departureDateLandingDisplay : outboundTrip.arrivalDateDisplay }}</span>
              </div>
              <div class="flight-selected-date" v-if="selectedDate === 'return'">
                <img src="@/assets/img/voyage/location.png" alt="pin">
                <span class="flight-date-text">{{ selectedFlight ? selectedFlight.returnDateDisplay : returnTrip.arrivalDateDisplay }}</span>
              </div>
            </div>
          </div>






          <!-- Section Transport Aller -->
          <div v-if="selectedFlight || selectedGroundTransportation" class="train-card">
            <div class="train-card-left" @click="handleReservationClick('departure')">
              <div class="rows-container">
                <div class="train-info">
                  <div class="train-row">
                    <h3 class="from-to">{{ selectedFlight ? selectedFlight.departure_airport : outboundTrip.origine.city }}</h3>
                    <p>{{ selectedFlight ? selectedFlight.departure_airline_name : outboundTrip.providers[0].company }}</p>
                    <h3 class="from-to-right">{{ selectedFlight ? selectedFlight.departure_landing_airport : outboundTrip.destination.city }}</h3>
                  </div>
                  <div class="line-container">
                    <span class="dot"></span>
                    <span class="line"></span>
                    <h4>{{ selectedFlight ? selectedFlight.departure_flight_duration : outboundTrip.durationDisplay }}</h4>
                    <span class="line"></span>
                    <span class="dot"></span>
                  </div>
                  <div class="time-row">
                    <div>
                      <h3>{{ selectedFlight ? selectedFlight.departureHour : outboundTrip.departureHour }}</h3>
                      <p>{{ selectedFlight ? selectedFlight.departureDateDisplay : outboundTrip.departureDateDisplay }}</p>
                    </div>
                    <p>{{ selectedFlight ? 
                        (selectedFlight.correspondanceAller === 0 ? 'Direct' : selectedFlight.correspondanceAller + ' escale' + (selectedFlight.correspondanceAller > 1 ? 's' : '')) :
                        (outboundTrip.numberOfSegments - 1 === 0 ? 'Direct' : (outboundTrip.numberOfSegments - 1) + ' correspondance' + ((outboundTrip.numberOfSegments - 1) > 1 ? 's' : ''))
                    }}</p>
                    <div>
                      <h3 style="text-align: right;">{{ selectedFlight ? selectedFlight.departureHourLanding : outboundTrip.arrivalHour }}</h3>
                      <p>{{ selectedFlight ? selectedFlight.departureDateLandingDisplay : outboundTrip.arrivalDateDisplay }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="train-card-right">
              <div class="text-container">
                <h2>{{ formatPrice(selectedFlight ? selectedFlight.price : outboundTrip.price) }}€</h2>
                <h4>par pers. </h4>
                <h5 style="color: var(--jaune); margin-top: 5px; margin-bottom: 5px;">{{ selectedFlight ? "A/R" : '(aller)' }}</h5>
                <button v-show="!expiredLink" class="more-details-button">
                  <a target="_blank" :href="selectedFlight ? selectedFlight.reservation_link : selectedGroundTransportation.bundle.redirectionLink" style="text-decoration: none; color: white;">Voir l'offre</a>
                </button>
              </div>
            </div>
          </div>

          <div v-show="expiredLink" class="expiredLink-bloc">
            <p>Le lien pour réserver ton transport a malheureusement expiré. Trouves-en un autre en cliquant ici :</p>
            <button class="more-details-button" @click="redirectToModifyTransport">
              Trouver un autre transport
            </button>
          </div>
          
          <!-- Section Hôtel -->
          <div class="hotel-card">
            <div class="hotel-card-left">
              <div class="rows-container">
                <div class="hotel-info">
                  <div class="hotel-image">
                    <img v-if="selectedHotel.images && selectedHotel.images.length > 0" :src="processHotelImage(selectedHotel.images[0])" alt="Hotel Image">
                    <img v-else src="@/assets/img/voyage/hotel.jpg" alt="Default Hotel Image">
                  </div>
                  <div class="hotel-details">
                    <h3 class="hotel-name">{{ selectedHotel.name }}</h3>
                    <div class="hotel-rating">
                      <span>{{ generateStarRating(selectedHotel.starRating) }}</span>
                      <span v-if="selectedHotel.ratings && selectedHotel.ratings.rating" class="hotel-score">{{ selectedHotel.ratings.rating }}</span>
                    </div>
                    <p class="hotel-address">{{ selectedHotel.address }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="hotel-card-right">
              <div class="text-container">
                <p class="daily-price">{{ Math.round(selectedHotel.dailyPrice) }}€/ nuit</p>
                <p><strong>{{ Math.round(totalHotelPrice) }}€</strong> les {{ nights }} nuits</p>
                <button class="more-details-button">
                  <a target="_blank" :href="selectedHotel.reservationLink" style="text-decoration: none; color: white;">Voir l'offre</a>
                </button>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="sumUp-dates">
        <h2>Jour {{ nbDays }}</h2>
        <h4>{{ formatDateLong(lastDayDate) }}</h4>
      </div>
      
      <div class="sumUp-info-bloc">
        <div class="vertical-bar" style="height: auto;"></div>
        <div class="sumUp-infos">
          <!-- Section Transport Retour -->
          <div v-if="selectedFlight || selectedGroundTransportation" class="train-card">
            <div class="train-card-left" @click="handleReservationClick('return')">
              <div class="rows-container">
                <div class="train-info">
                  <div class="train-row">
                    <h3 class="from-to">{{ selectedFlight ? selectedFlight.return_takeOff_airport : returnTrip.origine.city }}</h3>
                    <p>{{ selectedFlight ? selectedFlight.return_airline_name : returnTrip.providers[0].company }}</p>
                    <h3 class="from-to-right">{{ selectedFlight ? selectedFlight.return_airport : returnTrip.destination.city }}</h3>
                  </div>
                  <div class="line-container">
                    <span class="dot"></span>
                    <span class="line"></span>
                    <h4>{{ selectedFlight ? selectedFlight.return_flight_duration : returnTrip.durationDisplay }}</h4>
                    <span class="line"></span>
                    <span class="dot"></span>
                  </div>
                  <div class="time-row">
                    <div>
                      <h3>{{ selectedFlight ? selectedFlight.returnHourTakeOff : returnTrip.departureHour }}</h3>
                      <p>{{ selectedFlight ? selectedFlight.returnDateTakeOffDisplay : returnTrip.departureDateDisplay }}</p>
                    </div>
                    <p>{{ selectedFlight ? 
                        (selectedFlight.correspondanceRetour === 0 ? 'Direct' : selectedFlight.correspondanceRetour + ' escale' + (selectedFlight.correspondanceRetour > 1 ? 's' : '')) :
                        (returnTrip.numberOfSegments - 1 === 0 ? 'Direct' : (returnTrip.numberOfSegments - 1) + ' correspondance' + ((returnTrip.numberOfSegments - 1) > 1 ? 's' : ''))
                    }}</p>
                    <div>
                      <h3 style="text-align: right;">{{ selectedFlight ? selectedFlight.returnHour : returnTrip.arrivalHour }}</h3>
                      <p>{{ selectedFlight ? selectedFlight.returnDateDisplay : returnTrip.arrivalDateDisplay }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="train-card-right">
              <div class="text-container">
                <h2>{{ formatPrice(selectedFlight ? selectedFlight.price : returnTrip.price) }}€</h2>
                <h4>par pers.</h4>
                <h5 style="color: var(--jaune); margin-top: 5px; margin-bottom: 5px;"> {{ selectedFlight ? "A/R" : "(retour)" }}</h5>
                <button v-show="!expiredLink" class="more-details-button">
                  <a target="_blank" :href="selectedFlight ? selectedFlight.reservation_link : selectedGroundTransportation.bundle.redirectionLink" style="text-decoration: none; color: white;">Voir l'offre</a>
                </button>

              </div>
            </div>
          </div>

          <div v-show="expiredLink" class="expiredLink-bloc">
            <p>Le lien pour réserver ton transport a malheureusement expiré. Trouves-en un autre en cliquant ici :</p>
            <button class="more-details-button" @click="redirectToModifyTransport">
              Trouver un autre transport
            </button>
          </div>


        </div>
      </div>

      <div class="sumUp-info-bloc">
        <div class="vertical-bar" style="height: 0px;"></div>
        <div class="sumUp-total-price">
          <h4>Tarif total</h4>
          <div class="sumUp-dot-line"></div>
          <div class="sumUp-end-total-price">
            <h4>{{ Math.round(hotelAndFlightPricePerPerson) }}€ </h4>
            <p>/pers. les {{ nights + 1 }} jours</p>
          </div>
        </div>
      </div>

      <div class="sumUp-separation-line"></div>

      <div class="activity-planning">
        <p>Vous pouvez créer votre <strong>planning d’activités</strong> directement sur notre plateforme :</p>
        <button class="start-button" @click="createActivityPlan()">Commencer</button>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Chargement des données...</p>
  </div>
</template>


<script>
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from 'axios'; 
import UserAuth from '@/views/UserAuth.vue';

export default {
  name: "SumUp",
  components: {
    UserAuth
  },
  props: {
    docRef: {
      type: String,
      required: true,
    },
    isFromProfile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFlight: null,
      selectedGroundTransportation: null,
      selectedHotel: {},
      tripData: {},
      loading: true,
      nights: 0,
      totalHotelPrice: 0,
      hotelAndFlightPricePerPerson: 0,
      checkin: "",
      checkout: "",
      user: null,
      nbDays: 0,
      firstDayDate: null,
      lastDayDate: null,
      outboundTrip: {},
      returnTrip: {},
      showPopup: false,
      hotelImageHeight: 'auto',
      nbRooms: 1,
      isMobile: false,
      expiredLink: false,
      showAuthForm: false,
      isLoggedIn: false,
      showActivityPopUp: false,
    };
  },
  created() {
    this.auth = getAuth();
    onAuthStateChanged(this.auth, user => {
      this.isLoggedIn = !!user;
      if (user) {
        this.showAuthForm = false;
      }
    });
  },
  async mounted() {
    try {
      console.log("Doc Ref : ", this.docRef);
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.user = user;
        }
      });

      if (this.docRef) {
        await this.loadTripDatas(this.docRef);
        this.calculatePrices();
        this.calculateNbDays(); 
        await this.checkReservationLinks(); // Vérifier les liens de réservation
        this.loading = false;
      } else {
        console.error("docRef is missing");
      }
      this.isMobile = window.innerWidth < 768;
      console.log("expiredLink", this.expiredLink);

      if (!this.isFromProfile) {
        this.showActivityPopUp = true;
      }
    } catch (error) {
      console.error("Error loading informations:", error);
    }
    window.addEventListener('resize', this.setHotelImageHeight);

  },

  methods: {
    closeActivityPopUp() {
      this.showActivityPopUp = false;
    },

    closeAuthForm() {
      this.showAuthForm = false;
    },
    createActivityPlan() {
      if (this.isLoggedIn) {
        this.$router.push({ name: 'ActivitiesPage', params: { tripId: this.docRef } });
      } else {
        this.showAuthForm = true;
      }
    },
    async checkReservationLinks() {
        try {
            // Check reservation link for ground transportation
            if (this.selectedGroundTransportation && this.selectedGroundTransportation.bundle.redirectionLink) {
                // Prepare the request body with the reservation link and type "train"
                const requestBody = {
                    url: this.selectedGroundTransportation.bundle.redirectionLink,
                    type: "train"
                };

                // Make a POST request to the API to check the reservation link
                const response = await axios.post(
                    "https://europe-west1-alegotravel.cloudfunctions.net/api/checkReservationLink",
                    requestBody,
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                );
                console.log(response);
                // Check the response from the API
                if (response.data === true) {
                    this.expiredLink = true; // Update the variable if the link is expired
                } else {
                    this.expiredLink = false; // Update the variable if the link is not expired
                }
            }

            // For flights, keep the existing logic for now
            if (this.selectedFlight && this.selectedFlight.reservation_link) {
                const response = await axios.get(this.selectedFlight.reservation_link);

                // Use the isLinkExpired function to check if the flight link is expired
                if (this.isLinkExpired(response.data)) {
                    this.expiredLink = true; // Update the variable if the link is expired
                }
            }

        } catch (error) {
            console.error("Erreur lors de la vérification du lien de réservation :", error);
            this.expiredLink = false; // Update the variable in case of an error during the request
        }
    },
    redirectToModifyTransport() {
      // Rediriger vers la route 'ModifyTransport' avec le tripId approprié
      this.$router.push({ name: 'ModifyTransport', params: { tripId: this.docRef } });
    },
    checkStopoverIndex(index) {
      if (this.selectedDate === 'departure') {
        return index < this.selectedFlight.correspondanceAller;
      } else if (this.selectedDate === 'return') {
        return index < this.selectedFlight.correspondanceRetour + this.selectedFlight.correspondanceAller + 1;
      }
      return false;
    },
    async loadTripDatas(docRef) {
      try {
        const tripDataRef = doc(db, "tripdatas", docRef);
        const tripDataSnapshot = await getDoc(tripDataRef);
        if (tripDataSnapshot.exists()) {
          const data = tripDataSnapshot.data();
          this.tripData = data;
          if (data.selectedFlight) {
            this.selectedFlight = data.selectedFlight;
          } else if (data.selectedGroundTransportation) {
            this.selectedGroundTransportation = data.selectedGroundTransportation;
            this.outboundTrip = this.selectedGroundTransportation.outboundTrip;
            this.returnTrip = this.selectedGroundTransportation.returnTrip;
          }
          this.selectedHotel = data.selectedHotel || {};
        } else {
          console.log("Problème de chargement des données");
        }
      } catch (error) {
        console.error("Problème lors du chargement des données : ", error);
      }
    },
    setHotelImageHeight() {
      // Récupérer l'élément de l'image
      const hotelImageElement = this.$refs.hotelImage;

      // Vérifier si l'élément existe et définir la hauteur
      if (hotelImageElement) {
        const width = hotelImageElement.offsetWidth;
        this.hotelImageHeight = `${(width * 2) / 3}px`;
      }
    },
    // Fonction pour formater l'heure au format hh:mm
    formatHourISO(dateTimeString) {
        // Créer un nouvel objet Date à partir de la chaîne de caractères
        const date = new Date(dateTimeString);
        
        // Récupérer les heures et les minutes
        let hours = date.getHours();
        let minutes = date.getMinutes();
        
        // Ajouter un zéro devant les chiffres simples
        if (hours < 10) hours = '0' + hours;
        if (minutes < 10) minutes = '0' + minutes;
        
        // Retourner l'heure formatée
        return `${hours}:${minutes}`;
    },

    calculateDuration(departure, arrival) {
        // Convertir les chaînes de caractères en objets Date
        const departureDate = new Date(departure);
        const arrivalDate = new Date(arrival);
        
        // Calculer la différence en millisecondes
        const differenceInMilliseconds = arrivalDate - departureDate;
        
        // Convertir la différence en minutes
        const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
        
        // Calculer les heures et les minutes
        const hours = Math.floor(differenceInMinutes / 60);
        const minutes = differenceInMinutes % 60;
        
        // Retourner la durée formatée
        if (hours > 0) {
            return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`;
        } else {
            return `${minutes}mn`;
        }
    },

    // Fonction pour formater la date au format DD/MM/YYYY
    formatDateISO(dateTimeString) {
        // Créer un nouvel objet Date à partir de la chaîne de caractères
        const date = new Date(dateTimeString);
        
        // Récupérer le jour, le mois et l'année
        let day = date.getDate();
        let month = date.getMonth() + 1; // Les mois sont indexés à partir de 0
        const year = date.getFullYear();
        
        // Ajouter un zéro devant les chiffres simples
        if (day < 10) day = '0' + day;
        if (month < 10) month = '0' + month;
        
        // Retourner la date formatée
        return `${day}/${month}/${year}`;
    },

    processHotelImage(image) {
      if (image) {
        return image.replace("{size}", "x500");
      } else {
        return require("@/assets/img/voyage/hotel.jpg");
      }
    },

    generateStarRating(score) {
      const roundedScore = Math.round(score);
      return "★".repeat(roundedScore);
    },

    backToProfile() {
      if (this.isFromProfile) {
        this.$router.push({ name: 'UserTrips', query: { user: JSON.stringify(this.user) } });
      } else {
        this.$emit('update-step', 'selectHotelDetails');
      }
    },

    formatDate(date) {
      if (!date) return "Date non définie";

      const dateObj = date instanceof Date ? date : new Date(date);
      if (isNaN(dateObj.getTime())) return "Date invalide";

      const jours = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
      const mois = ["janv", "févr", "mars", "avr", "mai", "juin", "juil", "août", "sept", "oct", "nov", "déc"];

      const jourSemaine = jours[dateObj.getDay()];
      const jourMois = dateObj.getDate();
      const moisAnnee = mois[dateObj.getMonth()];

      return `${jourSemaine}. ${jourMois} ${moisAnnee}`;
    },

    formatDateLong(date) {
      if (!date) return "Date non définie";

      const dateObj = date instanceof Date ? date : new Date(date);
      if (isNaN(dateObj.getTime())) return "Date invalide";

      const jours = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
      const mois = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];

      const jourSemaine = jours[dateObj.getDay()];
      const jourMois = dateObj.getDate();
      const moisAnnee = mois[dateObj.getMonth()];

      return `${jourSemaine} ${jourMois} ${moisAnnee}`;
    },

    calculatePrices() {

      this.nbRooms = Math.ceil(this.tripData.nbPerson / 2);

      if (this.selectedFlight && this.selectedFlight.departureDateLanding && this.selectedFlight.returnDateTakeOff) {
        const departureDate = new Date(this.selectedFlight.departureDateLanding);
        const returnDate = new Date(this.selectedFlight.returnDateTakeOff);
        const oneDay = 24 * 60 * 60 * 1000;
        this.checkin = this.selectedFlight.departureDateLandingDisplay;
        this.checkout = this.selectedFlight.returnDateTakeOffDisplay;
        this.nights = Math.round(Math.abs((returnDate - departureDate) / oneDay));

        this.totalHotelPrice = this.nights * this.nbRooms * (this.selectedHotel ? this.selectedHotel.dailyPrice : 0);

        const hotelPricePerPerson = this.totalHotelPrice / (this.tripData.nbPerson || 1);
        this.hotelAndFlightPricePerPerson = hotelPricePerPerson + (this.selectedFlight ? this.selectedFlight.price : 0);
      } else if (this.selectedGroundTransportation && this.selectedGroundTransportation.outboundTrip && this.selectedGroundTransportation.returnTrip) {
        const departureDate = new Date(this.selectedGroundTransportation.outboundTrip.departureDate);
        const returnDate = new Date(this.selectedGroundTransportation.returnTrip.departureDate);
        const oneDay = 24 * 60 * 60 * 1000;
        this.checkin = this.selectedGroundTransportation.outboundTrip.departureDateDisplay;
        this.checkout = this.selectedGroundTransportation.returnTrip.departureDateDisplay;
        this.nights = Math.round(Math.abs((returnDate - departureDate) / oneDay));

        
        this.totalHotelPrice = this.nights * this.nbRooms * (this.selectedHotel ? this.selectedHotel.dailyPrice : 0);

        const hotelPricePerPerson = this.totalHotelPrice / (this.tripData.nbPerson || 1);
        const totalTransportationPrice = (this.selectedGroundTransportation.outboundTrip.price || 0) + (this.selectedGroundTransportation.returnTrip.price || 0);
        this.hotelAndFlightPricePerPerson = hotelPricePerPerson + totalTransportationPrice;
      }
    },
    handleReservationClick(way) {
      this.showPopup = true;
      this.selectedDate = way;
    },
    closePopup() {
      this.showPopup = false;
    },
    calculateNbDays() {
      const oneDay = 24 * 60 * 60 * 1000;
      const isValidDate = (date) => date instanceof Date && !isNaN(date);

      let departureDate, returnDate;

      // Utiliser les dates du tripData par défaut
      if (this.tripData.departureDate && this.tripData.returnDate) {
        departureDate = this.tripData.departureDate.toDate();
        returnDate = this.tripData.returnDate.toDate();
      }

      // Si les dates de vol sont disponibles, les utiliser
      if (this.selectedFlight && this.selectedFlight.departureDateLanding && this.selectedFlight.returnDateTakeOff) {
        departureDate = new Date(this.selectedFlight.departureDateLanding);
        returnDate = new Date(this.selectedFlight.returnDateTakeOff);
      }

      // Si les dates de transport terrestre sont disponibles, les utiliser
      if (this.selectedGroundTransportation && this.outboundTrip.arrivalDate && this.returnTrip.departureDate) {
        departureDate = new Date(this.outboundTrip.arrivalDate);
        returnDate = new Date(this.returnTrip.departureDate);
      }

      if (this.tripData.duration && this.tripData.duration > 0) {
        if (this.selectedGroundTransportation){
          this.nbDays = this.tripData.duration +1;
        } else {
          this.nbDays = this.tripData.duration;
        }  
      } else if (isValidDate(departureDate) && isValidDate(returnDate)) {
        this.nbDays = Math.round(Math.abs((returnDate - departureDate) / oneDay)) + 1;
      } else {
        this.nbDays = 0;
      }

      if (this.tripData.exactDates === false) {
        if (this.selectedFlight) {
          this.firstDayDate = this.selectedFlight.departureDate;
          this.lastDayDate = this.selectedFlight.returnDate;
        } else if (this.selectedGroundTransportation) {
          this.firstDayDate = this.outboundTrip.arrivalDate;
          this.lastDayDate = this.returnTrip.departureDate;
        }
      } else {
        this.firstDayDate = departureDate;
        this.lastDayDate = returnDate;
      }
    },

    formatPrice(price) {
      return Math.round(price * 10) / 10;
    },

    async saveTrip() {
      if (!this.user) {
        alert("Vous devez être connecté pour enregistrer le voyage.");
        return;
      }

      try {
        const tripDataRef = doc(db, "tripdatas", this.docRef);
        const tripDataSnapshot = await getDoc(tripDataRef);

        if (tripDataSnapshot.exists()) {
          const tripData = tripDataSnapshot.data();

          if (!tripData.userId) {
            // Mettre à jour le champ userId dans tripdatas
            await updateDoc(tripDataRef, { userId: this.user.uid });

            // Ajouter le tripId à la collection users
            const userRef = doc(db, "users", this.user.uid);
            await updateDoc(userRef, {
              tripIds: arrayUnion({
                tripId: this.docRef,
                addedAt: new Date().toISOString()
              })
            });
          }
          // Rediriger vers ProfileContent
          this.$router.push('/profile/trips');
        } else {
          console.log("Problème lors de la récupération des données du voyage");
        }
      } catch (error) {
        console.error("Erreur lors de l'enregistrement du voyage : ", error);
      }
    },
  }
};
</script>



<style scoped>

.sumUp {
    width: 100%;
    color: var(--dark-gray);
}

.sumUp-title {
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}

.sumUp-title h2 {
    color: var(--vert);
    font-size: 1.3rem;
}

.return-back {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    color: #525252;
    cursor: pointer;
}

.return-back img {
    width: 20px;
    height: 20px;
}

.save-Trip {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    background-color: var(--vert);
    padding: 2px 15px;
    border-radius: 50px;
    color: white;
    font-size: 0.9rem;
    font-weight: 600;
}

.save-Trip img {
    width: 35px;
    height: 35px;
}

.sumUp-banner {
    width: 80%;
    border-radius: 50px;
    padding: 0px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #525252;
    background-color: var(--white);
    margin: auto;
}

.sumUp-banner img {
    width: 20px;
    height: 20px;
}

.banner-separator {
    width: 1px;
    height: 40px;
    background-color: #525252;
    display: block;
}

.banner-cities {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vh;
    font-weight: 600;
    font-size: 0.9rem;
    color: #525252;
}

.nb-adults {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #525252;
}

.banner-dates {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3vh;
    font-size: 0.9rem;
    color: #525252;
    font-weight: 600;
}

.sumUp-first-section {
    width: 90%;
    margin: auto;
}

.sumUp-dates {
    display: flex;
    flex-direction: row;
    color: #434343;
    margin: 20px 0 20px 30px;
    align-items: center;
    gap: 70px;
}

.sumUp-dates h4 {
    color: var(--vert);
    font-weight: 500;
    font-size: 1rem;
}

.sumUp-dates h2 {
    font-weight: 500;
    font-size: 1.3rem;
}

.vertical-bar {
    width: 1px;
    height: auto;
    background-color: #525252;
    display: block;
    margin-bottom: 20px;
}

.sumUp-infos {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 20px;
}

/* Section Hôtel */
.hotel-info .parent {
    display: grid;
    grid-template-columns: 0.7fr 1.5fr 0.1fr 1.3fr; /* Fixed percentage widths to match flight info */
    grid-template-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.hotel-info .div1 { grid-area: 1 / 1 / 2 / 2; display: flex; align-items: center; justify-content: center;}
.hotel-info .div2 { grid-area: 1 / 2 / 2 / 3; display: flex; flex-direction: column; justify-content: space-between; margin-left: 10%;}
.hotel-info .div3 { grid-area: 1 / 4 / 2 / 5; display: flex; flex-direction: column; justify-content: center; align-items: center; border-left: 1px solid gray; padding-left: 40px;}



.hotel-image {
  width: 100%;
  border-radius: 18px;

  width: calc( 100% * 1 /3)
}

.hotel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 300px;
  border-radius: 18px;

}


.hotel-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.hotel-rating {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
}

.hotel-rating span:first-child {
    color: gold;
}

.hotel-score {
    background-color: #d6a85c;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    width: 30px;
}

.hotel-address {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
}

.hotel-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.hotel-price p {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.reserve-button {
    background-color: #d6a85c;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

.reserve-button:hover {
    background-color: #c5954b;
}

.sumUp-total-price {
    background-color: #d6a85c; /* Use the same color as in the image */
    color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    border-radius: 25px;
    margin: 55px auto 25px auto;
}

.sumUp-total-price h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
}

.sumUp-dot-line {
    flex-grow: 1;
    border-bottom: 2px dotted white;
    margin: 0 20px;
}

.sumUp-end-total-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.sumUp-end-total-price h4 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
    margin-right: 25px;
}

.sumUp-end-total-price p {
    margin: 0;
    font-size: 0.9rem;
}

.sumUp-separation-line {
    width: 100%;
    border-bottom: 1px solid darkgray;
    margin: 40px 0;
}

.activity-planning {
    text-align: center;
    margin: 50px 0;
}

.activity-planning p {
    font-size: 1.3rem;
    color: var(--vert); /* Use the same color as the button */
    font-weight: 300;
    width: 70%;
    margin: 30px auto;
    line-height: 3rem;
}

.start-button {
    background-color: var(--vert); /* Button color */
    color: white;
    border: none;
    border-radius: 40px; /* Rounded corners */
    padding: 20px 35px;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.start-button:hover {
    background-color: var(--vert); /* Darker shade for hover effect */
}

.sumUp-info-bloc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 70px;
    margin-left: 30px;
}

.on-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}

.expiredLink-bloc {
  font-size: 0.8rem;
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.expiredLink-bloc button {
  padding: 5px;
  width: 30%;
  margin: auto;
}
/* Media Query for Mobile */
@media (max-width: 768px) {
  
  .on-line {
    font-size: 0.8rem;
  }
  .sumUp-banner {
    padding: 0px 10px;
    width: 90%;
    flex-wrap: wrap;
    border-radius: 10px;
    justify-content: space-around;
  }

  .banner-separator {
    display: none;
  }

  .banner-cities {
    font-size: 0.7rem;
  }

  .nb-adults {
    font-size: 0.8rem;

  }

  .banner-dates {
    font-size: 0.7rem;
  }

  .sumUp-title {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .return-back {
    margin-top: -10px;
    font-size: 0.7rem;
    left: 30px;
    position: absolute;
  }

  .sumUp-title h2 {
    color: var(--vert);
    font-size: 1rem;
    padding-top: 40px;
  }

  .vertical-bar {
    display: none;
  }

  .sumUp-infos {
    width: 100%;
    margin: 0;
  }

  .sumUp-info-bloc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }

  .sumUp-first-section {
    width: 100%;
  }

  .sumUp-dates {
    margin: 20px 20px 0 20px;
    gap: 30px;
  }

  .sumUp-dates h2 {
    font-size: 1.1rem;
  }

  .sumUp-dates h4 {
    font-size: 0.9rem;
    margin: 15px 10px 10px 10px;
  }

  .hotel-rating {
    justify-content: center;
  }

  .hotel-address {
    font-size: 0.7rem;
  }

  .sumUp-total-price {
    padding: 20px;
    margin: 25px 0px;
  }

  .sumUp-total-price h4 {
    font-size: 1rem;
  }

  .sumUp-dot-line {
    display: none;
  }

  .sumUp-end-total-price {
    display: flex;
    flex-direction: column;
    align-items: center;
  } 

  .sumUp-end-total-price h4 {
    margin: 0;
  }

  .sumUp-separation-line {
    width: 90%;
    margin: 30px auto 0 auto;
  }

  .activity-planning {
    text-align: center;
    margin: 20px 0;
  }

  .activity-planning p {
    font-size: 1rem;
    margin: 0 auto !important;
    text-align: center;
    line-height: 2rem;
  }

  .start-button {
    color: white;
    border: none;
    border-radius: 40px; /* Rounded corners */
    padding: 15px 20px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 30px;
  }

  .reduceWidth {
    width: 90%;
    margin: auto;
  }

}

.train-card {
  border: 1px solid var(--gray);
  border-radius: 21px;
  width: 100%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 3fr 1.2fr; /* Augmenter la largeur de la partie droite */
  background-color: var(--white);
  transition: box-shadow 0.3s ease-in-out;
}

.train-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.train-card-left {
  padding: 40px;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
  background-color: var(--white);
  cursor: pointer;
}

.train-card-right {
  background-color: var(--vert);
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.train-card-right .text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.train-card-right h2,
.train-card-right h4 {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #E9B949;
}

.train-card-right h2 {
  margin-bottom: 5px;
  font-size: 1.3rem !important;
}

.train-card-right h4 {
  font-weight: normal;
  font-size: 1.3rem;
}

.train-card-right .more-details-button {
  background-color: var(--jaune);
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.train-card-right .more-details-button a {
  color: white;
  text-decoration: none;
}

.rows-container {
  display: grid;
  row-gap: 20px;
}

.train-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.train-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.train-row .from-to {
  width: 20%;
}

.train-row .from-to-right {
  width: 20%;
  text-align: right;
}

.line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--dark-gray);
  border-radius: 50%;
  margin: 0;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: var(--dark-gray);
  margin: 0;
}

.time-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.train-row h3,
.time-row h3 {
  color: var(--dark-gray);
  font-weight: bold;
  margin: 0;
}

.train-row p,
.line-container h4,
.time-row p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--dark-gray);
  text-align: center;
}

.train-row p,
.time-row p {
  flex: 1;
  text-align: center;
}

.line-container h4 {
  font-weight: bold;
  margin: 0 20px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .train-card {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 10px auto;
    padding: 0;
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 21px;
  }

  .train-card-left {
    border-top-right-radius: 21px;
    padding: 20px;
  }

  .train-card-right {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 21px;
  }

  .train-card-right {
    border-radius: 0;
    padding: 10px;
    border-top: 1px solid var(--gray);
    border-radius: 0 0 21px 21px;
    justify-content: flex-end;
    background-color: var(--vert);
    color: var(--jaune);
  }

  .train-card-right .text-container {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
    justify-content: flex-end;
  }

  .train-card-right .text-container h2 {
    font-size: 1rem;
    font-weight: bold;
  }

  .train-card-right .text-container h4 {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .train-card-right .more-details-button {
    font-size: 0.8rem;
  }

  .train-row .from-to, .train-row .from-to-right {
    font-size: 0.8rem;
    width: 30%;
  }

  .train-row p, .time-row p {
    font-size: 0.7rem;
  }

  .time-row h3 {
    font-size: 0.9rem;
  }

  .train-card-right .more-details-button {
    margin-top: 0px;
    margin-left: 20px;
  }

}

.hotel-card {
  border: 1px solid var(--gray);
  border-radius: 21px;
  width: 100%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 3fr 1.2fr; /* Augmenter la largeur de la partie droite */
  background-color: var(--white);
  transition: box-shadow 0.3s ease-in-out;
}

.hotel-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.hotel-card-left {
  padding: 40px 20px;
  border-top-left-radius: 21px;
  border-bottom-left-radius: 21px;
  background-color: var(--white);
  display: flex;
  align-items: center;
}

.hotel-card-right {
  background-color: var(--vert);
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hotel-card-right .text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hotel-card-right p {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #E9B949;
  font-size: 1.2rem;
}

.hotel-card-right p:first-child {
  margin-bottom: 5px;
  font-size: 1.5rem;
  font-weight: bold;
}

.hotel-card-right button {
  background-color: var(--jaune);
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
}

.hotel-info {
  display: flex;
  align-items: center;
}



.hotel-details {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-left: 20px;
}

.hotel-name {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
}

.hotel-rating span:first-child {
  color: gold;
}

.hotel-score {
  background-color: #d6a85c;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  width: 40px;
  text-align: center;
}



/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .hotel-card {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 10px auto;
    padding: 0;
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 21px;
  }

  .hotel-card-left {
    border-top-right-radius: 21px;
    padding: 5px;
    display: flex;
    flex-direction: column;
  }

  .hotel-card-right {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 21px;
  }

  .hotel-card-right {
    border-radius: 0;
    padding: 10px;
    border-top: 1px solid var(--gray);
    border-radius: 0 0 21px 21px;
    justify-content: flex-end;
    background-color: var(--vert);
    color: var(--jaune);
  }

  .hotel-card-right .text-container {
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
    justify-content: flex-end;
  }

  .hotel-card-right .text-container p:first-child {
    font-size: 1rem;
    font-weight: bold;
  }

  .hotel-card-right .text-container p {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .hotel-info {
    flex-direction: column;
  }

  .hotel-details {
    margin-left: 0;
    text-align: center;
  }

  .hotel-card-right .more-details-button {
    margin-top: 0px;
    margin-left: 0px;
    padding: 7px;
    font-size: 0.8rem;
  }

  .hotel-card .daily-price {
    margin: 0 !important;
    font-size: 0.8rem !important;
  }

  .hotel-name {
    font-size: 1rem;
    margin-top: 15px;
  }

  .hotel-image {
    width: 100%;
  }
  
  .hotel-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 18px;
    max-height: calc(100% / 2); /* Limiter la hauteur à 2/3 de la largeur */
    max-height: 200px;
    border-radius: 18px;
  }
}

.flight-popup {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.flight-popup-content {
  background-color: var(--white);
  margin: 5% auto;
  padding: 20px;
  border: 1px solid var (--gray);
  width: 50%;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.flight-close {
  color: var(--black);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.flight-close:hover,
.flight-close:focus {
  color: var(--gray);
  text-decoration: none;
  cursor: pointer;
}

.flight-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.flight-popup-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.flight-popup-header .flight-arrow {
  margin: 0 5px;
}

.flight-popup-header .button-choose {
  padding: 10px 20px;
  background-color: var(--jaune);
  cursor: pointer;
  border-radius: 20px;
  margin-right: 30px;
  color: var(--white);
}

.flight-dates {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.flight-date {
  margin: 0 10px;
  padding: 5px 10px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: border-bottom 0.3s;
  color: var(--dark-gray);
  font-weight: normal;
}

.flight-date.flight-selected {
  border-bottom: 2px solid var(--black);
  color: var(--dark-gray);
  font-weight: bold;
}

.flight-date:hover {
  border-bottom: 2px solid var(--gray);
}

.flight-selected-date {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: var(--jaune);
  margin-top: 10px;
}

.flight-arrow-icon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.flight-date-text {
  font-size: 1.1rem;
  font-weight: 600;
}

.flight-segment {
  background-color: #dde3e4;
  padding: 10px;
  border-radius: 10px;
  margin: 10px auto;
  width: 80%;
}

.segments-container {
  width: 90%;
  display: flex;
  margin: auto;
}

.segment-first-column {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.segment-second-column {
  flex: 0 0 25%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.segment-third-column {
  flex: 0 0 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.segment-first-column h4,
.segment-third-column h4 {
  font-size: 0.7rem;
  font-weight: 500;
  font-style: italic;
  color: var(--dark-gray);
}

.segment-first-column h3,
.segment-third-column h3 {
  font-size: 0.9rem;
  color: var(--dark-gray);
}

.segment-second-column .segment-first-row img,
.segment-second-column .segment-third-row img {
  width: 40px;
  height: 70px;
  margin: auto 5px;
}

.segment-second-column .segment-second-row img {
  transform: rotate(180deg);
  height: 30px;
  margin: auto 15px;
}

.escale {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 40%;
  margin: auto;
  align-items: center;
  font-size: 0.8rem;
  gap: 15px;
}

.escale img {
  width: 30px;
  height: 30px;
  margin-left: 13px;
}

.flight-selected-date img {
  width: 25px;
  height: auto;
  margin: 10px 10px;
}

@media (max-width: 768px) {
  .flight-popup-content {
    margin: 20% auto;
    padding: 20px;
    border: 1px solid var (--gray);
    width: 80%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s;
  }

  .flight-popup-header h2 {
    font-size: 0.9rem;
  }

  .flight-date-text {
    font-size: 0.8rem;
  }

  .segment-first-row h3, .segment-third-row h3, .segment-second-row h4 {
    font-size: 0.7rem;
  }

  .segment-first-row img, .segment-third-row img {
    height: 40px !important;
    width: 20px !important;
  }

  .segment-third-row {
    display: flex;
    align-items: center;
  }

  .segment-second-row img {
    width: 23px !important;
    height: 23px !important;
  }
}

.activity-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.activity-popup-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  width: 70%;
  max-width: 700px;
}

.activity-popup-content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.activity-popup-content .activity-popup-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.activity-popup-button {
  background-color: #d6a85c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  width: 40%;
}

.activity-popup-button-secondary {
  background-color: transparent;
  color: #d6a85c;
  border: 2px solid #d6a85c;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 1rem;
  margin-top: 10px;
  cursor: pointer;
  width: 40%;
}

.activity-popup-buttons {
  display: flex;
  justify-content: space-around;
  margin: auto;
}

</style>

