import { doc, setDoc, getDocs, getDoc, query, collection, where} from 'firebase/firestore';
import { db } from '@/firebase';

// Ajouter ou mettre à jour les informations utilisateur
export const upsertUser = async (user, firstName = '', lastName = '') => {
  const userRef = doc(db, 'users', user.uid);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    const existingUser = userSnap.data();
    await setDoc(userRef, {
      ...existingUser, // Conserve les données existantes de l'utilisateur
      firstName: firstName || existingUser.firstName || '',
      lastName: lastName || existingUser.lastName || '',
      email: user.email,
      photoURL: user.photoURL,
      providerData: user.providerData
    }, { merge: true });
  } else {
    await setDoc(userRef, {
      uid: user.uid,
      firstName: firstName,
      lastName: lastName,
      email: user.email,
      photoURL: user.photoURL,
      providerData: user.providerData
    });
  }
};

export const checkUserExists = async (email) => {
    const q = query(collection(db, 'users'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs[0].data();
      return userData;
    }
    return null;
  };
