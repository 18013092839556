import { db } from '../firebase.js';
import { collection, addDoc, doc, updateDoc, arrayUnion, Timestamp } from 'firebase/firestore';

// Fonction pour créer un document dans Firestore
export async function creerDocumentFirestore(data) {
  try {
    const docRef = await addDoc(collection(db, 'tripdatas'), data);
    console.log('Document written with ID: ', docRef.id);
    return docRef.id; // Retournez l'ID du document pour confirmation
  } catch (error) {
    console.error('Error adding document: ', error);
    throw error; // Lancez une erreur pour la gérer dans le composant appelant
  }
}

// Fonction pour ajouter un tripId à un utilisateur
export async function addTripIdToUser(userId, tripId) {
  try {
    const userDocRef = doc(db, 'users', userId);
    await updateDoc(userDocRef, {
      tripIds: arrayUnion({
        tripId: tripId,
        dateAdded: Timestamp.now()
      })
    });
    console.log('Trip ID added to user successfully');
  } catch (error) {
    console.error('Error adding trip ID to user: ', error);
    throw error;
  }
}

// Fonction existante pour récupérer les détails de la ville
export async function fetchCityDetails(city, country, cityFrom) {
  console.log("Valeurs en input : ", city, country, cityFrom);
  try {
    const response = await fetch('https://europe-west1-alegotravel.cloudfunctions.net/api/cityInfos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ city, country, cityFrom }),
    });

    if (!response.ok) {
      console.error('Réponse non réussie:', response.status, response.statusText);
      throw new Error(`Erreur réseau ou côté serveur: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Erreur lors de la récupération des informations de la ville: ", error);
    throw error;
  }
}
