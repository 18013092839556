import { db } from '../firebase.js';
import { collection, addDoc } from 'firebase/firestore';

// Fonction pour enregistrer les informations de l'hôtel
async function saveHotelData(hotelData, docRef) {
  // Vérifier que l'hôtel a un nom et une latitude définis
  if (hotelData.name && hotelData.location?.latitude) {
    try {
      const newDocRef = await addDoc(collection(db, 'hotelInformations'), {
        ...hotelData,
        linkedDocumentId: docRef // Lien avec le document de référence
      });
      console.log(`Informations de l'hôtel enregistrées avec l'ID : ${newDocRef.id}`);
      return newDocRef.id; // Retourner l'ID du nouveau document
    } catch (error) {
      console.error("Erreur lors de l'enregistrement des informations de l'hôtel :", error);
      throw new Error("Failed to save hotel data");
    }
  } else {
    console.log("Les informations de l'hôtel sont incomplètes : Nom ou Latitude manquant(e).");
    throw new Error("Incomplete hotel data: Name or Latitude missing.");
  }
}

export { saveHotelData };
