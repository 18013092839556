<template>
  <SeparatorLine />

  <div class="profile-container">
    <nav class="sidebar">
      <router-link
        to="/profile/informations"
        class="sidebar-item"
        :class="{ active: isActive('/profile/informations') }"
        exact
      >
        <img
          :src="getIconSrc('/profile/informations', 'profil')"
          alt="Profile Icon"
          class="icon"
        />
        <span>Mon profil</span>
      </router-link>
      <router-link
        to="/profile/trips"
        class="sidebar-item"
        :class="{ active: isActive('/profile/trips') }"
        exact
      >
        <img
          :src="getIconSrc('/profile/trips', 'valise')"
          alt="Trips Icon"
          class="icon"
        />
        <span>Mes voyages</span>
      </router-link>
      <!--<router-link
        to="/profile/favorites"
        class="sidebar-item"
        :class="{ active: isActive('/profile/favorites') }"
        exact
      >
        <img
          :src="getIconSrc('/profile/favorites', 'favori')"
          alt="Favorites Icon"
          class="icon"
        />
        <span>Mes favoris</span>
      </router-link> -->
      <div class="sidebar-item" @click="logout">
        <img src="@/assets/img/profile/disconnect.png" alt="Logout Icon" class="icon" />
        <span>Déconnexion</span>
      </div>
    </nav>

    <div class="mobile-menu">
      <select v-model="selectedMenu" @change="navigateTo(selectedMenu)">
        <option value="/profile/informations">Mon profil</option>
        <option value="/profile/trips">Mes voyages</option>
        <!--<option value="/profile/favorites">Mes favoris</option>-->
      </select>
    </div>

    <div class="profile-content">
      <router-view v-if="user" :user="user"></router-view>
    </div>
  </div>
</template>

<script>
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import SeparatorLine from '@/components/SeparatorLine.vue';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/firebase';

export default {
  components: {
    SeparatorLine
  },
  name: 'ProfileContent',
  data() {
    return {
      user: null,
      selectedMenu: '/profile/informations'
    };
  },
  async created() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          this.user = {
            uid: user.uid,
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            email: userData.email || user.email,
            photoURL: userData.photoURL || user.photoURL
          };
        } else {
          this.user = {
            uid: user.uid,
            firstName: '',
            lastName: '',
            email: user.email,
            photoURL: user.photoURL
          };
          await setDoc(userRef, this.user); // Ajout d'un nouvel utilisateur à la base de données s'il n'existe pas
        }

        // Redirige vers /profile/informations si l'utilisateur n'est pas déjà sur une sous-page du profil
        if (this.$route.path === '/profile' || this.$route.path === '/profile/') {
          this.$router.replace('/profile/informations');
        }
      } else {
        this.user = null;
        this.$router.push('/auth');
      }
    });
  },
  methods: {
    async updateProfileData(newData) {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        try {
          const updatedData = {
            ...this.user, // Conserve les données existantes de l'utilisateur
            ...newData // Remplace les champs avec les nouvelles données
          };
          await setDoc(userRef, updatedData, { merge: true });
          // Mettre à jour l'état local de l'utilisateur
          this.user = updatedData;
        } catch (error) {
          console.error('Erreur lors de la mise à jour des données du profil:', error);
        }
      }
    },
    async logout() {
      const auth = getAuth();
      try {
        await signOut(auth);
        this.$router.push('/');
      } catch (error) {
        console.error('Erreur lors de la déconnexion:', error);
      }
    },
    isActive(route) {
      return this.$route.path === route;
    },
    getIconSrc(route, iconName) {
      return this.isActive(route)
        ? require(`@/assets/img/profile/${iconName}-jaune.png`)
        : require(`@/assets/img/profile/${iconName}.png`);
    },
    navigateTo(route) {
      this.$router.push(route);
    }
  }
};
</script>



<style scoped>
:root {
  --gris-clair: #f8f8f8;
  --vert: #4caf50;
  --dark-gray: #333;
  --jaune: #ffeb3b;
}

.profile-container {
  display: flex;
  min-height: 80vh;
  background-color: var(--light-gray);
  padding: 40px 60px 40px 40px;
  margin-bottom: -100px;
}

.sidebar {
  width: 20%;
  max-width: 350px;
  background-color: white;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px;
  gap: 30px;
  height: fit-content;
  position: sticky;
  top: 40px;
}

.sidebar-item {
  width: 90%;
  padding: 10px;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 1.2rem;
  color: var(--dark-gray);
  cursor: pointer;
}

.sidebar-item.active {
  color: var(--jaune);
  border-radius: 10px;
  font-size: 1.2rem;
  transition: transform 0.2s;
}

.profile-content {
  width: 70%;
  padding: 0;
  background-color: var(--gris-clair);
  margin-left: auto;
  margin-bottom: 100px;
}

.icon {
  opacity: 1;
}

.mobile-menu {
  display: none;
  width: 90%;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  margin: 0 auto 20px auto;
}

.mobile-menu select {
  width: 100%;
  padding: 0px;
  font-size: 0.8rem;
  font-weight: bold;
  color: var(--dark-gray);
  border: none;
  background-color: var(--gris-clair);
  border-radius: 5px;
  outline: none;
}


@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .profile-container {
    padding: 20px;
        display: flex;
    flex-direction: column;
  }
  .profile-content {
    width: 100%;
    padding: 0;
    background-color: var(--gris-clair);
    margin-left: auto;
    margin-bottom: 100px;
  }
}
</style>


