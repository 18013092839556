<template>
  <div class="privacy-policy">
    <div class="header">
      <img src="@/assets/img/logo.png" alt="logo">
    </div>
    <div class="container">
      <h1>Politique de confidentialité</h1>
      <p>Dernière mise à jour : 01/07/2024</p>

      <p>Cette politique de confidentialité décrit les pratiques de Alego en matière de confidentialité et la
        manière dont nous traitons les informations personnelles que nous recueillons par le biais de notre
        site Web situé à l'adresse https://alego.app et de toute autre application ou service que nous
        possédons ou contrôlons et qui renvoie à cette politique de confidentialité.</p>

      <p>La plateforme Alego a pour but de permettre à ses utilisateurs la création et planification de voyages.
        Elle s’appuie sur l’IA afin de proposer des séjours les plus personnalisés possible en fonction des
        centres d’intérêts de l’utilisateur. La plateforme Alego permet de choisir des modes de transport, des
        logements ainsi que des activités.</p>

      <h2>PRÉAMBULE</h2>
      <p>La présente politique de confidentialité a pour but d’informer les utilisateurs du site :</p>

      <ul>
        <li>Sur la manière dont sont collectées leurs données personnelles.</li>
        <li>Sur les droits dont ils disposent concernant ces données.</li>
        <li>Sur la personne responsable du traitement des données à caractère personnel collectées et
            traitées.</li>
        <li>Sur les destinataires de ces données personnelles.</li>
        <li>Sur la politique du site en matière de cookies.</li>
      </ul>

      <h2>PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES DONNÉES PERSONNELLES</h2>
      <p>Conformément à l’article 5 du Règlement européen 2016/679, les données à caractère personnel
        sont :</p>

      <ul>
        <li>Traitées de manière licite, loyale et transparente.</li>
        <li>Collectées pour des finalités déterminées.</li>
        <li>Adéquates, pertinentes et limitées à ce qui est nécessaire.</li>
        <li>Exactes et, si nécessaire, tenues à jour.</li>
        <li>Conservées sous une forme permettant l'identification des personnes concernées.</li>
        <li>Traitées de façon à garantir une sécurité appropriée.</li>
      </ul>

      <h2>DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES</h2>
      <h3>Données collectées</h3>
      <p>Les données personnelles collectées dans le cadre de notre activité sont les suivantes :</p>

      <ul>
        <li>adresse e-mail</li>
        <li>adresse IP anonymisée de l’appareil</li>
      </ul>

      <p>La collecte et le traitement de ces données répond aux finalités suivantes :</p>
      <ul>
        <li>Communication entre Alego et l’utilisateur</li>
        <li>Statistiques sur le nombre de visiteurs via Google Analytics 4</li>
      </ul>

      <p>Les adresses IP sont conservées pendant une durée de un an.</p>

      <h3>Hébergement des données</h3>
      <p>Le site https://alego.app hébergé par :</p>
      <p>FIREBASE HOSTING par GOOGLE, situé au Google LLC, 1600 Amphitheatre Parkway, Mountain View, California 94043 USA</p>

      <h2>RESPONSABLE DU TRAITEMENT DES DONNÉES</h2>
      <h3>Le responsable du traitement des données</h3>
      <p>Les données sont collectées par Alego, SAS au capital de 1000€, numéro 930 219 126.</p>

      <p>Pour exercer vos droits, veuillez nous contacter à contact@alego.app.</p>

      <h2>LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DES DONNÉES</h2>
      <p>Tout utilisateur concerné par le traitement de ses données personnelles peut se prévaloir des droits suivants :</p>

      <ul>
        <li>Droit d’accès, de rectification et droit à l’effacement des données</li>
        <li>Droit à la portabilité des données</li>
        <li>Droit à la limitation et à l’opposition du traitement des données</li>
        <li>Droit de ne pas faire l’objet d’une décision fondée exclusivement sur un procédé automatisé</li>
        <li>Droit de déterminer le sort des données après la mort</li>
        <li>Droit de saisir l’autorité de contrôle compétente</li>
      </ul>

      <h2>CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
      <p>L’éditeur du site Alego se réserve le droit de pouvoir modifier cette Politique à tout moment pour se conformer au droit en vigueur.</p>

      <h2>COMMENT NOUS CONTACTER</h2>
      <p>Si vous avez des questions sur cette politique, contactez-nous à contact@alego.app.</p>
    </div>
  </div>
</template>

<style scoped>
.privacy-policy {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: white;
  color: #333;
  position: absolute;
  top: 0;
  z-index: 1000000;
}
.header {
  background-color: #14444c;
  color: white;
  padding: 20px;
  text-align: center;
}
.header img {
  height: 150px;
  width: auto;
}
.container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}
h1 {
  font-size: 2.8em;
  margin-bottom: 40px;
  text-align: center;
}
h2 {
  font-size: 2em;
  margin-top: 60px;
  margin-bottom: 20px;
}
p, ul {
  font-size: 1em;
  margin-bottom: 20px;
}
ul {
  padding-left: 20px;
}
</style>
