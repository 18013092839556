<template>
  <div>
    <SeparatorLine />
    
    <div class="sumUpPage-container">

      <SumUp :docRef="tripId" :isFromProfile="true" />

    </div>

  </div>
</template>

<script>
import SumUp from '../components/SumUp.vue';
import SeparatorLine from '../components/SeparatorLine.vue';

export default {
  name: "ActivitiesPage",
  components: {
    SeparatorLine,
    SumUp,
  },
  props: ['tripId'],
  created() {
    console.log("Trip ID:", this.tripId); // Assurez-vous que tripId est bien reçu
  },
  methods: {
    // Methods for handling activities logic
  },
};
</script>

<style scoped>

.sumUpPage-container {
  width: 90%;
  margin: 40px auto;
}

</style>