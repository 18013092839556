<template>
  <div class="bloc-container">
    <!-- Liste des destinations -->
    <div class="destination-list-bloc" v-if="!isMobileView || (isMobileView && !showMapMobile)">
      <!-- Mobile Toggle Button -->
      <div v-if="isMobileView" class="button-container">
        <button class="back-button" @click="handleBackButtonClick">
          <img style="width: 20px; height: 20px;" src="@/assets/img/voyage/previous-white.png" alt="Previous">
        </button>
        <button  class="mobile-toggle-button" @click="toggleView">{{ showMapMobile ? 'Liste' : 'Carte' }}</button>
        <button class="reload-button" @click="reloadDestinations" :disabled="isReloading">
          <img v-if="!isReloading" style="width: 20px; height: 20px;" src="@/assets/img/voyage/reload.png" alt="Reload">
          <img v-else style="width: 20px; height: 20px;" src="https://media.tenor.com/wpSo-8CrXqUAAAAi/loading-loading-forever.gif" alt="Chargement...">
        </button>
      </div>
  
      <div class="destination-list-title">
        <h2><strong>{{ prenom }}</strong>, sélectionne une destination dans la liste ci-dessous :</h2>
      </div>
 
      <div v-if="isLoading" class="loading-container">
        <div class="loading-content">
          <img src="@/assets/loading-gif.gif" style="height: 150px;" alt="Chargement...">
        </div>
      </div>

<div v-else class="destination-list">
  <div
    v-for="(destination, index) in validDestinations"
    :key="index"
    :id="'destination-' + index"  
    class="destination-card"
  >
    <div class="title-container" @click="toggleParagraph(index)">
      <h3>{{ destination.city }} - {{ destination.country }}</h3>
      <button v-show="showParagraphs[index]" @click.stop="selectDestination(index)">Choisir</button>
      <span class="toggle-arrow">{{ showParagraphs[index] ? '▲' : '▼' }}</span>
    </div>
    <div v-show="showParagraphs[index]" class="destination-details">
      <div class="info-container">
        <div class="budget-info">
          <strong>Budget moyen : </strong> {{ destination.budget }} €
        </div>
        <div class="weather-info">
          <strong>Météo : </strong>
          {{ destination.weather.minTemp }}°C - {{ destination.weather.maxTemp }}°C
          <img :src="getWeatherIcon(destination.weather.condition)" :alt="destination.weather.condition" class="weather-icon">
        </div>
      </div>
      <p>{{ destination.description }}</p>
    </div>
  </div>
</div>



      <div v-if="!isLoading" class="destination-list-bottom">
        <button class="back-button bottom-button" @click="handleBackButtonClick">
          <img class="img-reload-and-back" src="@/assets/img/voyage/previous-white.png" alt="Previous">
        </button>
        <button class="reload-button bottom-button" @click="reloadDestinations" :disabled="isReloading">
          <img v-if="!isReloading" style="width: 20px; height: 20px;margin-bottom: 10px;" src="@/assets/img/voyage/reload.png" alt="Reload">
          <img v-else class="img-reload-and-back"  src="https://media.tenor.com/wpSo-8CrXqUAAAAi/loading-loading-forever.gif" alt="Chargement...">
        </button>
      </div>

    </div>

    <!-- Carte pour ordinateur -->
    <div class="map-bloc desktop-map" ref="desktopMapContainer" v-if="!isMobileView"></div>

    <!-- Carte pour mobile -->
    <div class="map-bloc mobile-map" ref="mobileMapContainer" v-show="isMobileView && showMapMobile">
      <div class="button-container-map">
        <button v-if="isMobileView" class="mobile-toggle-button-map" @click="toggleView">{{ showMapMobile ? 'Liste' : 'Carte' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { toRaw } from 'vue';

export default {
  props: ['prenom', 'destinations', 'isLoading'],
  data() {
    return {
      showParagraphs: [],
      map: null,
      showMapMobile: false,
      isMobileView: window.innerWidth <= 768,
      customIcon: L.icon({
        iconUrl: require('@/assets/img/voyage/location-pin.png'),
        iconSize: [45, 50],
        iconAnchor: [22.5, 50],
        popupAnchor: [0, -50] // Ajustement du popupAnchor
      }),
      selectedDestinationIndex: 0,
      selectedDestination: null,
      isReloading: false,  
    };
  },
  computed: {
    validDestinations() {
      return this.destinations.filter(dest => this.isValidCoordinate(dest.latitude, dest.longitude));
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.destinations.length > 0) {
        this.initShowParagraphs();
        this.initMap();
      } else {
        const unwatch = this.$watch(
          () => this.destinations,
          (newVal) => {
            if (newVal && newVal.length > 0) {
              this.initShowParagraphs();
              this.initMap();
              unwatch();
            }
          },
          { immediate: true }
        );
      }
      window.vueInstance = this;
      window.addEventListener('resize', this.handleResize);
    });
  },
  methods: {
    getWeatherIcon(condition) {
      const weatherIcons = {
        'soleil': require('@/assets/img/landing/sun.png'),
        'soleil-nuage': require('@/assets/img/landing/sun-cloud.png'),
        'nuages': require('@/assets/img/landing/cloud.png'),
        'pluie': require('@/assets/img/landing/rain.png'),
        'fortes-pluies': require('@/assets/img/landing/heavy-rain.png')
      };
      return weatherIcons[condition] || require('@/assets/img/landing/sun-cloud.png');
    },

    isValidCoordinate(lat, lon) {
      const isLatitude = lat => isFinite(lat) && Math.abs(lat) <= 90;
      const isLongitude = lon => isFinite(lon) && Math.abs(lon) <= 180;
      return isLatitude(lat) && isLongitude(lon);
    },
    initShowParagraphs() {
      this.showParagraphs = this.validDestinations.map((_, index) => index === 0);
    },
    toggleParagraph(index) {
      this.showParagraphs = this.showParagraphs.map((_, i) => i === index ? !this.showParagraphs[i] : false);
      this.selectedDestinationIndex = index;

      // Défilement à l'intérieur de `.destination-list`
      const destinationElement = document.getElementById(`destination-${index}`);
      const destinationListContainer = document.querySelector('.destination-list');

      if (destinationElement && destinationListContainer) {
        // Calculer la position de l'élément à l'intérieur de son conteneur parent
        const destinationOffsetTop = destinationElement.offsetTop;
        const containerScrollTop = destinationListContainer.scrollTop;
        const containerHeight = destinationListContainer.clientHeight;

        // Vérifier si l'élément est en dehors de la vue visible du conteneur
        if (destinationOffsetTop < containerScrollTop || destinationOffsetTop > (containerScrollTop + containerHeight)) {
          destinationElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }

      const destination = this.validDestinations[index];
      if (destination && this.map) {
        const rawMap = toRaw(this.map);
        rawMap.flyTo([destination.latitude, destination.longitude], 7);
        rawMap.closePopup();
        rawMap.openPopup(this.createPopupContent(destination, index), [destination.latitude, destination.longitude]);
      }
    },
    initMap() {
      this.$nextTick(() => {
        if (!this.map) {
          if (this.isMobileView) {
            this.initMobileMap();
          } else {
            this.initDesktopMap();
          }
        }
      });
    },
    initDesktopMap() {
      if (this.$refs.desktopMapContainer && !this.map && this.validDestinations.length > 0) {
        const initialDest = this.validDestinations[0];
        if (this.isValidCoordinate(initialDest.latitude, initialDest.longitude)) {
          this.map = L.map(this.$refs.desktopMapContainer, {
            center: [initialDest.latitude, initialDest.longitude],
            zoom: 7,
            tap: false // Recommended for mobile devices to prevent 300ms click delay
          });
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap'
          }).addTo(this.map);
          this.updateMapMarkers();
        }
      }
    },
    initMobileMap() {
      if (this.$refs.mobileMapContainer && !this.map && this.validDestinations.length > 0) {
        const initialDest = this.validDestinations[0];
        if (this.isValidCoordinate(initialDest.latitude, initialDest.longitude)) {
          this.map = L.map(this.$refs.mobileMapContainer, {
            center: [initialDest.latitude, initialDest.longitude],
            zoom: 10,
            tap: false // Recommended for mobile devices to prevent 300ms click delay
          });
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap'
          }).addTo(this.map);
          this.updateMapMarkers();
        }
      }
    },
    updateMapMarkers() {
      const rawMap = toRaw(this.map);
      if (rawMap && rawMap._loaded) {
        rawMap.eachLayer(layer => {
          if (layer instanceof L.Marker) {
            rawMap.removeLayer(layer);
          }
        });
        this.validDestinations.forEach((destination, index) => {
          if (this.isValidCoordinate(destination.latitude, destination.longitude)) {
            L.marker([destination.latitude, destination.longitude], { icon: this.customIcon })
              .addTo(rawMap)
              .bindPopup(this.createPopupContent(destination, index))
              .on('click', () => this.toggleParagraph(index));
          }
        });
      }
    },
    createPopupContent(destination) {
      return `<div class='custom-popup'>
                <b>${destination.city}, ${destination.country}</b>
                <br/>
              </div>`;
    },
    selectDestination(index) {
      const selected = this.validDestinations[index];
      this.selectedDestination = selected;
      this.$emit('setCityTo', {
        cityTo: selected.city,
        longitudeTo: selected.longitude,
        latitudeTo: selected.latitude
      });
    },
    handleBackButtonClick() {
      this.resetMapAndDestinations();
      this.updateStep('selectBudgetAndDeparture');
    },
    resetMapAndDestinations() {
      this.$emit('updateDestinations', []);
      this.showParagraphs = [];
      if (this.map) {
        const rawMap = toRaw(this.map);
        rawMap.eachLayer(layer => {
          if (layer instanceof L.Marker) {
            rawMap.removeLayer(layer);
          }
        });
        this.map.off();
        this.map.remove();
        this.map = null;
      }
    },
    async reloadDestinations() {
      this.isReloading = true;  // Activer l'état de rechargement
      console.log('Rechargement commencé');
      try {
        // Simuler un délai de 2 secondes pour observer le changement
        await new Promise(resolve => setTimeout(resolve, 2000));
        await this.$emit('reloadDestinations');  // Recharger les destinations
      } finally {
        console.log('Rechargement terminé');
        this.isReloading = false;  // Désactiver l'état de rechargement
      }
    },

    updateStep(step) {
      this.$emit('updateStep', step);
    },
    toggleView() {
      this.showMapMobile = !this.showMapMobile;
      this.$nextTick(() => {
        if (this.map) {
          setTimeout(() => {
            this.map.invalidateSize();
          }, 100);
        }
      });
    },
    handleResize() {
      this.isMobileView = window.innerWidth <= 768;
      if (this.map) {
        setTimeout(() => {
          this.map.invalidateSize(); // Force la carte à redimensionner
        }, 100); // Utilisez un léger délai pour assurer que le DOM est prêt
      }
    }
  },
  watch: {
    destinations(newVal) {
      if (newVal.length > 0 && this.map) {
        this.updateMapMarkers();
      }
    },
    showMapMobile(newVal) {
      if (newVal && this.map) {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.map) {
              this.map.invalidateSize();
            }
          }, 100);
        });
      }
    }
  },
  unmounted() {
    if (this.map) {
      const rawMap = toRaw(this.map);
      rawMap.off();
      rawMap.remove();
      this.map = null;
    }
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>

.img-reload-and-back {
  width: 20px; 
  height: 20px; 
  margin-bottom: 10px;
}

.destination-list {
  overflow-y: scroll;
  max-height: calc(100% - 40px);
  padding-bottom: 0px;
  scrollbar-width: thin;
  scrollbar-color: var(--vert) #f1f1f1;
  background-color: rgb(227, 226, 226);
}

.destination-list::-webkit-scrollbar {
  width: 8px;
}

.destination-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.destination-list::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.leaflet-popup-content-wrapper {
  background: #fff; /* Background color of the popup */
  border-radius: 8px; /* Rounded corners for the popup */
  box-shadow: 0 3px 14px rgba(0,0,0,0.4); /* Shadow for some depth */
}

.leaflet-popup-content {
  margin: 8px; /* Adds some padding inside the popup */
  color: var(--vert); /* Text color */
  font-size: 16px; /* Adjust text size */
  line-height: 1.4; /* Spacing between lines */
}

.leaflet-popup-tip-container {
  display: none; /* Hide the tip arrow */
}

.custom-popup button {
  background-color: var(--jaune); /* Button background color */
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.custom-popup button:hover {
  background-color: var(--marron); /* Darken button on hover */
}

.destination-list-bloc {
  position: relative;
  background-color: transparent; /* Removed background color */
  border: none; /* Removed border */
  flex: 1;
  height: 100%; 
  padding-bottom: 80px;
  overflow: hidden; 
  background-color: white;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.back-button,
.reload-button {
  background-color: var(--vert);
  color: white;
  border: none;
  border-radius: 100px;
  padding: 12px 14px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
}

.back-button:hover,
.reload-button:hover {
  background-color: var(--jaune);
}

/* Bouton sticky pour mobile */
.mobile-toggle-button {
  display: none;
  background-color: var(--vert);
  color: white;
  border: none;
  border-radius: 100px;
  padding: 12px 20px;
  cursor: pointer;
}

.mobile-toggle-button:hover {
  background-color: var(--jaune);
}

.loading-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90%;
  display: flex; /* Utilisation de flexbox pour le centrage */
  justify-content: center; /* Centrer horizontalement */
  align-items: center; /* Centrer verticalement */
}

.loading-content {
  text-align: center; /* Centrer le contenu à l'intérieur du loading-container */
}

/* Map bloc */

.map-bloc {
  flex: 1;
  width: 55%;
  background-color: white;
  height: 100%;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Ajout d'une ombre pour un effet de relief */
}

.map-bloc.mobile-map {
  width: 100%;
  height: 100%; /* Full height for mobile view */
  border-radius: 0; /* No border radius */
}

.map-bloc.desktop-map {
  height: 100%; /* Full height for desktop view */
}

.destination-list-title {
  background-color: var(--vert);
  padding: 10px 25px;
  height: 40px;
  display: flex;
  align-items: center;
}

.destination-list-bottom {
  background-color: var(--vert);
  padding: 10px 25px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.destination-list-bloc h2 {
  font-size: 1rem; /* Reduced font size */
  font-weight: 500;
  color: white;
}

.destination-list-bloc strong {
  color: var(--jaune);
}

.map-bloc, .destination-list-bloc {
  box-sizing: border-box; 
}

.destination-card {
  padding: 5px 10px;
  background-color: transparent; /* Removed background color */
  border-bottom: 1px solid lightgray;
}

.destination-card p {
  color: rgb(84, 84, 84);
  padding: 0px 10px 10px 10px;
  font-size: 0.8rem; /* Reduced font size */
}

.destination-card h3 {
  margin-left: 30px;
  font-size: 0.8rem; /* Reduced font size */
}

.title-container {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  cursor: pointer; 
  width: 100%; 
}

.toggle-arrow {
  user-select: none; 
  font-size: 0.8rem; /* Reduced font size */
  margin-top: 10px;
  margin-right: 30px;
  color: var(--vert);
}

/* Fin map bloc */

/* Responsive styles */
@media (max-width: 768px) {
  .map-bloc.desktop-map {
    display: none; /* Hide desktop map on mobile view */
  }

  .map-bloc.mobile-map {
    display: block; /* Ensure map is visible when toggled */
    width: 100%;
    height: 80vh; /* Full height for mobile view */
    border-radius: 0; /* No border radius */
    margin-left: 0;
    margin-top: 20px;
  }

  .destination-list-bloc {
    width: 0%; /* Full width for list on mobile view */
    border-radius: 0px; 
    box-shadow: 0 0px 0px rgba(0,0,0,0.1); /* Ajout d'une ombre pour un effet de relief */
    position: relative;
    background-color: var(--light-grey);
    min-height: 70vh;
  }

  .destination-list {
    background-color: var(--light-grey);
    padding: 0;
    width: 95%;
    margin: auto;
  }

  .destination-list-title {
    padding: 25px 10px 0 10px; /* Reduced padding */
    background-color: var(--light-grey);
    color: black;
  }

  .destination-list-bloc h2 {
    font-size: 0.9rem; /* Further reduced font size */
    color: var(--vert);
    width: 90%;
    margin: auto;
    text-align: justify;
  }

  .destination-card {
    padding: 3px 5px; /* Reduced padding */
  }

  .destination-card p {
    font-size: 0.7rem; /* Further reduced font size */
    margin: 0;
  }

  .destination-card h3 {
    margin-left: 15px; /* Reduced margin */
    font-size: 0.7rem; /* Further reduced font size */
    padding: 0
  }

  .toggle-arrow {
    font-size: 0.7rem; /* Further reduced font size */
    margin-right: 15px; /* Reduced margin */
  }
  .custom-popup {
    display: flex;
    justify-content: center;
  }
  .custom-popup button {
    padding: 3px 5px; /* Reduced padding */
    font-size: 0.7rem; /* Reduced font size */
  }

  .button-container {
    position: relative;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0 20px;
  }

  .back-button,
  .reload-button {
    flex: 1;
    max-width: 45px; /* Ensure the buttons do not become too large */
  }
  .button-container-map {
    position: relative;
  }

  .mobile-toggle-button-map {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    padding: 10px 15px;
  }

  /* Bouton sticky pour mobile */
  .mobile-toggle-button {
    display: block;
    text-align: center; /* Center the text */
    z-index: 1000; /* Ensure the button stays above other elements */
  }

  .mobile-toggle-button:hover {
    background-color: var(--jaune);
  }
  .custom-popup {
    transform: translateX(-50%); /* Décalage vers la gauche de 50% */
  }
  .title-container {
    padding: 10px 0;
  }
  .title-container button {
    padding: 10px;
    font-size: 0.8rem;
    margin-top: 0;
  }
  .loading-container {
    top: 200px;
    background-color: white;
    border-radius: 25px;
    border: 1px solid grey;
    width: 90%;
    height: 40vh;
    left: 50%;
    transform: translateX(-50%);
  }

  .info-container {
    flex-direction: column;
  }

  .bottom-button {
    margin-top: 5px !important;
  }
  
  .destination-list-bottom {
    border-radius: 30px;
    margin-top: 40px;
  }
}

.destination-details {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.info-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px; /* Adds space below the info line */
}

.budget-info,
.weather-info {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.budget-info strong,
.weather-info strong {
  margin-right: 5px; /* Add space between the label and the value */
}

.weather-info {
  display: flex;
  align-items: center;
}

.weather-icon {
  width: 20px;
  height: 20px;
  margin-left: 5px; /* Add space between the temperature and the icon */
}

.destination-card p {
  color: rgb(84, 84, 84);
  padding: 10px 10px 10px 10px;
  font-size: 0.8rem; /* Reduced font size */
  text-align: justify;
}

.destination-card h3 {
  margin-left: 30px;
  font-size: 0.8rem; /* Reduced font size */
}

.title-container button {
  margin-top: 0;
}

</style>
