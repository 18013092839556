import { getFirestore, doc, getDoc, collection, getDocs, query, where } from "firebase/firestore";

const db = getFirestore();

export async function getUserTrips(userId) {
  const userRef = doc(db, "users", userId);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    throw new Error("User not found");
  }

  // Récupérer tripIds avec leurs dateAdded
  const tripIdsWithDates = userDoc.data().tripIds.map(trip => ({
    id: trip.tripId,
    dateAdded: trip.dateAdded,
  }));
  console.log('Trip IDs with Dates:', tripIdsWithDates);

  if (tripIdsWithDates.length === 0) {
    return [];
  }

  // Trier par date pour s'assurer que l'ordre est correct
  tripIdsWithDates.sort((a, b) => b.dateAdded - a.dateAdded);

  const allTrips = [];
  
  // Diviser les tripIds en lots de 30
  for (let i = 0; i < tripIdsWithDates.length; i += 30) {
    const batch = tripIdsWithDates.slice(i, i + 30); // Prendre des lots de 30 trips
    const tripIds = batch.map(trip => trip.id);
    
    // Créer une requête pour ce lot de 30 tripIds
    const tripsQuery = query(collection(db, "tripdatas"), where("__name__", "in", tripIds));
    const tripDocs = await getDocs(tripsQuery);
    
    // Ajouter les voyages récupérés à la liste complète des voyages
    tripDocs.forEach(doc => {
      const data = doc.data();
      const tripIdWithDate = tripIdsWithDates.find(trip => trip.id === doc.id);
      allTrips.push({
        id: doc.id,
        ...data,
        dateAdded: tripIdWithDate.dateAdded,
      });
    });
  }

  // Trier tous les voyages par dateAdded (ordre décroissant)
  allTrips.sort((a, b) => b.dateAdded - a.dateAdded);
  console.log('Sorted Trips:', allTrips);

  return allTrips;
}
