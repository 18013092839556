<template>
  <header :class="headerClasses">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/img/logo_beta.png" alt="Alego">
      </router-link>
    </div>

    <nav class="menu-centre" :class="{ 'menu-open': menuOpen }">
      <ul>
        <li class="menu-link" style="z-index: 100004" @click="closeMenu">
          <router-link to="/voyage">Créer mon voyage</router-link>
        </li>

        <li class="menu-link" style="z-index: 100004" @click="closeMenu">
          <router-link to="/about">A propos d'Alego</router-link>
        </li>
        
        <li style="z-index: 100004" class="menu-link mobile-only" @click="openFeedbackOverlay()">
          <a href="#">Faire un retour</a>
        </li>

        <li v-if="isLoggedIn" style="z-index: 100004" class="menu-link mobile-only" @click="logout">
          <a href="#">Déconnexion</a>
        </li>
      </ul>
    </nav>

    <div class="menu-separateur"></div>

    <nav class="menu-droite">
      <ul>
        <li class="menu-link connexion">
          <a href="#" @click.prevent="handleProfileClick">
            <img src="@/assets/img/profil.png" alt="profil">
          </a>
        </li>
      </ul>
    </nav>

    <!-- Afficher UserAuth en fonction de l'état de connexion -->
    <UserAuth v-if="showAuthForm" @close="closeAuthForm"/>

    <!-- Menu hamburger -->
    <div class="hamburger-menu" @click="toggleMenu">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </header>

  <div v-if="isScrolled" class="separator"></div>
  <OverlayFeedback :isVisible="showOverlay" @close="showOverlay = false" />
</template>


<script>
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import UserAuth from '@/views/UserAuth.vue';
import OverlayFeedback from "@/components/ContactOverlay.vue";

export default {
  name: 'SiteHeaderLanding',
  components: {
    UserAuth,
    OverlayFeedback
  },
  props: {
    isScrolled: {
      type: Boolean,
      default: false
    },
    isScrolledStart: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoggedIn: false,
      auth: null,
      showAuthForm: false,
      menuOpen: false,
      showOverlay: false,
    };
  },
  computed: {
    headerClasses() {
      return {
        'scrolled': this.isScrolled,
        'scrolled-start': this.isScrolledStart
      };
    }
  },
  created() {
    this.auth = getAuth();
    onAuthStateChanged(this.auth, user => {
      this.isLoggedIn = !!user;
      if (user) {
        this.showAuthForm = false;
      }
    });
  },
  methods: {
    openFeedbackOverlay() {
      if (window.innerWidth <= 768) { // Vous pouvez ajuster cette valeur selon la largeur que vous considérez comme mobile
        this.showOverlay = true;
      }
    },
    handleProfileClick() {
      if (this.isLoggedIn) {
        this.$router.push('/profile'); // Redirige vers la page de profil
      } else {
        this.showAuthForm = true;
      }
    },
    closeAuthForm() {
      this.showAuthForm = false;
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    async logout() {
      const auth = getAuth();
      try {
        await signOut(auth);
        this.$router.push('/');
        this.closeMenu();  // Fermer le menu après déconnexion
      } catch (error) {
        console.error('Erreur lors de la déconnexion:', error);
      }
    }
  }
};
</script>


<style scoped>
/* HEADER */
header {
  height: 12vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 5vh; /* 5% de marge à gauche et à droite */
  background-image: none;
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

header.scrolled {
  background-image: linear-gradient(to left, var(--jaune), var(--vert) 80%);
  background-size: 100% 100%; /* Ajuster la taille du dégradé */
}

header.scrolled-start {
  background-color: rgba(0, 0, 0, 0.2); /* Fond noir légèrement opaque */
}

header.scrolled::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--jaune);
}

.logo {
  cursor: pointer;
  margin-left: 5vh;
  margin-top: 2vh;
}

.logo img {
  max-height: 10vh;
  cursor: pointer; 
}

.menu-centre {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.menu-centre ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 40px;
}

.menu-link a, .menu-link a:visited {
  color: white;
  font-weight: 500;
  margin: 0 15px;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  display: block;
  letter-spacing: 1px;
  transition: color 0.3s; /* Transition pour l'effet au survol */
}

.menu-link a:hover {
  color: var(--jaune);
  transition: 0.5s;
}

.menu-droite {
  display: flex;
  align-items: center;
}

.menu-droite ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.menu-droite .connexion {
  margin-right: 10vh;
}

.menu-droite .connexion img {
  width: 40px;
  height: 40px;
}

/* Styles pour le menu hamburger */
.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 20; /* Plus élevé que le header */
}

.hamburger-menu span {
  height: 4px;
  width: 30px;
  background-color: white;
  margin: 3px;
  transition: all 0.3s;
  border-radius: 20px;
}

.mobile-only {
  display: none;
}

/* Styles responsive */
@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  header.scrolled-start {
    background-image: linear-gradient(to left, var(--jaune), var(--vert) 80%);
    background-size: 100% 100%; /* Ajuster la taille du dégradé */
  }

  header.scrolled-start::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--jaune);
  }

  header {
    z-index: 100000;
    width: 100vw;
    padding: 0;
  }
  .logo {
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .menu-centre {
    position: absolute;
    top: 12vh;
    left: 0;
    right: 0;
    background-color: var(--vert);
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    z-index: 15; /* Plus élevé que d'autres éléments, mais moins que le menu hamburger */
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 8px 12px rgba(0, 0, 0, 0.15); /* Ajout de l'ombre */
    opacity: 0.9;
  }

  .menu-centre::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--jaune); /* Couleur orange */
  }
  

  .menu-centre.menu-open {
    max-height: 200px; /* Ajuster en fonction de la hauteur nécessaire */
  }

  .menu-centre ul {
    flex-direction: column;
    gap: 10px;
    justify-content: space-around;
  }

  .menu-link a {
    margin: 10px 0;
    font-size: 0.8rem;
  }

  .menu-droite .connexion {
    margin-right: 0vh;
  }
  .hamburger-menu {
    display: flex;
    margin-right: 35px;
  }

  .logo img {
    margin-top: 10px;
  }

  .menu-link img {
    margin-top: 10px;
  }
}
</style>
