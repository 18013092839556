<template>
<div class="back-arrow" @click="updateCurrentStep('selectDates')">
      <img src="@/assets/img/voyage/previous.png" alt="Retour"> 
    </div>
  <div class="budget-and-departure">

    <h2>Pour ton voyage</h2>
    <div class="input-group">
      <label style="margin-top: -15px;">De quelle ville souhaites-tu partir ?</label>
      <input type="text" v-model="cityFrom" ref="autocompleteInput" placeholder="Départ" class="input-field departure" @keyup.enter="submit">
      
      <label style="margin-top: 20px">De quel budget disposes-tu par personne pour le transport et l'hébergement ?</label>
      <div class="budget-input-container">
        <input type="number" v-model="budget" placeholder="Budget" class="input-field">
        <span class="euro-symbol">€</span>
      </div>

      <button @click="submit" class="button">Valider</button>
    </div>

  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup(props, { emit }) {
    const cityFrom = ref('');
    const budget = ref('');
    const longitudeFrom = ref('');
    const latitudeFrom = ref('');
    const autocompleteInput = ref(null);

    onMounted(() => {
      loadGoogleMapsAPI();
    });

    

    function loadGoogleMapsAPI() {
      const apiKey = process.env.VUE_APP_GOOGLE_MAP_API_KEY;// Assurez-vous de remplacer par votre clé API réelle
      const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initAutocomplete`;
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }

    window.initAutocomplete = () => {
      const input = autocompleteInput.value;
      if (input) {
        // eslint-disable-next-line
        const autocomplete = new google.maps.places.Autocomplete(input, { types: ['(cities)'] });
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            cityFrom.value = place.name;
            longitudeFrom.value = place.geometry.location.lng();
            latitudeFrom.value = place.geometry.location.lat();
          } else {
            console.error("Aucune coordonnée trouvée pour :", place.name);
            alert("Aucune coordonnée trouvée pour ce lieu.");
          }
        });
      } else {
        console.error("L'élément d'autocomplétion n'est pas un input HTML valide.");
      }
    };
    function updateCurrentStep(step) {
      emit('updateStep', step);
    }
    function submit() {
      if (cityFrom.value && budget.value !== null && budget.value !== '') {
        emit('budget-and-departure-submitted', {
          cityFrom: cityFrom.value,
          budget: budget.value,
          longitudeFrom: longitudeFrom.value,
          latitudeFrom: latitudeFrom.value
        });
      } else {
        console.error('Veuillez remplir tous les champs.');
        alert('Veuillez remplir tous les champs.');
      }
    }

    return {
      cityFrom,
      budget,
      longitudeFrom,
      latitudeFrom,
      autocompleteInput,
      submit,
      updateCurrentStep
    }
  }
};
</script>

<style scoped>
.budget-and-departure {
  margin-top: 30px;
}

.budget-and-departure h2 {
  color: var(--vert);
  font-size: 25px;
  text-align: center;
  margin-top: -15px;
}

.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
}

.input-field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: var(--vert);
  outline: none;
}

.autocomplete-input {
  flex: 1; /* Pour que l'input occupe tout l'espace disponible */
}

.button {
  padding: 12px 24px;
  background-color: var(--vert);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: var(--jaune);
}

.budget-input-container {
  position: relative;
}
.budget-input-container .input-field {
  width: calc(100% - 25px); /* Adjust width to accommodate euro symbol */
  padding-right: 25px; /* Add padding to the right to make space for the euro symbol */
}

.euro-symbol {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa; /* Grisé */
  font-size: 16px;
}

.departure {
  width: 60%;
}

@media (max-width: 768px) {

  .budget-and-departure {
    width: 90%;
    margin: auto;
  }

  .button {
    width: 40%;
    margin-top: 50px;
  }
}

</style>