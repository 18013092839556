<template>
  <div class="bloc">
    <h2>Quelques informations sur toi !</h2>
    <div class="informations-container">
      <form @submit.prevent="soumettreInformations">
        <div class="input-group" v-if="etape >= 0">
          <label for="prenom">Dis moi ton prénom :</label>
          <input type="text" id="prenom" v-model="prenomLocal" @input="verifierPrenom" required>
        </div>

        <div class="input-group" v-if="etape >= 1">
          <label for="age">Quel âge as-tu ?</label>
          <div class="input-with-addon">
            <input type="number" id="age" v-model.number="age" @input="verifierAge" min="1" required>
            <span class="addon"> (ans)</span>
          </div>
        </div>

        <div class="input-group" v-if="etape >= 2">
          <label for="nombrePersonnes">Combien serez-vous à voyager ?</label>
          <input type="number" id="nombrePersonnes" v-model="nombrePersonnes" @input="verifierNombrePersonnes" min="1" required>
        </div>

        <div class="button" v-if="etape >= 3">
          <button type="submit">Continuer</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prenom: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      prenomLocal: this.prenom, // Utiliser la prop comme valeur initiale
      age: null,
      nombrePersonnes: null,
      etape: 0,
    };
  },
  watch: {
    prenom(newVal) {
      this.prenomLocal = newVal;
      if (newVal) {
        this.etape = Math.max(this.etape, 1); // Mettre à jour l'étape si le prénom est renseigné
      }
    }
  },
  mounted() {
    if (this.prenom) {
      this.etape = 1; // Mettre à jour l'étape si le prénom est renseigné au montage du composant
    }
  },
  methods: {
    verifierPrenom() {
      if (this.prenomLocal) {
        this.etape = Math.max(this.etape, 1);
      }
    },
    verifierAge() {
      if (this.age) {
        this.etape = Math.max(this.etape, 2);
      }
    },
    verifierNombrePersonnes() {
      if (this.nombrePersonnes) {
        this.etape = Math.max(this.etape, 3);
      }
    },
    soumettreInformations() {
      this.$emit('informations-soumises', {
        prenom: this.prenomLocal,
        age: this.age,
        nombrePersonnes: this.nombrePersonnes
      });
    }
  }
}
</script>

<style>

.bloc h2 {  
    font-size: 1.5rem;
    text-align: center;
    color: var(--vert);
}

.informations-container {
    width: 75%;
    padding: 10px 30px 0 10px;
    margin: auto;
}

.input-group {
    margin-bottom: 10px;
    display: flex;
    width: 70%;
    flex-direction: column;
}

.input-group input {
    display: block;
    width: 100%;
    padding: 8px;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.8rem;
    font-family: 'Arial Narrow Bold', sans-serif;
    letter-spacing: 1.2px;
}

.input-with-addon {
    position: relative;
}

.input-with-addon .addon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    font-size: 15px;
}

.button {
    display: flex;
    justify-content: center;
}

button {
    background-color: var(--vert); /* Couleur verte */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
}

button:hover {
    background-color: var(--jaune);
}

.bloc h2 {
    font-size: 1.5rem;
    text-align: center;
    color: var(--vert);
    font-weight: bold;
}

.input-group label {
    font-size: 1rem;
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

button {
    background-color: var(--jaune);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
}

button:hover {
    background-color: darken(var(--jaune), 10%);
}

/* Media queries pour les petits écrans */
@media (max-width: 768px) {
    .bloc h2 {
        font-size: 1.2rem !important;
        text-align: center;
        color: var(--vert);
        font-weight: bold;
        margin: 30px 0;
    }

    .bloc {
      width: 90%;
      margin: auto !important;
    }
    .informations-container {
        width: 90% !important;
        padding: 0;
        margin: auto;
        min-height: 30vh;
    }

    .input-group {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .input-group label {
        font-size: 0.9rem;
        margin-bottom: 5px;
        width: 100%;
        text-align: left !important;
    }

    .input-group input {
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #ccc;
        font-size: 1rem;
        box-sizing: border-box; /* Assurez-vous que le padding est inclus dans la largeur */
    }

    .button {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        width: 100%; /* S'assure que le bouton prend toute la largeur */
    }

    button {
        background-color: var(--jaune);
        color: white;
        padding: 12px 24px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        width: auto; /* Ajuste la largeur du bouton automatiquement */
    }

    button:hover {
        background-color: darken(var(--jaune), 10%);
    }
}



</style>
