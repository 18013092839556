<template>

    <div class="bloc">

        <div class="back-arrow" @click="updateCurrentStep('selectWishes')">
          <img src="@/assets/img/voyage/previous.png" alt="Retour"> 
        </div>

        <h2><strong>{{ prenom }}</strong>, tes envies de voyage sont uniques !</h2>
        <p>Que souhaites-tu découvrir, faire pendant ton voyage ?</p>
        <div class="wrap-preferences">
            <div v-for="(preference, index) in preferences" :key="index" class="input-group-pref">
                <input type="text" v-model="preferences[index]" placeholder="Ajoute une préférence">
                <button @click="removePreference(index)" v-if="preferences.length > 1">-</button>
            </div>
        </div>      
        <div class="buttons">
            <button @click="addPreference">+</button>
            <button @click="submitPreferences">Valider</button>
        </div>
          
    </div>  

</template>


<script>
export default {
  props: ['prenom'],
  data() {
    return {
      preferences: ['']
    };
  },
  methods: {
    addPreference() {
      this.preferences.push('');
    },
    removePreference(index) {
      if (this.preferences.length > 1) {
        this.preferences.splice(index, 1);
      }
    },
    submitPreferences() {
      this.$emit('preferences-submitted', { preferences: this.preferences });
    },
    updateCurrentStep(step) {
      this.$emit('updateStep', step);
    },
  }
}
</script>



<style>

.bloc h2 {  
    font-size: 1.5rem;
    text-align: center;
    color: var(--vert);
}

.bloc strong {
    color: var(--jaune);
}
.bloc p {
    text-align: center;
}
.input-group-pref {
    margin-bottom: 5px;
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center; /* Assure que les enfants sont centrés verticalement */
}

.input-group-pref input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
}

.input-group-pref button {
    width: 30px;
    height: 30px;
    cursor: pointer; /* Style pour rendre le curseur comme un pointeur */
    background-color: var(--vert);
    color: white;
    border: none;
    border-radius: 40%; 
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}

.buttons button {
    padding: 10px 15px;
    background-color: var(--vert);
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 700;
}

button:hover {
    background-color: var(--jaune);
}

.wrap-preferences {
    overflow-y: scroll;
    max-height: 30vh;
    margin-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: var(--vert) #f1f1f1;
    padding: 10px;
}

.wrap-preferences::-webkit-scrollbar {
    width: 8px;
}

.wrap-preferences::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.wrap-preferences::-webkit-scrollbar-thumb {
    background-color: var(--vert); 
    border-radius: 10px;
    border: 3px solid #f1f1f1;
}

.buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    width: 80%;
    margin-left: 10%; /* Centré par rapport à la largeur du conteneur principal */
}

.input-group-pref button {
  margin-bottom: 10px;
  margin-left: 20px
}

/* Media queries pour les petits écrans */
@media (max-width: 768px) {

  .buttons button {
    padding: 10px 15px;
    background-color: var(--vert);
    color: white;
    border: none;
    border-radius: 35px;

  } 
  .input-group-pref {
    margin: auto;
  }
  .input-group-pref button {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 20px
  }

  .wrap-preferences {
    max-height: 100vh;
  }

}

</style>
