<template>
  <div class="profile-container">
    <div class="section">
      <div class="section-header">
        <img style="opacity: 0.7" src="@/assets/img/profile/information.png" alt="Informations personnelles" class="section-icon"/>
        <h2>Informations personnelles</h2>
      </div>
      <p>Vous pouvez mettre à jour vos informations personnelles dans cette rubrique.</p>
      <div class="info-grid" v-if="user">
        <div class="info-item">
          <label>Prénom et NOM</label>
          <div class="info-item-names">
            <div class="buttons-name-position" v-if="editingName">
              <button class="buttons-name" @click="changeName">Valider</button>
              <button class="buttons-name" @click="editingNameFct(false)">Annuler</button>
            </div>
            <button class="modifyButton" v-else @click="editingNameFct(true)" >Modifier</button>
            <div v-if="editingName">
              <input v-model="editedFirstName" :placeholder="user.firstName" />
              <input v-model="editedLastName" :placeholder="user.lastName" />
            </div>
            <div v-else>
              <p>{{ user.firstName }} {{ user.lastName }}</p>
            </div>
          </div>  
        </div>
        <div class="info-item">
          <label>Adresse e-mail</label>
          <p style="margin-top: 30px">{{ user.email }}</p>
        </div>
      </div>
      <div v-else>
        <p>Chargement des informations...</p>
      </div>
    </div>

    <div class="section">
      <div class="section-header">
        <img style="opacity: 0.7" src="@/assets/img/profile/security.png" alt="Sécurité" class="section-icon"/>
        <h2>Sécurité</h2>
      </div>
      <p>Vous pouvez mettre à jour vos paramètres de sécurité dans cette rubrique.</p>
      <div class="info-grid" v-if="user">
        <div class="info-item">
          <label>Mot de passe</label>
          <div v-if="provider === 'password'">
            <p style="margin-top: 25px">Sécurisez votre compte en modifiant régulièrement votre mot de passe</p>
            <div v-if="editingPassword">
              <input type="password" v-model="currentPassword" placeholder="Mot de passe actuel" />
              <input type="password" v-model="newPassword" placeholder="Nouveau mot de passe" />
              <input type="password" v-model="confirmNewPassword" placeholder="Confirmez le nouveau mot de passe" />
              <div class="buttons-name-position">
                <button class="buttons-name" @click="changePassword">Valider</button>
                <button class="buttons-name" @click="cancelPasswordEdit">Annuler</button>
              </div>
            </div>
            <div  class="buttons-name-position">
              <button v-if="!editingPassword" @click="editingPassword = true" style="margin-right: 20px;">Modifier</button>
            </div>
          </div>
          <p style="margin-top: 30px" v-else>Vous êtes connecté avec {{ provider }}, vous ne pouvez donc pas modifier votre mot de passe</p>
        </div>
        <div class="info-item">
          <label>Suppression du compte</label>
          <button @click="confirmDeleteAccount">Supprimer</button>
          <p>Supprimer votre compte définitivement</p>
        </div>
      </div>
      <div v-else>
        <p>Chargement des informations...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, deleteDoc, updateDoc } from "firebase/firestore"; // Import Firestore functions
import { db } from "@/firebase"; // Import your Firebase configuration
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword, deleteUser } from "firebase/auth"; // Import Firebase Auth

export default {
  name: 'UserInformations',
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editingName: false,
      editingPassword: false,
      editedFirstName: '',
      editedLastName: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      userId: this.user.id || null,
      provider: '' // Add provider to store the login provider
    };
  },
  watch: {
    user: {
      handler(newValue) {
        if (newValue && newValue.id) {
          this.userId = newValue.id;
          this.setProvider(newValue.providerData);
        } else {
          const auth = getAuth();
          const currentUser = auth.currentUser;
          if (currentUser) {
            this.userId = currentUser.uid;
            this.setProvider(currentUser.providerData);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    setProvider(providerData) {
      if (providerData && providerData.length > 0) {
        const providerId = providerData[0].providerId;
        if (providerId === 'password') {
          this.provider = 'password';
        } else if (providerId === 'google.com') {
          this.provider = 'Google';
        } else if (providerId === 'facebook.com') {
          this.provider = 'Facebook';
        } else {
          this.provider = providerId; // Handle other providers if necessary
        }
      }
    },
    onImageError(event) {
      console.error("Erreur lors du chargement de l'image", event.target.src);
      // Ajoutez un placeholder ou une image de secours ici
      event.target.src = 'path/to/placeholder-image.jpg';
    },
    onImageLoad(event) {
      console.log("Image chargée avec succès", event.target.src);
    },
    editingNameFct(boolean) {
      this.editingName = boolean;
      if (boolean) {
        this.editedFirstName = this.user.firstName;
        this.editedLastName = this.user.lastName;
      }
    },
    async changeName() {
      if (!this.userId) {
        console.error("User ID is not defined");
        return;
      }
      if (!this.editedFirstName || !this.editedLastName) {
        alert("Le prénom et le nom ne peuvent pas être vides.");
        return;
      }

      const userRef = doc(db, "users", this.userId);
      try {
        await updateDoc(userRef, {
          firstName: this.editedFirstName,
          lastName: this.editedLastName
        });
        this.$emit("update-user", { firstName: this.editedFirstName, lastName: this.editedLastName });
        this.editingNameFct(false);
        window.location.reload(); // Reload the page to reflect the changes
      } catch (error) {
        console.error("Erreur lors de la mise à jour du nom:", error);
      }
    },
    async changePassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        alert("Les nouveaux mots de passe ne correspondent pas");
        return;
      }

      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

      try {
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, this.newPassword);
        alert("Mot de passe mis à jour avec succès");
        this.editingPassword = false;
        this.currentPassword = '';
        this.newPassword = '';
        this.confirmNewPassword = '';
      } catch (error) {
        console.error("Erreur lors de la mise à jour du mot de passe:", error);
        alert("Erreur lors de la mise à jour du mot de passe");
      }
    },
    cancelPasswordEdit() {
      this.editingPassword = false;
      this.currentPassword = '';
      this.newPassword = '';
      this.confirmNewPassword = '';
    },
    // Méthode pour confirmer la suppression
    async confirmDeleteAccount() {
      const confirmed = confirm("Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.");
      if (confirmed) {
        console.log("Confirmation reçue, suppression du compte en cours..."); // Débogage
        await this.deleteAccount();
      } else {
        console.log("Suppression annulée par l'utilisateur."); // Débogage
      }
    },

    // Méthode pour supprimer le compte
    async deleteAccount() {
      const auth = getAuth();
      const user = auth.currentUser;

      // Supprimer les données utilisateur de Firestore
      const userRef = doc(db, "users", this.userId);
      try {
        await deleteDoc(userRef);
        console.log("Données utilisateur supprimées de Firestore."); // Débogage
      } catch (error) {
        console.error("Erreur lors de la suppression des données utilisateur dans Firestore:", error);
        alert("Erreur lors de la suppression des données utilisateur.");
        return;
      }

      // Supprimer le compte utilisateur de Firebase Authentication
      try {
        await deleteUser(user);
        alert("Votre compte a été supprimé avec succès.");
        console.log("Compte utilisateur supprimé."); // Débogage
        this.$router.push({ name: 'Accueil' }); // Redirige vers la page d'accueil après la suppression
      } catch (error) {
        console.error("Erreur lors de la suppression du compte:", error);
        alert("Erreur lors de la suppression du compte. Veuillez réessayer.");
      }
    }
  }
};
</script>



<style scoped>
.profile-container {
  padding: 20px;
  border-radius: 10px;
  margin: auto;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--dark-gray);
}

.section {
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.section-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

h2 {
  font-size: 1.4em;
  color: var(--dark-gray);
}

.info-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 70%;
  margin: 50px 0;
}

.info-item {
  background: white;
  padding: 30px 40px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(50% - 20px);
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}

.info-item label {
  font-weight: bold;
}

.info-item button {
  align-self: flex-end;
  background: none;
  border: none;
  color: var(--jaune);
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  margin-bottom: 5px;
  font-weight: 600;
}

.info-item button:hover {
  text-decoration: underline;
}

.modifyButton {
  position: absolute;
  right: 30px;
  top: 35%;
}

.info-item p {
  margin: 10px 0 0 0;
  font-size: 1em;
  width: 80%;
}

.info-item input {
  margin: 30px 0 5px 0;
  padding: 5px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  width: 28%;
}

.buttons-name-position {
  position: absolute;
  right: 20px;
  top: 37%;
}

.buttons-name {
  margin-right: 20px;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1rem;
    color: #333;
  }
  .profile-container {
    width: 90%;
  }
  .section {
    margin-bottom: 0;
  }
  .section p {
    font-size: 0.8rem;
    margin-top: 15px;
  }

  .info-item {
    padding: 15px 25px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: none;
    width: 80%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
  }

  .info-item p {
    width: auto;
    margin: 10px 0 !important;
  }
  .info-item label {
    font-size: 0.8rem;
  }

  .info-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    margin: 30px auto;
  }

  button {
    margin-top: 0;
    font-size: 0.8rem !important;
  }

  .info-item input {
    margin: 10px 0 5px 0;
    padding: 5px 10px;
    font-size: 0.7rem;
  }

  .buttons-name-position {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .buttons-name-position button {
    margin: 10px 0 0 0;
  }

  .info-item-names {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
