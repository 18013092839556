<template>
  <footer>
    <div class="columns-section">
      <div class="first">
        <router-link to="/">
          <img src="@/assets/img/logo.png" alt="Alego">
        </router-link>
      </div>

      <div class="second-third-wrapper">
        <div class="second">
          <ul>
            <li @click="navigateTo('/voyage')">
              <router-link to="/voyage">Créer mon voyage</router-link>
            </li>
            <li @click="openFeedbackOverlay">
              <router-link to="/about">A propos d'Alego</router-link>
            </li>
            <li @click="openFeedbackOverlay">
              <router-link to="#">Faire un retour</router-link>
            </li>
          </ul>
        </div>

        <div class="third">
          <p>SUIVEZ-NOUS</p>
          <div class="social-network-images">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src="@/assets/img/linkedin.png" alt="linkedin">
            </a>
            <a href="https://www.instagram.com/alego.app/" target="_blank" rel="noopener noreferrer">
              <img src="@/assets/img/instagram.png" alt="instagram">
            </a>
            <a href="https://www.tiktok.com/@alego_app" target="_blank" rel="noopener noreferrer">
              <img src="@/assets/img/tiktok.webp" alt="tiktok">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <p @click="navigateTo('Legals')">Mentions légales</p>
      <p @click="navigateTo('Policy')">Politique de confidentialité</p>
    </div>

    <!-- Overlay components for Legal and Privacy Policies -->
    <OverlayFeedback :isVisible="showOverlay" @close="showOverlay = false" />
    <LegalMentions v-if="activeOverlay === 'legal'" @close="closeOverlay" />
    <PrivacyPolicy v-if="activeOverlay === 'privacy'" @close="closeOverlay" />
  </footer>
</template>

<script>
import OverlayFeedback from "@/components/ContactOverlay.vue";


export default {
  name: 'SiteFooter',
  components: {
    OverlayFeedback,
  },
  data() {
    return {
      showOverlay: false,
      activeOverlay: null,
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    openFeedbackOverlay() {
      this.showOverlay = true;
    },

    closeOverlay() {
      this.activeOverlay = null;
    },
  },
}
</script>



<style scoped>

/* SECTION Footer Columns */

footer {
  width: 100%;
  background-color: var(--vert);
  color: white;
  padding: 20px 0;
  text-align: center;
}

.columns-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 5%;
}

.columns-section .first {
  flex: 1 1 15%;
  padding: 0;
  text-align: center;
}

.columns-section .first img {
  width: 70%;
  margin-top: 40px;
}

.second-third-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 80%;
  padding: 0;
}

.columns-section .second {
  color: white;
  margin: auto;
  padding-right: 40px;
}

.columns-section .second ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.columns-section .second li {
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.5rem;
  margin: 20px 10px;
}

.columns-section .second li a {
  color: inherit; /* Conserver la couleur actuelle (blanche) */
  text-decoration: none; /* Supprimer le soulignement */
  font-weight: bold;
  font-size: 1em;
  line-height: 1.5rem;
}


.columns-section .third p {
  font-size: 1.2rem;
  font-weight: 700;
}

.columns-section .social-network-images {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.columns-section .social-network-images img {
  width: 30px;
}

.footer-bottom {
  margin-top: 10px;
}

.footer-bottom p {
  font-size: 1rem;
  margin: 10px 0;
}

/* Styles responsive */
@media (max-width: 768px) {
  footer {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 10px 0;
  }
  
  .columns-section {
    flex-direction: column;
    text-align: center;
  }

  .second-third-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .columns-section .first,
  .columns-section .second,
  .columns-section .third {
    flex: 1 1 48%;
    margin: 5px 0;
  }

  .columns-section .second ul,
  .columns-section .third p {
    font-size: 0.8rem;
  }

  .columns-section .second li,
  .columns-section .third p {
    font-size: 0.8rem;
  }

  .columns-section .second li {
    margin:0;
    text-align: left;
  }

  .columns-section .social-network-images img {
    width: 20px;
  }

  .columns-section .first img {
    width: 30%;
    margin: 0
  }

  .footer-bottom p {
    font-size: 0.6rem;
  }
}

</style>
