import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueGtag from 'vue-gtag';

// Styles CSS
import './assets/style/style.css';
import './assets/style/voyage.css';

// Import de Element-plus, pour datepicker entre autres
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import locale from 'element-plus/es/locale/lang/fr';
import { createHead } from '@vueuse/head';

// Créer l'application Vue
const app = createApp(App);
const head = createHead();

if (process.env.NODE_ENV === 'production') {
  app.use(VueGtag, {
    config: { id: 'G-XXXXXXXXXX' }
  }, router);
}

app.use(head);

// Configurer ElementPlus avec la localisation française
app.use(ElementPlus, { locale });

// Ajouter Vue Toastification à l'application
app.use(Toast, {
  // Options de configuration par défaut de Vue Toastification (modifiable)
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false
});

// Utiliser le router et monter l'application sur l'élément #app
app.use(router).mount('#app');
