<template>
  <div>
    <h2>User Favorites</h2>
    <p>Vos favoris.</p>
  </div>
</template>

<script>
export default {
  name: 'UserFavorites'
};
</script>
