<!-- SelectWishes.vue -->

<template>

    <div class="bloc">
    
        <div class="back-arrow" @click="updateCurrentStep('selectName')">
          <img src="@/assets/img/voyage/previous.png" alt="Retour"> 
        </div>

        <h2>{{ prenom }}, tes envies de voyage sont uniques !</h2>
        
        <!-- Question ajoutée ici -->
        <div class="question" >
            <p>Connais-tu déjà ta destination ?</p>
            <div class="boolean-response">
                <button @click="setDestinationKnowledge(true)">Oui</button>
                <button @click="setDestinationKnowledge(false)">Non</button>
            </div>    
        </div>
        
        <div class="informations-container">
            <form @submit.prevent="soumettreSouhaits">

                <div v-if="knowsDestination === true">
                    <div class="input-group" style="margin-top: 30px;">
                        <label for="destination">Où souhaites-tu aller ?</label>
                        <input type="text" id="destination" v-model="destination" required>
                    </div>
                    <div class="button">
                        <button id="submitButton" type="submit" @click="submitForm" :disabled="loading">
                          <span v-if="!loading">Continuer</span>
                          <span v-else>
                            <img src="https://media.tenor.com/wpSo-8CrXqUAAAAi/loading-loading-forever.gif" width="24" height="24" alt="Chargement...">
                          </span>
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>  

</template>

<script>
export default {
  props: ['prenom'],
  data() {
    return {
      knowsDestination: null,
      destination: '',
      loading: false
    };
  },
  methods: {
    setDestinationKnowledge(knowledge) {
      this.knowsDestination = knowledge;
      // Émettre l'événement pour changer de composant quand l'utilisateur dit "Non"
      if (!knowledge) {
        this.$emit('updateStep', 'fillWishes');
      }
    },
    async validerDestination() {
      if (!this.destination) {
        alert("Veuillez entrer une destination.");
        return;
      }
      try {
        const response = await fetch('https://europe-west1-alegotravel.cloudfunctions.net/api/regionCheck', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ city: this.destination }),
        });
        if (!response.ok) {
          throw new Error('Erreur réseau ou côté serveur');
        }
        const data = await response.json();
        this.$emit('updateDestinationData', {
          explicitGeographicZone: data.explicitGeographicZone,
          explicitCity: data.explicitCity, 
          geographicZone: data.geographicZone,
          CountryDestination: data.paysDestination,
          destination: this.destination
        });

        if (data.explicitGeographicZone === 0 || (data.explicitCity === 0 && data.explicitGeographicZone === 1)) {
          this.$emit('updateStep', 'fillWishes');
        } else {
          this.$emit('updateStep', 'selectDates');
        }
      } catch (error) {
        console.error("Erreur lors de l'appel API: ", error);
      }
    },
    async soumettreSouhaits() {
      if (this.knowsDestination) {
        this.loading = true; // Activer le chargement
        try {
          await this.validerDestination();
        } catch (error) {
          console.error("Erreur lors de la soumission des souhaits: ", error);
        } finally {
          this.loading = false; // Désactiver le chargement une fois le traitement terminé
        }
      }
    },
    updateCurrentStep(step) {
      this.$emit('updateStep', step);
    },
  },
}
</script>


<style>

.bloc h2 {  
    font-size: 1.5rem;
    text-align: center;
    color: var(--vert);
}

.informations-container {
    width: 75%;
    padding: 10px 30px 0 10px;
    margin: auto;
}

.input-group {
    margin: auto;
    margin-bottom: 20px;
}

.input-group label, .input-group input {
    display: block;
    width: 100%;
}

.input-group label {
    margin-bottom: 15px; /* Espacement entre le label et l'input */
    text-align: center;
}

.input-group input {
    width: 100%;
    padding: 8px;
    line-height: 1.5;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.8rem;
    font-family:'Arial Narrow Bold', sans-serif;
    letter-spacing: 1.2px;
}

.input-group input[type="number"]::-webkit-inner-spin-button,
.input-group input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Enlève les flèches dans WebKit */
    margin: 0;
}

.button {
    display: flex;
    justify-content: center;
}

button {
    background-color: var(--vert); /* Couleur verte */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    font-size: 0.9rem;
}

button:hover {
    background-color: var(--jaune);
}

.question {
    width: 50%;
    padding: 0 30px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;
}
.boolean-response {
    display: flex;
    justify-content: space-around;
}

/* Media queries pour les petits écrans */
@media (max-width: 768px) {
  .bloc {
    margin: 50px 0;
  }

  .bloc h2 {
        font-size: 1.2rem !important;
        text-align: center;
        color: var(--vert);
        font-weight: bold;
        margin: 30px 0;
  }
  .question {
    width: 90%;
    padding: 0;
  }  
  button {
    padding: 20px 30px;
    border-radius: 30px;
    margin-top: 30px;
    font-size: 0.9rem;
  }

}

</style>