import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { differenceInCalendarDays } from 'date-fns';


function formatFirebaseTimestampToDisplay(timestamp) {
  const date = timestamp.toDate(); 
  return format(date, 'EEE d MMM', { locale: fr });
}

function calculateDayDifference(startTimestamp, endTimestamp) {
  const startDate = startTimestamp.toDate();
  const endDate = endTimestamp.toDate();
  return differenceInCalendarDays(endDate, startDate) + 1;
}


export default async function generateHTMLContent(tripData) {

        let starsHTML = '';
        if (tripData.selectedHotel && tripData.selectedHotel.starRating) {
          const starRating = tripData.selectedHotel.starRating;
          starsHTML = `<span>${'★'.repeat(starRating)}</span>`;
        }
    
        let ratingHTML = '';
        if (tripData.selectedHotel && tripData.selectedHotel.ratings && tripData.selectedHotel.ratings.rating) {
          ratingHTML = `<span class="hotel-score">${tripData.selectedHotel.ratings.rating}</span>`;
        }
  
        const departureDateDisplay = formatFirebaseTimestampToDisplay(tripData.departureDate);
        const returnDateDisplay = formatFirebaseTimestampToDisplay(tripData.returnDate);
        const dayDifference = calculateDayDifference(tripData.departureDate, tripData.returnDate);
  
        const totalHotelPrice = tripData.selectedHotel ? Math.round(tripData.selectedHotel.dailyPrice * tripData.nightsInDest) : '';
        const imageURL = tripData.selectedHotel && tripData.selectedHotel.images ? tripData.selectedHotel.images[0].replace('{size}', 'x500') : 'path/to/default-image.jpg';
  
        let htmlContent = `
        <!DOCTYPE html>
        <html lang="fr">
        <head>
          <meta charset="UTF-8">
          <style>
      
  body {
    padding: 40px;
  }
  
  .sumUp {
      width: 100%;
      color: #383838; /* --dark-gray */
  }
  
  .sumUp-title {
      margin: 20px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
  }
  
  .sumUp-title h2 {
      color: #14444c; /* --vert */
      font-size: 1.3rem;
  }
  
  .return-back {
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      color: #525252;
      cursor: pointer;
  }
  
  .return-back img {
      width: 20px;
      height: 20px;
  }
  
  .save-Trip {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      background-color: #14444c; /* --vert */
      padding: 2px 15px;
      border-radius: 50px;
      color: white;
      font-size: 0.9rem;
      font-weight: 600;
  }
  
  .save-Trip img {
      width: 35px;
      height: 35px;
  }
  
  .sumUp-banner {
      width: 80%;
      border-radius: 50px;
      padding: 0px 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #525252;
      background-color: #fff; /* --white */
      margin: auto;
  }
  
  .sumUp-banner img {
      width: 20px;
      height: 20px;
  }
  
  .banner-separator {
      width: 1px;
      height: 40px;
      background-color: #525252;
      display: block;
  }
  
  .banner-cities {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3vh;
      font-weight: 600;
      font-size: 0.9rem;
      color: #525252;
  }
  
  .nb-adults {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      font-size: 0.9rem;
      font-weight: 600;
      color: #525252;
  }
  
  .banner-dates {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3vh;
      font-size: 0.9rem;
      color: #525252;
      font-weight: 600;
  }
  
  .sumUp-first-section {
      width: 90%;
      margin: auto;
  }
  
  .sumUp-dates {
      display: flex;
      flex-direction: row;
      color: #434343;
      margin: 20px 0 20px 30px;
      align-items: center;
      gap: 70px;
  }
  
  .sumUp-dates h4 {
      color: #14444c; /* --vert */
      font-weight: 500;
      font-size: 1rem;
  }
  
  .sumUp-dates h2 {
      font-weight: 500;
      font-size: 1.3rem;
  }
  
  .vertical-bar {
      width: 1px;
      height: auto;
      background-color: #525252;
      display: block;
      margin-bottom: 20px;
  }
  
  .sumUp-infos {
      width: 90%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-left: 20px;
  }
  
  /* Section Hôtel */
  .hotel-info .parent {
      display: grid;
      grid-template-columns: 0.7fr 1.5fr 0.1fr 1.3fr; /* Fixed percentage widths to match flight info */
      grid-template-rows: auto;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
  }
  
  .hotel-info .div1 { grid-area: 1 / 1 / 2 / 2; display: flex; align-items: center; justify-content: center;}
  .hotel-info .div2 { grid-area: 1 / 2 / 2 / 3; display: flex; flex-direction: column; justify-content: space-between; margin-left: 10%;}
  .hotel-info .div3 { grid-area: 1 / 4 / 2 / 5; display: flex; flex-direction: column; justify-content: center; align-items: center; border-left: 1px solid gray; padding-left: 40px;}
  
  .hotel-image {
    width: 100%;
    border-radius: 18px;
    width: calc(100% * 1 /3);
  }
  
  .hotel-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 300px;
    border-radius: 18px;
  }
  
  .hotel-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
  }
  
  .hotel-rating {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-direction: row;
  }
  
  .hotel-rating span:first-child {
      color: gold;
  }
  
  .hotel-score {
      background-color: #d6a85c; /* gold-like color */
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      width: 30px;
  }
  
  .hotel-address {
      margin: 0;
      font-size: 0.9rem;
      color: #333;
  }
  
  .hotel-price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
  }
  
  .hotel-price p {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0;
  }
  
  .reserve-button {
      background-color: #d6a85c; /* gold-like color */
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
  }
  
  .reserve-button:hover {
      background-color: #c5954b;
  }
  
  .sumUp-total-price {
      background-color: #d6a85c; /* gold-like color */
      color: white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      border-radius: 25px;
      margin: 55px auto 25px auto;
  }
  
  .sumUp-total-price h4 {
      margin: 0;
      font-size: 1.4rem;
      font-weight: bold;
  }
  
  .sumUp-dot-line {
      flex-grow: 1;
      border-bottom: 2px dotted white;
      margin: 0 20px;
  }
  
  .sumUp-end-total-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
  }
  
  .sumUp-end-total-price h4 {
      margin: 0;
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 25px;
  }
  
  .sumUp-end-total-price p {
      margin: 0;
      font-size: 0.9rem;
  }
  
  .sumUp-separation-line {
      width: 100%;
      border-bottom: 1px solid darkgray;
      margin: 40px 0;
  }
  
  .activity-planning {
      text-align: center;
      margin: 50px 0;
  }
  
  .activity-planning p {
      font-size: 1.3rem;
      color: #14444c; /* --vert */
      font-weight: 300;
      width: 70%;
      margin: 30px auto;
      line-height: 3rem;
  }
  
  .start-button {
      background-color: #14444c; /* --vert */
      color: white;
      border: none;
      border-radius: 40px; /* Rounded corners */
      padding: 20px 35px;
      font-size: 1.3rem;
      font-weight: bold;
      cursor: pointer;
      margin-top: 20px;
      transition: background-color 0.3s ease;
  }
  
  .start-button:hover {
      background-color: #14444c; /* Darker shade for hover effect */
  }
  
  .sumUp-info-bloc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 70px;
      margin-left: 30px;
  }
  
  .on-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
  
  .expiredLink-bloc {
    font-size: 0.8rem;
    margin: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .expiredLink-bloc button {
    padding: 5px;
    width: 30%;
    margin: auto;
  }
  
  /* Media Query for Mobile */
  @media (max-width: 768px) {
    
    .on-line {
      font-size: 0.8rem;
    }
    .sumUp-banner {
      padding: 0px 10px;
      width: 90%;
      flex-wrap: wrap;
      border-radius: 10px;
      justify-content: space-around;
    }
  
    .banner-separator {
      display: none;
    }
  
    .banner-cities {
      font-size: 0.7rem;
    }
  
    .nb-adults {
      font-size: 0.8rem;
    }
  
    .banner-dates {
      font-size: 0.7rem;
    }
  
    .sumUp-title {
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      width: 95%;
    }
  
    .return-back {
      margin-top: -10px;
      font-size: 0.7rem;
      left: 30px;
      position: absolute;
    }
  
    .sumUp-title h2 {
      color: #14444c; /* --vert */
      font-size: 1rem;
      padding-top: 40px;
    }
  
    .vertical-bar {
      display: none;
    }
  
    .sumUp-infos {
      width: 100%;
      margin: 0;
    }
  
    .sumUp-info-bloc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
    }
  
    .sumUp-first-section {
      width: 100%;
    }
  
    .sumUp-dates {
      margin: 20px 20px 0 20px;
      gap: 30px;
    }
  
    .sumUp-dates h2 {
      font-size: 1.1rem;
    }
  
    .sumUp-dates h4 {
      font-size: 0.9rem;
      margin: 15px 10px 10px 10px;
    }
  
    .hotel-rating {
      justify-content: center;
    }
  
    .hotel-address {
      font-size: 0.7rem;
    }
  
    .sumUp-total-price {
      padding: 20px;
      margin: 25px 0px;
    }
  
    .sumUp-total-price h4 {
      font-size: 1rem;
    }
  
    .sumUp-dot-line {
      display: none;
    }
  
    .sumUp-end-total-price {
      display: flex;
      flex-direction: column;
      align-items: center;
    } 
  
    .sumUp-end-total-price h4 {
      margin: 0;
    }
  
    .sumUp-separation-line {
      width: 90%;
      margin: 30px auto 0 auto;
    }
  
    .activity-planning {
      text-align: center;
      margin: 20px 0;
    }
  
    .activity-planning p {
      font-size: 1rem;
      margin: 0 auto !important;
      text-align: center;
      line-height: 2rem;
    }
  
    .start-button {
      color: white;
      border: none;
      border-radius: 40px; /* Rounded corners */
      padding: 15px 20px;
      font-size: 1.1rem;
      font-weight: bold;
      cursor: pointer;
      margin-top: 30px;
    }
  
    .reduceWidth {
      width: 90%;
      margin: auto;
    }
  
  }
  
  .train-card {
    border: 1px solid #ccc; /* --gray */
    border-radius: 21px;
    width: 100%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 3fr 1.2fr; /* Augmenter la largeur de la partie droite */
    background-color: #fff; /* --white */
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .train-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .train-card-left {
    padding: 40px;
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    background-color: #fff; /* --white */
    cursor: pointer;
  }
  
  .train-card-right {
    background-color: #14444c; /* --vert */
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .train-card-right .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .train-card-right h2,
  .train-card-right h4 {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #E9B949;
  }
  
  .train-card-right h2 {
    margin-bottom: 5px;
    font-size: 1.3rem !important;
  }
  
  .train-card-right h4 {
    font-weight: normal;
    font-size: 1.3rem;
  }
  
  .train-card-right .more-details-button {
    background-color: #e0af5d; /* --jaune */
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .train-card-right .more-details-button a {
    color: white;
    text-decoration: none;
  }
  
  .rows-container {
    display: grid;
    row-gap: 20px;
  }
  
  .train-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .train-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .train-row .from-to {
    width: 20%;
  }
  
  .train-row .from-to-right {
    width: 20%;
    text-align: right;
  }
  
  .line-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: #383838; /* --dark-gray */
    border-radius: 50%;
    margin: 0;
  }
  
  .line {
    flex-grow: 1;
    height: 2px;
    background-color: #383838; /* --dark-gray */
    margin: 0;
  }
  
  .time-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .train-row h3,
  .time-row h3 {
    color: #383838; /* --dark-gray */
    font-weight: bold;
    margin: 0;
  }
  
  .train-row p,
  .line-container h4,
  .time-row p {
    margin: 0;
    font-size: 0.9rem;
    color: #383838; /* --dark-gray */
    text-align: center;
  }
  
  .train-row p,
  .time-row p {
    flex: 1;
    text-align: center;
  }
  
  .line-container h4 {
    font-weight: bold;
    margin: 0 20px;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .train-card {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin: 10px auto;
      padding: 0;
      background-color: #fff; /* --white */
      border: 1px solid #ccc; /* --gray */
      border-radius: 21px;
    }
  
    .train-card-left {
      border-top-right-radius: 21px;
      padding: 20px;
    }
  
    .train-card-right {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 21px;
    }
  
    .train-card-right {
      border-radius: 0;
      padding: 10px;
      border-top: 1px solid #ccc; /* --gray */
      border-radius: 0 0 21px 21px;
      justify-content: flex-end;
      background-color: #14444c; /* --vert */
      color: #e0af5d; /* --jaune */
    }
  
    .train-card-right .text-container {
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-right: 20px;
      justify-content: flex-end;
    }
  
    .train-card-right .text-container h2 {
      font-size: 1rem;
      font-weight: bold;
    }
  
    .train-card-right .text-container h4 {
      font-size: 0.8rem;
      font-weight: bold;
    }
  
    .train-card-right .more-details-button {
      font-size: 0.8rem;
    }
  
    .train-row .from-to, .train-row .from-to-right {
      font-size: 0.8rem;
      width: 30%;
    }
  
    .train-row p, .time-row p {
      font-size: 0.7rem;
    }
  
    .time-row h3 {
      font-size: 0.9rem;
    }
  
    .train-card-right .more-details-button {
      margin-top: 0px;
      margin-left: 20px;
    }
  
  }
  
  .hotel-card {
    border: 1px solid #ccc; /* --gray */
    border-radius: 21px;
    width: 100%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 3fr 1.2fr; /* Augmenter la largeur de la partie droite */
    background-color: #fff; /* --white */
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .hotel-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .hotel-card-left {
    padding: 40px 20px;
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    background-color: #fff; /* --white */
    display: flex;
    align-items: center;
  }
  
  .hotel-card-right {
    background-color: #14444c; /* --vert */
    border-top-right-radius: 21px;
    border-bottom-right-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .hotel-card-right .text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .hotel-card-right p {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #E9B949;
    font-size: 1.2rem;
  }
  
  .hotel-card-right p:first-child {
    margin-bottom: 5px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .hotel-card-right button {
    background-color: #e0af5d; /* --jaune */
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .hotel-info {
    display: flex;
    align-items: center;
  }
  
  .hotel-details {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-left: 20px;
  }
  
  .hotel-name {
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0;
  }
  
  .hotel-rating span:first-child {
    color: gold;
  }
  
  .hotel-score {
    background-color: #d6a85c; /* gold-like color */
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    width: 40px;
    text-align: center;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .hotel-card {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      margin: 10px auto;
      padding: 0;
      background-color: #fff; /* --white */
      border: 1px solid #ccc; /* --gray */
      border-radius: 21px;
    }
  
    .hotel-card-left {
      border-top-right-radius: 21px;
      padding: 5px;
      display: flex;
      flex-direction: column;
    }
  
    .hotel-card-right {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 21px;
    }
  
    .hotel-card-right {
      border-radius: 0;
      padding: 10px;
      border-top: 1px solid #ccc; /* --gray */
      border-radius: 0 0 21px 21px;
      justify-content: flex-end;
      background-color: #14444c; /* --vert */
      color: #e0af5d; /* --jaune */
    }
  
    .hotel-card-right .text-container {
      font-size: 0.8rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      margin-right: 20px;
      justify-content: flex-end;
    }
  
    .hotel-card-right .text-container p:first-child {
      font-size: 1rem;
      font-weight: bold;
    }
  
    .hotel-card-right .text-container p {
      font-size: 0.8rem;
      font-weight: bold;
    }
  
    .hotel-info {
      flex-direction: column;
    }
  
    .hotel-details {
      margin-left: 0;
      text-align: center;
    }
  
    .hotel-card-right .more-details-button {
      margin-top: 0px;
      margin-left: 0px;
      padding: 7px;
      font-size: 0.8rem;
    }
  
    .hotel-card .daily-price {
      margin: 0 !important;
      font-size: 0.8rem !important;
    }
  
    .hotel-name {
      font-size: 1rem;
      margin-top: 15px;
    }
  
    .hotel-image {
      width: 100%;
    }
    
    .hotel-image img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 18px;
      max-height: calc(100% / 2); /* Limiter la hauteur à 2/3 de la largeur */
      max-height: 200px;
      border-radius: 18px;
    }
  }
  
  .flight-popup {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .flight-popup-content {
    background-color: #fff; /* --white */
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #ccc; /* --gray */
    width: 50%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .flight-close {
    color: #000; /* --black */
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .flight-close:hover,
  .flight-close:focus {
    color: #ccc; /* --gray */
    text-decoration: none;
    cursor: pointer;
  }
  
  .flight-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .flight-popup-header h2 {
    margin: 0;
    font-size: 1.2rem;
  }
  
  .flight-popup-header .flight-arrow {
    margin: 0 5px;
  }
  
  .flight-popup-header .button-choose {
    padding: 10px 20px;
    background-color: #e0af5d; /* --jaune */
    cursor: pointer;
    border-radius: 20px;
    margin-right: 30px;
    color: #fff; /* --white */
  }
  
  .flight-dates {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .flight-date {
    margin: 0 10px;
    padding: 5px 10px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: border-bottom 0.3s;
    color: #383838; /* --dark-gray */
    font-weight: normal;
  }
  
  .flight-date.flight-selected {
    border-bottom: 2px solid #000; /* --black */
    color: #383838; /* --dark-gray */
    font-weight: bold;
  }
  
  .flight-date:hover {
    border-bottom: 2px solid #ccc; /* --gray */
  }
  
  .flight-selected-date {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #e0af5d; /* --jaune */
    margin-top: 10px;
  }
  
  .flight-arrow-icon {
    margin-right: 10px;
    font-size: 1.2rem;
  }
  
  .flight-date-text {
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  .flight-segment {
    background-color: #dde3e4;
    padding: 10px;
    border-radius: 10px;
    margin: 10px auto;
    width: 80%;
  }
  
  .segments-container {
    width: 90%;
    display: flex;
    margin: auto;
  }
  
  .segment-first-column {
    flex: 0 0 25%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .segment-second-column {
    flex: 0 0 25%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
  .segment-third-column {
    flex: 0 0 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .segment-first-column h4,
  .segment-third-column h4 {
    font-size: 0.7rem;
    font-weight: 500;
    font-style: italic;
    color: #383838; /* --dark-gray */
  }
  
  .segment-first-column h3,
  .segment-third-column h3 {
    font-size: 0.9rem;
    color: #383838; /* --dark-gray */
  }
  
  .segment-second-column .segment-first-row img,
  .segment-second-column .segment-third-row img {
    width: 40px;
    height: 70px;
    margin: auto 5px;
  }
  
  .segment-second-column .segment-second-row img {
    transform: rotate(180deg);
    height: 30px;
    margin: auto 15px;
  }
  
  .escale {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 40%;
    margin: auto;
    align-items: center;
    font-size: 0.8rem;
    gap: 15px;
  }
  
  .escale img {
    width: 30px;
    height: 30px;
    margin-left: 13px;
  }
  
  .flight-selected-date img {
    width: 25px;
    height: auto;
    margin: 10px 10px;
  }
  
  @media (max-width: 768px) {
    .flight-popup-content {
      margin: 20% auto;
      padding: 20px;
      border: 1px solid #ccc; /* --gray */
      width: 80%;
      border-radius: 10px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      animation: fadeIn 0.3s;
    }
  
    .flight-popup-header h2 {
      font-size: 0.9rem;
    }
  
    .flight-date-text {
      font-size: 0.8rem;
    }
  
    .segment-first-row h3, .segment-third-row h3, .segment-second-row h4 {
      font-size: 0.7rem;
    }
  
    .segment-first-row img, .segment-third-row img {
      height: 40px !important;
      width: 20px !important;
    }
  
    .segment-third-row {
      display: flex;
      align-items: center;
    }
  
    .segment-second-row img {
      width: 23px !important;
      height: 23px !important;
    }
  }
  
          </style>
        </head>
        <body>
  
        <div class="sumUp-banner">
          <div class="banner-cities">
                <h3>${tripData.cityFrom} → ${tripData.cityTo}</h3>
          </div>
          <div class="banner-separator"></div>
          <div class="nb-adults">
              <h3>${tripData.nbPerson} Adultes</h3>
          </div>
            <div class="banner-separator"></div>
            <div class="banner-dates">
              <h3>${departureDateDisplay} - ${returnDateDisplay}</h3>
            </div>
        </div>
  
        <div class="sumUp-dates">
          <h2>Jour 1</h2>
          <h4>${departureDateDisplay}</h4>
        </div>
  
        <div class="sumUp-info-bloc">
          <div class="vertical-bar"></div>
          <div class="sumUp-infos">
        `;
  
        if (tripData.selectedFlight) {
            htmlContent += `
            <!-- Section Transport Aller -->
            <div class="train-card">
              <div class="train-card-left">
                <div class="rows-container">
                  <div class="train-info">
                    <div class="train-row">
                      <h3 class="from-to">${tripData.selectedFlight.departure_airport}</h3>
                      <p>${tripData.selectedFlight.departure_airline_name}</p>
                      <h3 class="from-to-right">${tripData.selectedFlight.departure_landing_airport}</h3>
                    </div>
                    <div class="line-container">
                      <span class="dot"></span>
                      <span class="line"></span>
                      <h4>${tripData.selectedFlight.departure_flight_duration}</h4>
                      <span class="line"></span>
                      <span class="dot"></span>
                    </div>
                    <div class="time-row">
                      <div>
                        <h3>${tripData.selectedFlight.departureHour}</h3>
                        <p>${tripData.selectedFlight.departureDateDisplay}</p>
                      </div>
                      <p>${tripData.selectedFlight.correspondanceAller === 0 ? 'Direct' : tripData.selectedFlight.correspondanceAller + ' escale(s)'}</p>
                      <div>
                        <h3 style="text-align: right;">${tripData.selectedFlight.departureHourLanding}</h3>
                        <p>${tripData.selectedFlight.departureDateLandingDisplay}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="train-card-right">
                <div class="text-container">
                  <h2>${tripData.selectedFlight.price}€</h2>
                  <h4>par pers. </h4>
                  <h5 style="color: #FFD174; margin-top: 5px; margin-bottom: 5px;">A/R</h5>
                </div>
              </div>
            </div>
            `;
        }
  
        htmlContent += `
          <!-- Section Hôtel -->
          <div class="hotel-card">
            <div class="hotel-card-left">
              <div class="rows-container">
                <div class="hotel-info">
                  <div class="hotel-image">
                   <img src="${imageURL}" alt="Hotel Image">
                  </div>
                  <div class="hotel-details">
                    <h3 class="hotel-name">${tripData.selectedHotel ? tripData.selectedHotel.name : ''}</h3>
                    <div class="hotel-rating">
                      ${starsHTML}  <!-- Affichage des étoiles -->
                      ${ratingHTML}  <!-- Affichage du score si disponible -->
                    </div>
                    <p class="hotel-address">${tripData.selectedHotel ? tripData.selectedHotel.address : ''}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="hotel-card-right">
              <div class="text-container">
                <p class="daily-price">${tripData.selectedHotel ? Math.round(tripData.selectedHotel.dailyPrice) : ''}€/ nuit</p>
                <p><strong>${totalHotelPrice ? totalHotelPrice : ''} nuits</p>
        
              </div>
            </div>
          </div>
        `;
  
        htmlContent += `
            </div> <!-- sumUp-infos -->
          </div> <!-- sumUp-info-bloc -->
        `;
  
        // Ajouter une nouvelle section pour le jour du retour
        htmlContent += `
          <div class="sumUp-dates">
            <h2>Jour ${dayDifference}</h2>
            <h4>${returnDateDisplay}</h4>
          </div>
  
          <div class="sumUp-info-bloc" style="justify-content: space-around">
            <div class="vertical-bar"></div>
            <div class="sumUp-infos" width: 90%;>
        `;
  
        if (tripData.selectedFlight || tripData.selectedGroundTransportation) {
            htmlContent += `
            <!-- Section Transport Retour -->
            <div class="train-card">
              <div class="train-card-left">
                <div class="rows-container">
                  <div class="train-info">
                    <div class="train-row">
                      <h3 class="from-to">${tripData.selectedFlight ? tripData.selectedFlight.return_takeOff_airport : tripData.returnTrip.origine.city}</h3>
                      <p>${tripData.selectedFlight ? tripData.selectedFlight.return_airline_name : tripData.returnTrip.providers[0].company}</p>
                      <h3 class="from-to-right">${tripData.selectedFlight ? tripData.selectedFlight.return_airport : tripData.returnTrip.destination.city}</h3>
                    </div>
                    <div class="line-container">
                      <span class="dot"></span>
                      <span class="line"></span>
                      <h4>${tripData.selectedFlight ? tripData.selectedFlight.return_flight_duration : tripData.returnTrip.durationDisplay}</h4>
                      <span class="line"></span>
                      <span class="dot"></span>
                    </div>
                    <div class="time-row">
                      <div>
                        <h3>${tripData.selectedFlight ? tripData.selectedFlight.returnHourTakeOff : tripData.returnTrip.departureHour}</h3>
                        <p>${tripData.selectedFlight ? tripData.selectedFlight.returnDateTakeOffDisplay : tripData.returnTrip.departureDateDisplay}</p>
                      </div>
                      <p>
                        ${tripData.selectedFlight ? 
                          (tripData.selectedFlight.correspondanceRetour === 0 ? 'Direct' : tripData.selectedFlight.correspondanceRetour + ' escale(s)') :
                          (tripData.returnTrip.numberOfSegments - 1 === 0 ? 'Direct' : (tripData.returnTrip.numberOfSegments - 1) + ' correspondance' + ((tripData.returnTrip.numberOfSegments - 1) > 1 ? 's' : ''))
                        }
                      </p>
                      <div>
                        <h3 style="text-align: right;">${tripData.selectedFlight ? tripData.selectedFlight.returnHour : tripData.returnTrip.arrivalHour}</h3>
                        <p>${tripData.selectedFlight ? tripData.selectedFlight.returnDateDisplay : tripData.returnTrip.arrivalDateDisplay}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="train-card-right">
                <div class="text-container">
                  <h2>${tripData.selectedFlight ? tripData.selectedFlight.price : tripData.returnTrip.price}€</h2>
                  <h4>par pers.</h4>
                  <h5 style="color: #FFD174; margin-top: 5px; margin-bottom: 5px;"> ${tripData.selectedFlight ? "A/R" : "(retour)"}</h5>
                </div>
              </div>
            </div>
            `;
        }
  
        htmlContent += `
            </div> <!-- sumUp-infos -->
          </div> <!-- sumUp-info-bloc -->
        </body>
        </html>
        `;
  
  
        
        //console.log(htmlContent);
        return htmlContent;
}

