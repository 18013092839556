<template>
<div>

    <AboutUsFirst />
    <AboutUsSecond />
    <AboutUsThird />


</div>
</template>

<script>
import AboutUsFirst from '../components/AboutUsComponents/AboutUsFirst.vue';
import AboutUsSecond from '../components/AboutUsComponents/AboutUsSecond.vue';
import AboutUsThird from '../components/AboutUsComponents/AboutUsThird.vue';

export default {
  name: 'AboutUs',
  components: {
    AboutUsFirst,
    AboutUsSecond,
    AboutUsThird
  },
};
</script>

<style scoped>

</style>