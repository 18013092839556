<template>
  <div class="satisfaction-section">
    <h2 class="section-title">Votre satisfaction, notre priorité</h2>
    <div class="content-container">
      <div class="quote-box">
        <p class="quote">
          “Chez Alego, ta satisfaction est au cœur de tout ce que nous faisons. Notre ambition est de te proposer une expérience de voyage sur mesure, simple et agréable, mais surtout à la hauteur de tes attentes. Chaque suggestion, chaque recommandation, et chaque fonctionnalité de notre plateforme sont pensées pour rendre ta planification de voyage aussi fluide que possible. Ton bonheur de voyager est notre plus belle récompense !”
        </p>
      </div>
      <div class="text-section">
        <div class="text-item">
          <h3 class="text-title">Ton Avis Compte : Aide-nous à Améliorer Alego 🛠</h3>
          <p class="text-description">
            Parce que nous savons que rien n’est jamais parfait, nous sommes à ton écoute. Tu rencontres un bug sur la plateforme ? Tu aimerais voir une fonctionnalité en plus ou as des idées pour améliorer notre service ? N’hésite pas à nous faire part de tes retours ! Chaque commentaire, suggestion ou idée d’amélioration est pour nous une précieuse opportunité d’évoluer et de te proposer une expérience encore meilleure.
          </p>
        </div>
        <div class="text-item">
          <h3 class="text-title">Comment nous contacter ? 📬</h3>
          <p class="text-description">
            Tu peux facilement nous envoyer tes retours via notre plateforme, par email ou en utilisant nos réseaux sociaux. Notre équipe se tient prête à recevoir tes commentaires et à te répondre rapidement. Ensemble, nous ferons d’Alego une plateforme qui te ressemble, intuitive, efficace et toujours à la pointe des besoins des voyageurs. Ton expérience est notre priorité. Merci de nous faire confiance et de nous aider à devenir chaque jour un peu meilleurs pour toi. Alego, c’est avant tout une aventure humaine, et nous sommes ravis de t’avoir à nos côtés.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AboutUsThird',
};
</script>


<style scoped>
.satisfaction-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--dark-gray);
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.section-title::after {
  content: "";
  display: block;
  width: 150px;
  height: 3px;
  background-color: var(--dark-gray);
  margin-top: 5px;
  border-radius: 2px;
  margin: 25px auto;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  margin: auto;
}

.quote-box {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quote-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.quote {
  font-style: italic;
  color: var(--dark-gray);
  font-size: 0.8rem;
  line-height: 1.5;
}

.text-section {
  flex: 1;
  display: flex;
  flex-direction: column; /* Assure que les éléments sont en colonne */
  gap: 30px; /* Ajoute un espace entre les éléments */
  margin-top: 40px;
}

.text-item {
  margin-bottom: 1rem;
}

.text-title {
  font-size: 1rem;
  font-weight: bold;
  color: var(--dark-gray);
  margin-bottom: 0.5rem;
}

.text-description {
  font-size: 0.9rem;
  color: var(--dark-gray);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.2rem;
  }

  .content-container {
    width: 85%;
    padding: 0 10px;
    margin: auto;
    text-align: justify;
  }

  .quote-box {
    padding: 1.5rem;
  }

  .quote {
    font-size: 0.7rem;
  }

  .text-title {
    font-size: 0.9rem;
    text-align: center;
  }

  .text-description {
    font-size: 0.8rem;
  }
}

</style>
