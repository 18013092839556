<template>
  <div class="section-complete-voyage">
    <h2 class="title">Votre voyage complet sur une seule plateforme</h2>
    <div class="grid-container">
      <div class="grid-item">
        <div class="title-and-image">
          <img src="@/assets/img/landing/destination-icon.png" alt="Destination icon">
          <h3>Destination</h3>
        </div>
        <p>Découvrez des destinations personnalisées adaptées à vos envies grâce à l’Intelligence Artificielle</p>
      </div>
      <div class="grid-item">
        <div class="title-and-image">
          <img src="@/assets/img/landing/transport-icon.png" alt="Transport icon">
          <h3>Transport</h3>
        </div>
        <p>Accédez à un large choix de transports en fonction de vos préférences : avion, train, bus</p>
      </div>
      <div class="grid-item">
        <div class="title-and-image">
          <img src="@/assets/img/landing/hebergement-icon.png" alt="Hébergement icon">
          <h3>Hébergement</h3>
        </div>
        <p>Sélectionnez l'hébergement idéal parmi de nombreuses options : hôtels, maisons d’hôtes, et plus</p>
      </div>
      <div class="grid-item">
        <div class="title-and-image">
          <img src="@/assets/img/landing/activites-icon.png" alt="Activités icon">
          <h3>Activités</h3>
        </div>
        <p>Organisez facilement vos activités à l'avance avec notre planificateur intégré</p>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'AccueilStep3',
  // Ajoutez ici vos propriétés, méthodes, ou données si nécessaire.
}
</script>

<style scoped>
.section-complete-voyage {
  text-align: center;
  padding: 50px 20px 10px 20px;
}

.title {
  color: var(--vert); /* Couleur adaptée au design */
  font-size: 1.8rem;
  margin-bottom: 30px;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
  width: 60%; /* Limitation à 70% de la largeur */
  margin: 7ch auto; /* Centrage horizontal */
}

.grid-item {
  flex: 1 1 45%; /* Ajustement à 45% pour garantir l'affichage en deux colonnes avec le gap */
  background-color: #ffffff;
  padding: 20px 30px 0 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  box-sizing: border-box; /* Assurer que padding et bordures sont inclus dans la taille */
}

.grid-item img {
  width: 50px;
}

.grid-item h3 {
  font-size: 1.2rem;
  color: #004d40;
  margin: 10px 0 0 0;
}

.grid-item p {
  color: #666;
  font-size: 0.9rem;
}

.title-and-image {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .section-complete-voyage {
    text-align: center;
    padding: 50px 20px 0px 20px;
  }

  .grid-item {
    flex: 1 1 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column; /* Garde l'alignement vertical global */
    padding: 15px; /* Ajuster le padding pour mobile */
  }

  .grid-item .title-container {
    display: flex;
    align-items: center; /* Alignement vertical centré entre l'image et le titre */
    margin-bottom: 10px; /* Espacement entre le titre et le paragraphe */
  }

  .grid-item img {
    margin-right: 15px;
    width: 30px; /* Taille constante de l'image */
  }

  .grid-item h3 {
    font-size: 1rem;
    margin: 0;
  }

  .grid-item p {
    font-size: 0.9rem;
    margin-top: 10px;
  }

  .title {
    font-size: 1.2rem;
    width: 90%;
    margin: auto;
  }

  .grid-container {
    width: 95%;
    margin-top: 40px;
  }

  .title-and-image {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}



</style>

