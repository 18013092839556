<template>
  <div class="activities-planning-container">
    <div class="activities-container">
      
      <div class="activities-top-bloc" v-if="!isMobile">
        <div class="top-left-column">
          <div class="top-left-column-container">
            <div class="custom-select">
              <select v-if="nbDays > 0" v-model="selectedDay">
                <option v-for="day in nbDays" :key="day" :value="day">Jour {{ day }}</option>
              </select>
            </div>
            <button type="button" @click="generatePlanningWithAI">
              <img src="@/assets/img/activities/ai.png" alt="ia" />Génération auto
            </button>
          </div>
        </div>

        <div class="top-right-column" v-if="!isMobile">
          <div class="button-group-left">
            <button type="button" @click="switchToListView" :class="{ highlighted: activeTab === 'list' }">Liste</button>
            <button type="button" @click="switchToMapView" :class="{ highlighted: activeTab === 'map' }">Carte</button>
          </div>
          <div class="button-group-right">
            <button type="button" @click="saveAndReturnToProfile">Sauvegarder et revenir au profil</button>
          </div>
        </div>
       

      </div>

      <div class="activities-main-bloc">
        <div class="activities-left-column">
          <div class="accordion">
            <div class="accordion-item">
              <div class="accordion-header">Matin</div>
              <div class="accordion-content" id="morning">
                <draggable
                  class="draggable-container morning-container"
                  :list="morningActivities"
                  group="activities"
                  handle=".drag-handle"
                  ghost-class="sortable-ghost"
                >
                  <template #item="{ element, index }">
                    <div class="activity-wrapper" :key="element.id" @click="centerMapOnActivity(element)">
                      <div class="activity-card">
                        <button class="close-button" @click="removeActivity(selectedDay, 'morning', index)">
                          &times;
                        </button>
                        <img :src="getActivityImage(element)" alt="Activity Image" />
                        <div class="activity-details">
                          <h4>{{ element.name }}</h4>
                          <p>{{ element.description }}</p>
                          <p class="activity-time">
                            <img src="@/assets/img/activities/clock.png" alt="Clock Icon" />
                            ~{{ formatDuration(element.duration) }}
                          </p>
                        </div>
                      </div>
                      <div class="drag-handle">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                      </div>
                    </div>
                  </template>
                </draggable>
                <button
                  class="add-activity-button"
                  @click="addSelectedActivity('morning')"
                  :disabled="!selectedActivity"
                >
                  <img style="width: 20px; height: 20px" src="@/assets/img/activities/plus-icon.png" alt="" />
                  Ajouter l'activité sélectionnée
                </button>
              </div>
            </div>

            <div class="accordion-item">
              <div class="accordion-header">Après-midi</div>
              <div class="accordion-content" id="afternoon">
                <draggable
                  class="draggable-container afternoon-container"
                  :list="afternoonActivities"
                  group="activities"
                  handle=".drag-handle"
                  ghost-class="sortable-ghost"
                >
                  <template #item="{ element, index }">
                    <div class="activity-wrapper" :key="element.id" @click="centerMapOnActivity(element)">
                      <div class="activity-card">
                        <button class="close-button" @click="removeActivity(selectedDay, 'afternoon', index)">
                          &times;
                        </button>
                        <img :src="getActivityImage(element)" alt="Activity Image" />
                        <div class="activity-details">
                          <h4>{{ element.name }}</h4>
                          <p>{{ element.description }}</p>
                          <p class="activity-time">
                            <img src="@/assets/img/activities/clock.png" alt="Clock Icon" />
                            ~{{ formatDuration(element.duration) }}
                          </p>
                        </div>
                      </div>
                      <div class="drag-handle">
                        <div class="bar"></div>
                        <div class="bar"></div>
                        <div class="bar"></div>
                      </div>
                    </div>
                  </template>
                </draggable>
                <button
                  class="add-activity-button"
                  @click="addSelectedActivity('afternoon')"
                  :disabled="!selectedActivity"
                >
                  <img style="width: 20px; height: 20px" src="@/assets/img/activities/plus-icon.png" alt="" />
                  Ajouter l'activité sélectionnée
                </button>
              </div>
            </div>
          </div>
          <div class="left-column-validate">
            <button class="left-column-validate-button" @click="savePlanning">Valider</button>
            <button class="left-column-cancel-button" @click="cancelPlanning">Annuler</button>
          </div>
        </div>


        <!-- Affichage conditionnel de la carte ou de la liste -->
        <div class="activities-right-column">
           <!-- Boutons sur la carte en version mobile -->
          <div class="map-buttons-mobile" v-if="isMobile">
            <div class="button-group-left">
              <button type="button" @click="switchToListView" :class="{ highlighted: activeTab === 'list' }">Liste</button>
              <button type="button" @click="switchToMapView" :class="{ highlighted: activeTab === 'map' }">Carte</button>
            </div>
            <div class="button-group-right">
              <button type="button" @click="saveAndReturnToProfile">Sauvegarder et revenir au profil</button>
            </div>
          </div>
          <div id="map" v-show="activeTab === 'map'" style="width: 100%; height: 100%"></div>

          
          <div v-show="activeTab === 'list'" class="list-container">
            <div class="activity-list">

              <div v-for="activity in proposedActivities" :key="activity.id" class="activity-card">
                <img :src="getActivityImage(activity)" alt="Activity Image" class="activity-image"/>
                <div class="activity-info">
                  <h4>{{ activity.name }}</h4>
                  <p>{{ activity.description }}</p>
                  <p class="activity-time">
                    <img src="@/assets/img/activities/clock.png" alt="Clock Icon" />
                    ~{{ formatDuration(activity.duration) }}
                  </p>
                </div>
                <div class="add-buttons">
                  <button class="add-activity-map-button" @click="addActivityToPeriod('morning', activity)">+ Matin</button>
                  <button class="add-activity-map-button" @click="addActivityToPeriod('afternoon', activity)">+ Après-midi</button>
                </div>
              </div>
            </div>
          </div>

          <div class="info-box" v-if="selectedActivity">
            <h3>{{ selectedActivity.name }}</h3>
            <div class="info-box-p">
              <p>Type: {{ selectedActivity.type }}</p>
              <p>Durée: {{ formatDuration(selectedActivity.duration) }}</p>
            </div>
            <div class="add-activity-map-buttons">
              <button class="add-activity-map-button" @click="addActivityToPeriod('morning', selectedActivity)">Ajouter à la matinée</button>
              <button class="add-activity-map-button" @click="addActivityToPeriod('afternoon', selectedActivity)">Ajouter à l'après-midi</button>
            </div>
          </div>
        </div>


      </div>

      <div class="open-planning-button" v-if="isMobile & !isMobileTransitionOpen" @click="toggleMobileTransition">
        <img src="@/assets/img/activities/up.png" alt="">
        <span>Planning</span>
        <img src="@/assets/img/activities/up.png" alt="">

      </div>

      <div class="activities-main-bloc-mobile" v-if="isMobile">
        <!-- PLANNING Slide MOBILE -->
        <div :class="['mobile-transition-div', { open: isMobileTransitionOpen }]">
          <div v-if="isMobileTransitionOpen" class="top-planning-button">
            <button class="close-planning-button" @click="toggleMobileTransition"> <img src="@/assets/img/activities/down.png" alt="">Planning <img src="@/assets/img/activities/down.png" alt=""></button>
          </div>
          <div class="mobile-transition-header">
            <div class="custom-select">
              <select v-if="nbDays > 0" v-model="selectedDay">
                <option v-for="day in nbDays" :key="day" :value="day">Jour {{ day }}</option>
              </select>
            </div>
            <button type="button" @click="generatePlanningWithAI">
              <img src="@/assets/img/activities/ai.png" alt="ia" />Génération auto
            </button>
          </div>
          <div class="mobile-transition-content">
            <div class="activities-left-column-mobile">
              <div class="left-column-validate">
                <button class="left-column-validate-button" @click="savePlanning">Valider</button>
                <button class="left-column-cancel-button" @click="cancelPlanning">Annuler</button>
              </div>
              <!-- Same content as the activities-left-column desktop version -->
              <div class="accordion">
                <div class="accordion-item">
                  <div class="accordion-header">Matin</div>
                  <div class="accordion-content" id="morning">
                    <draggable
                      class="draggable-container morning-container"
                      :list="morningActivities"
                      group="activities"
                      handle=".drag-handle"
                      ghost-class="sortable-ghost"
                    >
                      <template #item="{ element, index }">
                        <div class="activity-wrapper" :key="element.id" @click="centerMapOnActivity(element)">
                          <div class="activity-card">
                            <button class="close-button" @click="removeActivity(selectedDay, 'morning', index)">
                              &times;
                            </button>
                            <img :src="getActivityImage(element)" alt="Activity Image" />
                            <div class="activity-details">
                              <h4>{{ element.name }}</h4>
                              <p>{{ element.description }}</p>
                              <p class="activity-time">
                                <img src="@/assets/img/activities/clock.png" alt="Clock Icon" />
                                ~{{ formatDuration(element.duration) }}
                              </p>
                            </div>
                          </div>
                          <div class="drag-handle">
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                          </div>
                        </div>
                      </template>
                    </draggable>
                    <button
                      class="add-activity-button"
                      @click="addSelectedActivity('morning')"
                      :disabled="!selectedActivity"
                    >
                      <img style="width: 20px; height: 20px" src="@/assets/img/activities/plus-icon.png" alt="" />
                      Ajouter l'activité sélectionnée
                    </button>
                  </div>
                </div>

                <div class="accordion-item">
                  <div class="accordion-header">Après-midi</div>
                  <div class="accordion-content" id="afternoon">
                    <draggable
                      class="draggable-container afternoon-container"
                      :list="afternoonActivities"
                      group="activities"
                      handle=".drag-handle"
                      ghost-class="sortable-ghost"
                    >
                      <template #item="{ element, index }">
                        <div class="activity-wrapper" :key="element.id" @click="centerMapOnActivity(element)">
                          <div class="activity-card">
                            <button class="close-button" @click="removeActivity(selectedDay, 'afternoon', index)">
                              &times;
                            </button>
                            <img :src="getActivityImage(element)" alt="Activity Image" />
                            <div class="activity-details">
                              <h4>{{ element.name }}</h4>
                              <p>{{ element.description }}</p>
                              <p class="activity-time">
                                <img src="@/assets/img/activities/clock.png" alt="Clock Icon" />
                                ~{{ formatDuration(element.duration) }}
                              </p>
                            </div>
                          </div>
                          <div class="drag-handle">
                            <div class="bar"></div>
                            <div class="bar"></div>
                            <div class="bar"></div>
                          </div>
                        </div>
                      </template>
                    </draggable>
                    <button
                      class="add-activity-button"
                      @click="addSelectedActivity('afternoon')"
                      :disabled="!selectedActivity"
                    >
                      <img style="width: 20px; height: 20px" src="@/assets/img/activities/plus-icon.png" alt="" />
                      Ajouter l'activité sélectionnée
                    </button>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>




    </div>
  </div>
</template>



<script>
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";
import draggable from "vuedraggable";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { markRaw } from "vue";
import { generateMultipleDayPlans } from '@/functions/generateDayPlan';
import { useToast } from "vue-toastification";

export default {
  name: "PlanningGenerator",
  components: {
    draggable,
  },
  props: ["activitiesDocument", "tripId"],
  data() {
    return {
      activityInformations: null,
      tripDatas: null,
      map: null,
      nbDays: 0,
      selectedDay: 1,
      selectedActivity: null,
      selectedActivityId: null,
      planning: [],
      activeTab: 'map',
      proposedActivities: [],
      isMobileTransitionOpen: false,
    };
  },
  computed: {
    morningActivities() {
      return this.planning[this.selectedDay - 1]?.morning || [];
    },
    afternoonActivities() {
      return this.planning[this.selectedDay - 1]?.afternoon || [];
    },
    isMobile() {
      console.log("Planning", this.planning);
      return window.innerWidth <= 768;
    },
    
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    toggleMobileTransition() {
      this.isMobileTransitionOpen = !this.isMobileTransitionOpen;
    },
    addActivityToPeriod(period, activity) {
      if (activity) {
        this.initializeDay(this.selectedDay);
        this.planning[this.selectedDay - 1][period].push({ ...activity, id: Date.now() });
        this.selectedActivity = null;
        this.updateMapMarkers(); 
      }
    },
    async fetchData() {
      try {
        const activityRef = doc(db, "activitiesInformations", this.activitiesDocument);
        const tripRef = doc(db, "tripdatas", this.tripId);

        const [activitySnapshot, tripSnapshot] = await Promise.all([getDoc(activityRef), getDoc(tripRef)]);

        if (activitySnapshot.exists()) {
          this.activityInformations = activitySnapshot.data();
          console.log("Activity Information:", this.activityInformations);
          this.proposedActivities = this.activityInformations.proposedActivities;
          if (this.activityInformations.planning) {
            this.planning = this.activityInformations.planning;
          } else {
            this.planning = [];
          }
        } else {
          console.error("Activity document does not exist");
        }

        if (tripSnapshot.exists()) {
          this.tripDatas = tripSnapshot.data();
          console.log("Trip Data:", this.tripDatas);
          this.calculateNbDays();
          this.initializeAllDays();
          this.initializeMap();
          this.updateMapMarkers();
        } else {
          console.error("Trip document does not exist");
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    calculateNbDays() {
      const oneDay = 24 * 60 * 60 * 1000;
      const departureDate = this.tripDatas.departureDate.toDate();
      const returnDate = this.tripDatas.returnDate.toDate();

      const isValidDate = (date) => date instanceof Date && !isNaN(date);

      if (this.tripDatas.availableDays && this.tripDatas.availableDays.numberOfDays) {
        // Si availableDays est présent, on utilise son champ numberOfDays
        this.nbDays = this.tripDatas.availableDays.numberOfDays;
      } else if (this.tripDatas.duration) {
        // Si duration est présent, on l'utilise
        this.nbDays = this.tripDatas.duration;
      } else if (isValidDate(departureDate) && isValidDate(returnDate)) {
        // Sinon, on calcule la durée en jours à partir des dates de départ et de retour
        this.nbDays = Math.round(Math.abs((returnDate - departureDate) / oneDay)) + 1;
      } else {
        // Si aucune information n'est disponible, on met nbDays à 0
        this.nbDays = 0;
      }
    },
    initializeMap() {
      if (this.map) {
        this.map.remove();
      }

      const latitude = this.tripDatas?.latitudeTo || 47.41322;
      const longitude = this.tripDatas?.longitudeTo || -1.219482;

      this.map = markRaw(L.map("map").setView([latitude, longitude], 13));

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
      }).addTo(this.map);

      this.updateMapMarkers();
    },
    switchToListView() {
      this.activeTab = 'list';
    },
    switchToMapView() {
      this.activeTab = 'map';
    },
    updateMapMarkers() {
      if (!this.map || !this.activityInformations?.proposedActivities) return;

      // Efface tous les anciens marqueurs
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.map.removeLayer(layer);
        }
      });

      const iconMap = {
        Gastronomique: require("@/assets/img/activities/gastronomie.png"),
        Loisirs: require("@/assets/img/activities/loisirs.png"),
        Balade: require("@/assets/img/activities/marche.png"),
        Repos: require("@/assets/img/activities/repos.png"),
        Culture: require("@/assets/img/activities/museum.png"),
      };

      const plannedActivities = [
        ...(this.planning[this.selectedDay - 1]?.morning || []),
        ...(this.planning[this.selectedDay - 1]?.afternoon || []),
      ];

      this.activityInformations.proposedActivities.forEach((activity) => {
        if (activity.lat && activity.lng && activity.name && activity.type) {
          const isPlanned = plannedActivities.some((planned) => planned.name === activity.name);
          const backgroundColor = isPlanned ? '#e0af5d' : '#14444c';
          const iconUrl = iconMap[activity.type] || iconMap["Repos"];

          const iconHtml = `
            <div style="background-color: ${backgroundColor}; padding: 5px; border-radius: 5px; text-align: center; width: 20px;">
              <img src="${iconUrl}" alt="${activity.type} Icon" style="width: 100%; height: auto; border-radius: 3px;" />
            </div>
          `;

          const customIcon = L.divIcon({
            className: 'custom-div-icon', // Vous pouvez ajouter des styles CSS ici si nécessaire
            html: iconHtml,
            iconSize: [50, 50], // Taille du conteneur de l'icône
            iconAnchor: [25, 50], // Point de l'icône qui sera à l'emplacement de la latitude et de la longitude
          });

          const marker = L.marker([activity.lat, activity.lng], {
            icon: customIcon,
            zIndexOffset: isPlanned ? 1000 : 0, // 1000 pour les activités planifiées (orange), 0 pour les autres
          }).addTo(this.map);

          marker.on("click", () => {
            this.selectedActivity = activity;
            this.updateMapMarkers(); // Met à jour les marqueurs pour refléter la sélection
          });
        } else {
          console.error("Invalid activity data:", activity);
        }
      });
    },

    centerMapOnActivity(activity) {
      if (this.map && activity.lat && activity.lng) {
        this.map.setView([activity.lat, activity.lng], 13); // Centre la carte sur l'activité
        this.selectedActivity = activity; // Ouvre l'info box associée
        this.selectedActivityId = activity.id; // Met à jour l'activité sélectionnée
        this.updateMapMarkers(); // Met à jour les marqueurs pour refléter la sélection
      }
    },
    getActivityImage(activity) {
      if (activity.imageUrl) {
        return activity.imageUrl;
      } else {
        const iconMap = {
          Gastronomique: require("@/assets/img/activities/gastronomie-vert.png"),
          Loisirs: require("@/assets/img/activities/loisirs-vert.png"),
          Balade: require("@/assets/img/activities/marche-vert.png"),
          Repos: require("@/assets/img/activities/repos-vert.png"),
          Culture: require("@/assets/img/activities/museum-vert.png"),
        };

        return iconMap[activity.type] || iconMap["Repos"];
      }
    },
    formatDuration(duration) {
      if (!duration) return "N/A";

      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;

      return hours > 0 ? `${hours}h${minutes > 0 ? minutes : ""}` : `${minutes}mn`;
    },
    addSelectedActivity(period) {
      if (this.selectedActivity) {
        this.initializeDay(this.selectedDay);
        this.planning[this.selectedDay - 1][period].push({ ...this.selectedActivity, id: Date.now() });
        this.selectedActivity = null;
      }
    },
    removeActivity(day, period, index) {
      this.planning[day - 1][period].splice(index, 1);
      this.updateMapMarkers();
    },
    async savePlanning() {
      const activityRef = doc(db, "activitiesInformations", this.activitiesDocument);
      const toast = useToast();

      try {
        this.initializeAllDays();

        const activitySnapshot = await getDoc(activityRef);

        const includedActivities = this.planning.reduce((acc, day) => {
          const morningActivities = day.morning.map((activity) => activity.name);
          const afternoonActivities = day.afternoon.map((activity) => activity.name);
          return acc.concat(morningActivities, afternoonActivities);
        }, []);

        if (activitySnapshot.exists()) {
          let existingPlanning = activitySnapshot.data().planning || [];

          for (let day = 1; day <= this.nbDays; day++) {
            existingPlanning[day - 1] = this.planning[day - 1];
          }

          await updateDoc(activityRef, {
            planning: existingPlanning,
            includedActivities: includedActivities,
          });
          console.log("Planning mis à jour :", this.planning);
        } else {
          let newPlanning = [];

          for (let day = 1; day <= this.nbDays; day++) {
            newPlanning[day - 1] = this.planning[day - 1];
          }

          await setDoc(activityRef, {
            planning: newPlanning,
            includedActivities: includedActivities,
          });
          console.log("Planning créé :", this.planning);
        }
        toast.success("Planning de la journée sauvegardé");
      } catch (error) {
        console.error("Erreur lors de la sauvegarde du planning :", error);
      }
    },
    async saveAndReturnToProfile() {
      const activityRef = doc(db, "activitiesInformations", this.activitiesDocument);
      const toast = useToast();

      try {
        // Boucle pour sauvegarder le planning de chaque journée
        for (let day = 1; day <= this.nbDays; day++) {
          this.selectedDay = day; // Change le jour sélectionné
          this.initializeDay(day); // Initialise la journée si nécessaire
        }

        const activitySnapshot = await getDoc(activityRef);

        const includedActivities = this.planning.reduce((acc, day) => {
          const morningActivities = day.morning.map((activity) => activity.name);
          const afternoonActivities = day.afternoon.map((activity) => activity.name);
          return acc.concat(morningActivities, afternoonActivities);
        }, []);

        if (activitySnapshot.exists()) {
          let existingPlanning = activitySnapshot.data().planning || [];

          for (let day = 1; day <= this.nbDays; day++) {
            existingPlanning[day - 1] = this.planning[day - 1];
          }

          await updateDoc(activityRef, {
            planning: existingPlanning,
            includedActivities: includedActivities,
          });
          console.log("Planning mis à jour :", this.planning);
        } else {
          let newPlanning = [];

          for (let day = 1; day <= this.nbDays; day++) {
            newPlanning[day - 1] = this.planning[day - 1];
          }

          await setDoc(activityRef, {
            planning: newPlanning,
            includedActivities: includedActivities,
          });
          console.log("Planning créé :", this.planning);
        }

        toast.success("Planning sauvegardé");

        // Rediriger vers le profil après la sauvegarde de toutes les journées
        this.$router.push({ name: 'ProfileContent' });

      } catch (error) {
        console.error("Erreur lors de la sauvegarde du planning :", error);
        toast.error("Erreur lors de la sauvegarde du planning");
      }
    },
    async cancelPlanning() {
      try {
        const activityRef = doc(db, "activitiesInformations", this.activitiesDocument);
        const activitySnapshot = await getDoc(activityRef);

        if (activitySnapshot.exists()) {
          const activityData = activitySnapshot.data();

          if (activityData.planning) {
            this.planning = activityData.planning;
            this.initializeAllDays();
          } else {
            this.planning = [];
          }
          console.log("Planning réinitialisé à l'état du document.");
        } else {
          console.error("Activity document does not exist");
        }
      } catch (error) {
        console.error("Erreur lors de la réinitialisation du planning :", error);
      }
    },
    updateActivityOrder(period, oldIndex, newIndex) {
      console.log("Old Index:", oldIndex, "New Index:", newIndex);
      console.log("Before move:", this.planning[this.selectedDay - 1][period]);

      const activities = this.planning[this.selectedDay - 1][period];

      const movedActivity = activities.splice(oldIndex, 1)[0];
      activities.splice(newIndex, 0, movedActivity);

      console.log("After move:", this.planning[this.selectedDay - 1][period]);

      this.$forceUpdate();
    },
    initializeDay(day) {
      if (!this.planning[day - 1]) {
        this.planning[day - 1] = { morning: [], afternoon: [] };
      }
      this.updateMapMarkers();
    },
    initializeAllDays() {
      for (let day = 1; day <= this.nbDays; day++) {
        this.initializeDay(day);
      }
      this.updateMapMarkers();
    },
    async generatePlanningWithAI() {
      try {
          // Gather all planned activities from all days
          const allPlannedActivities = this.planning.reduce((acc, day) => {
              return [
                  ...acc,
                  ...(day.morning || []),
                  ...(day.afternoon || [])
              ];
          }, []);

          // Filter out activities that have already been planned
          const activities = this.activityInformations.proposedActivities
              .filter((activity) => {
                  return !allPlannedActivities.some((planned) => planned.name === activity.name);
              })
              .map((activity) => ({
                  name: activity.name,
                  duration: activity.duration,
                  lat: activity.lat,
                  lng: activity.lng,
                  type: activity.type,
                  imageUrl: activity.imageUrl,
              }));

          // Generate plans if there are activities left to plan
          if (activities.length > 0) {
              const generatedPlans = await generateMultipleDayPlans(activities, 3);

              // Update the selected day with generated plans
              this.planning[this.selectedDay - 1].morning = generatedPlans[0].morning;
              this.planning[this.selectedDay - 1].afternoon = generatedPlans[0].afternoon;

              console.log("Plannings générés :", generatedPlans);
          } else {
              console.log("Toutes les activités disponibles sont déjà planifiées.");
          }
      } catch (error) {
          console.error("Erreur lors de la génération du planning avec l'IA :", error);
      }
    },

  },
  watch: {
    selectedDay(newDay) {
      this.initializeDay(newDay);
      this.updateMapMarkers(); // Met à jour les icônes lorsque le jour sélectionné change
    },
    morningActivities(newActivities) {
      this.planning[this.selectedDay - 1].morning = newActivities;
      this.updateMapMarkers(); // Met à jour les icônes lorsque les activités du matin changent
    },
    afternoonActivities(newActivities) {
      this.planning[this.selectedDay - 1].afternoon = newActivities;
      this.updateMapMarkers(); // Met à jour les icônes lorsque les activités de l'après-midi changent
    },
    // ... autres watch ...
  },
  beforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
};
</script>



<style scoped>
/* ACTIVITIES */

.activities-planning-container {
  width: 100%;
  height: auto;
  background-color: var(--light-gray);
}

.activities-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.activities-top-bloc {
  width: 95%;
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  flex-direction: row;
  align-items: center;
  height: 10vh;
}

.top-right-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  width: 50%;
}

.top-left-column {
  width: 40%;
}

.top-left-column-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

.custom-select {
  position: relative;
  display: flex;
  align-items: center;
  width: 30%;
  font-weight: 500;
  border: 1px solid rgb(68, 68, 68);
  border-radius: 10px;
  background-color: white;
  margin-top: 10px;
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 9px 20px 9px 20px;
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.custom-select::after {
  content: "▼";
  position: absolute;
  right: 30px;
  pointer-events: none;
  font-size: 12px;
  color: #333;
}

.custom-select-right {
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  font-weight: 400;
  border: 1px solid rgb(68, 68, 68);
  border-radius: 15px;
  background-color: white;
}

.custom-select-right select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
  border-radius: 20px;
  padding: 9px 20px 9px 20px;
  cursor: pointer;
}

.custom-select-right::after {
  content: "▼";
  position: absolute;
  right: 10px;
  pointer-events: none;
  font-size: 12px;
  color: #333;
}

.top-left-column-container button {
  padding: 8px 15px 8px 5px;
  border-radius: 10px;
  border: none;
  background-color: var(--jaune);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
  width: 60%;
  font-weight: 400;
  gap: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-left-column-container button img {
  width: 25px;
  height: 25px;
}

.top-left-column button:hover {
  background-color: var(--jaune);
}

.activities-type-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  width: 45%;
}

.activities-type-choice h3 {
  font-size: 1rem;
  color: var(--vert);
  margin-right: 20px;
}

.activities-main-bloc {
  display: flex;
  flex-direction: row;
}

.activities-left-column {
  width: 45%;
  padding: 20px;
}

.accordion::-webkit-scrollbar {
  width: 5px; /* Largeur de la barre de défilement */
}

.accordion::-webkit-scrollbar-thumb {
  background: #888; /* Couleur de la poignée de la barre de défilement */
  border-radius: 4px;
}

.accordion::-webkit-scrollbar-thumb:hover {
  background: #555; /* Couleur de la poignée au survol */
}

.accordion::-webkit-scrollbar-track {
  background: #f1f1f1; /* Couleur de l'arrière-plan de la barre de défilement */
}

.activities-right-column {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-gray);
  margin: 0;
  background-color: white;
  position: relative;
  height: 75vh;
  overflow: hidden;
}

.activities-right-column img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
}

label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

/* Accordion Styles */
.accordion {
  width: 100%;
  max-height: 60vh; /* Ajustez la hauteur maximale selon vos besoins */
  overflow-y: auto; /* Ajoute une barre de défilement verticale */
  overflow-x: hidden;
}

.accordion-item {
  width: 100%;
  position: relative;
}

.accordion-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0; /* Ajustez cette valeur pour déplacer la bordure horizontalement */
  width: 85%; /* Ajustez cette valeur pour la largeur de la bordure */
  border-bottom: 1px solid #ccc; /* Changez la couleur et l'épaisseur selon vos besoins */
  margin-top: 10px;
}

.accordion-header {
  color: #333;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  outline: none;
  border: none;
  width: 100%;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content {
  padding: 0px 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-content p {
  margin: 10px 0;
}

.activity-card {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px 0;
  position: relative;
  background-color: white;
  height: 100px;
  width: 80%;
  font-size: 0.7rem;
  padding-left: 3px;
  cursor: pointer;
}

.activity-card img {
  height: 95px;
  width: 95px;
  border-radius: 7px;
  margin-right: 10px;
}

.activity-details {
  flex-grow: 1;
}

.activity-details h4{
  width: 80%;
}

.activity-time {
  display: flex;
  align-items: center;
}

.activity-time img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.info-button {
  background-color: var(--jaune);
  color: white;
  border: none;
  border-radius: 7px;
  padding: 5px 10px;
  cursor: pointer;
  width: 80px;
  font-size: 1rem;
  margin-right: 20px;
  position: absolute;
  bottom: 10px;
  right: -10px;
}

.info-button:hover {
  background-color: white;
  border: 1px solid var(--jaune);
  color: var(--jaune);
}

.activity-travel {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.activity-travel img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.dotted-line {
  border-left: 2px dotted #000; /* Ligne verticale en pointillés */
  height: 30px; /* Hauteur de la ligne */
  margin: 0 20px; /* Marge autour de la ligne */
}

.add-activity-button {
  background: white;
  color: var(--jaune);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 85%;
  text-align: center;
  font-size: 0.9rem;
  margin: 20px 0;
  border: 1px solid var(--jaune);
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 1rem;
}

.add-activity-button:hover {
  background-color: var(--jaune);
  color: white;
}

.activity-wrapper {
  display: flex;
  align-items: center;
}

.left-column-validate {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0;
  gap: 20px;
}

.left-column-validate-button,
.left-column-cancel-button {
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

.left-column-validate button:hover {
  color: var(--jaune);
  background-color: white;
  border: 1px solid var(--jaune);
  transition: 0.2s;
  font-weight: 600;
}

.left-column-validate-button {
  color: white;
  background-color: var(--jaune);
  border: none;
  width: 60%;
  font-size: 1.3rem;
  padding: 10px 0;
}

.left-column-cancel-button {
  color: var(--jaune);
  background-color: white;
  border: 1px solid var(--jaune);
  width: 40%;
  font-size: 1.1rem;
  padding: 12px 0;
}

.left-column-cancel-button:hover {
  color: white;
  background-color: var(--jaune);
  border: none;
}

/* DRAG N DROP */

.activity-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Ensure the close button is positioned relative to the wrapper */
}

.activity-card {
  position: relative; /* Ensure the close button is positioned relative to the card */
}

.close-button {
  position: absolute;
  top: 2px;
  right: 5px;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: gray;
}

.close-button:hover {
  color: dark;
}

.drag-handle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: grab;
  margin-right: 25px;
}

.drag-handle .bar {
  width: 100%;
  height: 4px;
  background-color: var(--jaune);
  border-radius: 2px;
}

/* Optional: Style for the ghost element while dragging */
.sortable-ghost {
  opacity: 0.4;
  background-color: #e0e0e0;
}

/* Ensure accordion contents are always visible */
.accordion-content {
  display: block;
  max-height: none;
}



/* FIN DRAG N DROP */

/* Info box for activity details */
/* Info box for activity details */
.info-box {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 50%;
  max-width: 800px;
  text-align: center;
}

.info-box h3 {
  margin: 0 0 10px 0;
  color: var(--vert);
  font-size: 0.9rem;
}

.info-box p {
  margin: 5px 0;
  font-size: 0.8rem;
}

.add-activity-map-buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin: auto;
  justify-content: center;
}

.info-box button {
  padding: 5px 7px;
}

.info-box-p {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  gap: 20px;
}

.button-group-left {
  display: flex;

  gap: 30px;
}

/* Style pour le conteneur de la liste */
.list-container {
  overflow-y: auto;
  max-height: 100%;
  background-color: var(--light-gray);
  border-radius: 8px;
  z-index: 1001;
  width: 100%;
}

.activity-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px 0;
  width: 100%;
}

/* Style pour chaque carte d'activité */
.activity-card {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
}

.activity-card:last-child {
  margin-bottom: 0;
}

/* Style pour l'image de l'activité */
.activity-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Style pour les informations de l'activité */
.activity-info {
  flex: 1;
}

.activity-info h4 {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
}

.activity-info p {
  margin: 5px 0;
  color: #777;
}

.activity-time {
  display: flex;
  align-items: center;
  color: #888;
}

.activity-time img {
  margin-right: 5px;
}

.right-list-title {
  font-size: 0.9rem;
}

.add-buttons {
  display: flex;
  flex-direction: column;
}

.add-buttons button {
  background-color: var(--jaune);
}

.add-buttons button:hover {
  background-color: var(--vert);
}

.button-group-left .highlighted {
  background-color: var(--jaune);
}

/* FIN ACTIVITIES */

/* MOBILE DESIGN */

/* Hide activities-left-column on mobile */
@media screen and (max-width: 768px) {
  .activities-left-column {
    display: none;
  }

  .mobile-transition-div {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 85vh; /* Adjust height as needed */
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px 10px 0 0;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    z-index: 1000;
  }

  .mobile-transition-div.open {
    transform: translateY(0);
  }

  .mobile-transition-content {
    padding: 10px 20px;
    overflow-y: auto;
    height: calc(100% - 60px); /* Adjust height minus the header */
  }

  .mobile-transition-div {
    display: block;
  }

  .activities-right-column {
    width: 100%;
    height: 85vh;
    border-radius: 0;
    border: none;
    position: relative;
  }

  .top-left-column {
    display: none;
  }

  .activities-top-bloc {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .button-group-left button, .button-group-right button {
    margin-top: 0;
    padding: 10px;
    font-size: 0.7rem;
  }

  .activities-container {
    width: 100%;

  }

    /* Bouton pour ouvrir le planning */
  .open-planning-button {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Ajuster la largeur selon vos besoins */
    height: 40px; /* Ajuster la hauteur selon vos besoins */
    background-color: white;
    color: #333;
    text-align: center;
    line-height: 40px; /* Alignement vertical du texte */
    font-weight: bold;
    border-top: 1px solid #ddd;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    z-index: 1004; /* Plus élevé que le z-index du planning */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
  }


  .open-planning-button:hover {
    background-color: #f8f8f8;
  }

  /* Masquer le bouton lorsque le planning est ouvert */
  .mobile-transition-div.open + .open-planning-button {
    display: none;
  }

  .mobile-transition-header img {
    width: 20px;
    height: 20px;
  }

  .mobile-transition-header button {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0;
    padding: 10px;
  }

  .mobile-transition-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--light-gray);
    border-bottom: 1px solid #ddd;
  }
  
  .custom-select {
    margin-top: 0;
    width:  40%;
    border-radius: 50px;
  }

  .custom-select select {
    padding: 8px 20px;
  }

  .accordion-header {
    font-size: 0.9rem;
    padding-top: 0;
  }

  .accordion {
    padding-bottom: 70px;
  }

  .activities-details h4 {
    font-size: 0.8rem;
  }

  .activity-card {
    padding: 0 3px;
  }

  .activity-details h4 {
    font-size: 0.6rem;
  }

  .add-activity-button {
    font-size: 0.7rem;
    width: 95%;
  }

  .activity-time {
    font-size: 0.5rem
  }

  .accordion-item::after {
    width: 95%; /* Ajustez cette valeur pour la largeur de la bordure */
  }

    /* Boutons "Valider" et "Annuler" dans la version mobile */
  .left-column-validate {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    margin: 10px auto;;

  }

  .left-column-validate-button,
  .left-column-cancel-button {
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    width: 45%; /* Ajuster la largeur des boutons pour s'adapter à l'écran mobile */
    padding: 10px;
    font-size: 0.9rem; /* Réduire la taille du texte pour mobile */
    margin-top: 0;
    margin-bottom: 15px;
  }

  .left-column-validate-button {
    color: white;
    background-color: var(--jaune);
    border: none;

  }

  .left-column-cancel-button {
    color: var(--jaune);
    background-color: white;
    border: 1px solid var(--jaune);
  }

  .left-column-cancel-button:hover {
    color: white;
    background-color: var(--jaune);
    border: none;
  }

  .map-buttons-mobile {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1002;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0.8); /* Fond blanc transparent */
    padding: 10px;
    border-bottom-left-radius: 10px;
    width: 80%;
  }

  .map-buttons-mobile .button-group-left,
  .map-buttons-mobile .button-group-right {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .map-buttons-mobile button {
    padding: 5px 10px;
    font-size: 0.7rem;
    border-radius: 5px;
  }

  .map-buttons-mobile .button-group-right button {
    background-color: var(--jaune);
    color: white;
  }

  .map-buttons-mobile .button-group-left button {
    background-color:rgba(0, 0, 0, 0);
    border: 1px solid var(--gray);
    color: #333;
  }

  .map-buttons-mobile .button-group-left button.highlighted {
    background-color: var(--jaune);
    border: none;
    color: white;
  }
  .activities-main-bloc-mobile {
    z-index: 1003;
  }

  .activity-list .activity-card {
    width: 90%;
  }

  .activity-list .activity-card button {
    margin: 0;
    padding: 5px 8px;
    font-size: 0.7rem;
    border-radius: 10px;
  }

  .add-buttons {
    gap: 10px;
    margin-right: 10px;
  }

  .activity-list .activity-card h4 {
    font-size: 0.6rem;
    width: 80%;
  }

  .activity-list {
    margin-top: 80px;
  }

  .list-container {
    background-color: var(--light-gray);
  }

  .activities-planning-container {
    padding-bottom: 50px;
  }

  .add-activity-map-buttons button {
    font-size: 0.7rem;
    margin-top: 10px;
    padding: 10px;
  }

  .info-box {
    padding: 10px 10px 30px 10px;
    width: 100%;
  }

  .activity-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .top-planning-button {
    text-align: center;
    background-color: var(--light-gray);
  }
  .close-planning-button {
    padding: 0;
    background-color: unset;
    color: black;
    margin: 0px auto;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 20px;
  }
  .close-planning-button img {
    width: 20px;
    height: 20px;
  }

  .open-planning-button img {
    width: 20px;
    height: 20px;
  }
}



/* FIN MOBILE DESIGN */

</style>
