function availableTripDays(tripdatas) {
    let departureLanding, returnTakeOff;
    let firstDay, firstDayMoment;
    let lastDay, lastDayMoment;

    if (tripdatas.selectedFlight) {
        // Cas où selectedFlight est disponible
        departureLanding = new Date(tripdatas.selectedFlight.date_departure_landing);
        returnTakeOff = new Date(tripdatas.selectedFlight.date_return_take_off);
    } else if (tripdatas.selectedGroundTransportation) {
        // Cas où selectedGroundTransportation est disponible
        departureLanding = new Date(tripdatas.selectedGroundTransportation.outboundTrip.arrivalLocalISO);
        returnTakeOff = new Date(tripdatas.selectedGroundTransportation.outboundTrip.departureLocalISO);
    } else if (tripdatas.departureDate && tripdatas.returnDate) {
        // Cas où ni selectedFlight ni selectedGroundTransportation n'est disponible
        departureLanding = new Date(tripdatas.departureDate.toDate());
        returnTakeOff = new Date(tripdatas.returnDate.toDate());
        
        // Utiliser les dates pour générer les firstDay et lastDay
        firstDay = departureLanding.toISOString().split('T')[0];
        firstDayMoment = "morning";
        lastDay = returnTakeOff.toISOString().split('T')[0];
        lastDayMoment = "afternoon";

        // Calcul du nombre de jours entre le premier et le dernier jour
        const diffTime = Math.abs(returnTakeOff - departureLanding);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

        return {
            numberOfDays: diffDays,
            firstDay: { date: firstDay, moment: firstDayMoment },
            lastDay: { date: lastDay, moment: lastDayMoment }
        };
    } else {
        throw new Error("Aucune information de transport ou de date disponible");
    }

    // Gestion du premier jour
    if (!firstDay) {
        if (departureLanding.getHours() < 13) {
            firstDay = departureLanding.toISOString().split('T')[0];
            firstDayMoment = "afternoon";
        } else {
            const nextDay = new Date(departureLanding);
            nextDay.setDate(nextDay.getDate() + 1);
            firstDay = nextDay.toISOString().split('T')[0];
            firstDayMoment = "morning";
        }
    }

    // Gestion du dernier jour
    if (!lastDay) {
        if (returnTakeOff.getHours() < 18) {
            lastDay = returnTakeOff.toISOString().split('T')[0];
            lastDayMoment = "morning";
        } else {
            const previousDay = new Date(returnTakeOff);
            previousDay.setDate(previousDay.getDate() - 1);
            lastDay = previousDay.toISOString().split('T')[0];
            lastDayMoment = "afternoon";
        }
    }

    // Calcul du nombre de jours entre le premier et le dernier jour
    const firstDayDate = new Date(firstDay);
    const lastDayDate = new Date(lastDay);
    const diffTime = Math.abs(lastDayDate - firstDayDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

    return {
        numberOfDays: diffDays,
        firstDay: { date: firstDay, moment: firstDayMoment },
        lastDay: { date: lastDay, moment: lastDayMoment }
    };
}

module.exports = { availableTripDays };
