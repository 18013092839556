<template>
  <div class="legal-mentions">
    <div class="header">
      <img src="@/assets/img/logo.png" alt="logo">
    </div>
    <div class="container">
      <h1>Mentions légales</h1>
      <p>En vigueur au 01/07/2024</p>

      <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la
        Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et
        visiteurs, ci-après l’"Utilisateur", du site https://alego.app , ci-après le "Site", les présentes mentions
        légales.</p>

      <p>La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans
        réserve des présentes mentions légales.</p>

      <p>Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».</p>

      <h2>ARTICLE 1 - L'EDITEUR</h2>
      <p>L'édition du Site est assurée par Alego SAS au capital de 1000 euros, immatriculée au Registre du
        Commerce et des Sociétés de Lille sous le numéro 930219126 dont le siège social est situé au 65
        rue moulin becquet 59000 Lille,
        Adresse e-mail : contact@alego.app.
        Le Directeur de la publication est Alego
        ci-après l'"Editeur".</p>

      <h2>ARTICLE 2 - L'HEBERGEUR</h2>
      <p>L'hébergeur du Site est la société :</p>
      <p>FIREBASE HOSTING par GOOGLE, dont le siège social est situé au Google LLC, 1600 Amphitheatre
        Parkway, Mountain View, California 94043 USA</p>
      <p>Mail : support@firebase.google.com</p>
      <p>Téléphone : +1 650 253 0000</p>

      <h2>ARTICLE 3 - ACCES AU SITE</h2>
      <p>Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption
        programmée ou non et pouvant découlant d’une nécessité de maintenance.</p>
      <p>En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.</p>

      <h2>ARTICLE 4 - COLLECTE DES DONNEES</h2>
      <p>Le Site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect
        de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers
        et aux libertés.</p>
      <p>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit
        d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur
        exerce ce droit :
        · par mail à l'adresse email contact@alego.app</p>
      <p>Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site,
        sans autorisation de l’Editeur est prohibée et pourra entraîner des actions et poursuites judiciaires
        telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</p>
    </div>
  </div>
</template>

<style scoped>
.legal-mentions {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: white;
  color: #333;
  position: absolute;
  top: 0;
  z-index: 1000000;
}
.header {
  background-color: #14444c;
  color: white;
  padding: 20px;
  text-align: center;
}
.header img {
  height: 150px;
  width: auto;
}
.container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}
h1 {
  font-size: 2.8em;
  margin-bottom: 40px;
  text-align: center;
}
h2 {
  font-size: 2em;
  margin-top: 60px;
  margin-bottom: 20px;
}
p, ul {
  font-size: 1em;
  margin-bottom: 20px;
}
ul {
  padding-left: 20px;
}
</style>
