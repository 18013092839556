<template>
  <div class="auth-overlay" @click.self="close">
    <div class="auth-container">
      <div class="auth-card">
        <button class="close-button" @click="close">X</button>
        <h2>{{ isLogin ? 'Connexion' : 'Inscription' }}</h2>
        <hr />
        <h3 class="welcome-message">Bienvenue !</h3>
        <p class="already-account">
          {{ isLogin ? "Tu n'as pas de compte ?" : "Tu as déjà un compte ?" }}
          <a href="#" @click.prevent="toggleAuth">{{ isLogin ? 'Inscription' : 'Connexion' }}</a>
        </p>
        <form @submit.prevent="isLogin ? loginWithEmail() : checkUserAndProceed()">
          <input v-if="!isLogin" v-model="firstName" type="text" placeholder="Prénom" required />
          <input v-if="!isLogin" v-model="lastName" type="text" placeholder="Nom" required />
          <input v-model="email" type="email" placeholder="E-mail" required />
          <input v-model="password" type="password" placeholder="Mot de passe" required />
          <a v-if="isLogin" class="forgot-pass" href="#" @click="forgotPassword">Mot de passe oublié ?</a>
          <button type="submit">{{ isLogin ? 'Valider' : 'S\'inscrire' }}</button>
        </form>

        <div class="divider" v-if="!isLoggedIn">
          <span>{{ isLogin ? 'Connexion avec' : 'Inscription avec' }}</span>
        </div>
        <div class="social-login-buttons" v-if="!isLoggedIn">
          <button @click="loginWithGoogle"><img src="@/assets/img/login/google.png" alt="Google" /></button>
          <!--<button @click="loginWithFacebook"><img src="@/assets/img/login/facebook.png" alt="Facebook" /></button>-->
        </div>
        <p v-if="message" :class="{'success-message': success, 'error-message': !success}">{{ message }}</p>
        <button v-if="isLoggedIn" @click="logout" class="logout-button">Déconnexion</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, signOut, fetchSignInMethodsForEmail, sendPasswordResetEmail } from 'firebase/auth';
import { upsertUser, checkUserExists } from '@/services/firestoreService';
import { useToast } from 'vue-toastification';

export default {
  name: 'UserAuth',
  data() {
    return {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      auth: null,
      googleProvider: null,
      facebookProvider: null,
      message: '',
      success: false,
      isLogin: true,
      isLoggedIn: false
    };
  },
  created() {
    this.auth = getAuth();
    this.googleProvider = new GoogleAuthProvider();
    this.facebookProvider = new FacebookAuthProvider();
    this.toast = useToast();

    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.isLoggedIn = true;
        await upsertUser(user);
        this.close();
      } else {
        this.isLoggedIn = false;
      }
    });
  },
  methods: {
    toggleAuth() {
      this.isLogin = !this.isLogin;
    },
    async checkUserAndProceed() {
      const user = await checkUserExists(this.email);
      if (user) {
        if (user.providerData[0] === 'google.com') {
          this.message = 'Votre adresse email est liée à Google, connectez-vous via Google';
          this.success = false;
        } else {
          await this.registerWithEmail();
        }
      } else {
        await this.registerWithEmail();
      }
    },
    async forgotPassword() {
      if (!this.email) {
        this.message = "Veuillez entrer votre adresse e-mail.";
        this.success = false;
        this.toast.error(this.message);
        return;
      }

      try {
        await sendPasswordResetEmail(this.auth, this.email);
        this.success = true;
        this.message = "Un e-mail de réinitialisation a été envoyé à votre adresse.";
        this.toast.success(this.message);
      } catch (error) {
        this.success = false;
        this.message = this.translateErrorMessage(error.code);
        this.toast.error(this.message);
        console.error("Erreur lors de la réinitialisation du mot de passe:", error);
      }
    },
    async loginWithEmail() {
      try {
        const userCredential = await signInWithEmailAndPassword(this.auth, this.email, this.password);
        await upsertUser(userCredential.user);
        this.success = true;
        this.message = 'Connexion réussie';
        this.toast.success(this.message);  // Affichage de la notification de succès
        this.close();
      } catch (error) {
        this.success = false;
        this.message = this.translateErrorMessage(error.code);
        this.toast.error(this.message);  // Affichage de la notification d'échec
        console.error("Erreur de connexion:", error);
      }
    },
    async registerWithEmail() {
      try {
        const userCredential = await createUserWithEmailAndPassword(this.auth, this.email, this.password);
        const newUser = userCredential.user;
        newUser.displayName = `${this.firstName} ${this.lastName}`;

        // Créer un document utilisateur dans la collection 'users'
        await upsertUser(newUser, this.firstName, this.lastName);

        this.success = true;
        this.message = 'Inscription réussie';
        this.toast.success(this.message);
        this.close();
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          await this.handleEmailAlreadyInUse(this.email);
        } else {
          this.success = false;
          this.message = this.translateErrorMessage(error.code);
          this.toast.error(this.message);
        }
      }
    },

    async handleEmailAlreadyInUse(email) {
      try {
        const methods = await fetchSignInMethodsForEmail(this.auth, email);
        if (methods.includes('google.com')) {
          this.message = 'Votre adresse email est liée à Google, connectez-vous via Google';
        } else if (methods.includes('facebook.com')) {
          this.message = 'Votre adresse email est liée à Facebook, connectez-vous via Facebook';
        } else {
          this.message = 'L\'e-mail est déjà utilisé';
        }
        this.success = false;
        this.toast.error(this.message);  // Affichage de la notification d'échec
      } catch (error) {
        this.success = false;
        this.message = "Une erreur est survenue lors de la vérification de l'email.";
        this.toast.error(this.message);  // Affichage de la notification d'échec
        console.error("Erreur lors de la vérification de l'email:", error);
      }
    },
    async loginWithGoogle() {
      try {
        const userCredential = await signInWithPopup(this.auth, this.googleProvider);
        await upsertUser(userCredential.user);
        this.success = true;
        this.message = 'Connexion avec Google réussie';
        this.toast.success(this.message);  // Affichage de la notification de succès
        this.close();
      } catch (error) {
        this.success = false;
        this.message = this.translateErrorMessage(error.code);
        this.toast.error(this.message);  // Affichage de la notification d'échec
        console.error("Erreur de connexion avec Google:", error);
      }
    },
    async loginWithFacebook() {
      try {
        const userCredential = await signInWithPopup(this.auth, this.facebookProvider);
        await upsertUser(userCredential.user);
        this.success = true;
        this.message = 'Connexion avec Facebook réussie';
        this.toast.success(this.message);  // Affichage de la notification de succès
        this.close();
      } catch (error) {
        this.success = false;
        this.message = this.translateErrorMessage(error.code);
        this.toast.error(this.message);  // Affichage de la notification d'échec
        console.error("Erreur de connexion avec Facebook:", error);
      }
    },
    async logout() {
      try {
        await signOut(this.auth);
        this.success = true;
        this.message = 'Déconnexion réussie';
        this.toast.success(this.message);  // Affichage de la notification de succès
        this.isLoggedIn = false;
        this.close(); // Ferme la fenêtre modale
        this.$router.push({ name: 'Accueil' }); // Redirige vers la page d'accueil
      } catch (error) {
        this.success = false;
        this.message = this.translateErrorMessage(error.code);
        this.toast.error(this.message);  // Affichage de la notification d'échec
        console.error("Erreur de déconnexion:", error);
      }
    },
    
    translateErrorMessage(errorCode) {
      const errorTranslations = {
        'auth/user-not-found': "L'email ou le mot de passe est incorrect",
        'auth/wrong-password': "L'email ou le mot de passe est incorrect",
        'auth/email-already-in-use': "L'e-mail est déjà utilisé",
        'auth/invalid-email': "E-mail invalide",
        'auth/weak-password': "Le mot de passe est trop faible",
        'auth/invalid-credential': "L'email ou le mot de passe est incorrect"
        // Ajoutez d'autres traductions ici
      };

      return errorTranslations[errorCode] || "Une erreur est survenue";
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>



<style scoped>

.auth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond grisé */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000000;
}

.auth-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 50px;
  position: relative;
  color: var(--dark-gray);
}

.auth-card {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.auth-card .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--dark-gray);
}

.auth-card h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.auth-card h3 {
  font-size: 1.1rem;
}

.auth-card hr {
  border: 0;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.auth-card p {
  margin: 20px 0;
  text-align: left;
}

.auth-card a {
  margin-left: 20px;
  font-weight: bold;
}

.auth-card form {
  display: flex;
  flex-direction: column;
}

.auth-card input {
  margin-bottom: 10px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 0.9rem;
}

.auth-card button {
  padding: 10px;
  border: none;
  border-radius: 25px;
  background-color: var(--jaune);
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: 600;
}

.auth-card .forgot-pass {
  color: var(--dark-gray);
  font-weight: bold;
  font-size: 0.9rem;
  text-align: right;
  margin: 10px 0 15px 0;
}

.welcome-message {
  text-align: left;
  font-weight: bold;
  font-size: 25px;
}

.already-account {
  text-align: left;
  font-size: 1rem;
}

.already-account a {
  text-decoration: none;
  color: var(--dark-gray);
}

.divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 1.2rem;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.divider:not(:empty)::before {
  margin-right: 1em;
}

.divider:not(:empty)::after {
  margin-left: 1em;
}

.social-login-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-login-buttons button {
  border: none;
  background: none;
  cursor: pointer;
}

.social-login-buttons img {
  width: 40px;
  height: 40px;
}

.success-message {
  color: green;
  font-size: 1rem;
}

.error-message {
  color: red;
  font-size: 1rem;
}

@media (max-width: 768px) {

  .auth-overlay {
    width: 100vw;
  }
  .auth-container {
    padding: 20px;
    position: sticky;
    top: 20px;
  }
  
  .auth-card {
    padding: 0px;
  }

  .auth-card input {
    padding: 10px;
    font-size: 0.8rem;
  }

  .auth-card button {
    padding: 10px;
    font-size: 1rem;
  }

  .auth-card .close-button {
    font-size: 1rem;
  }

  .welcome-message {
    font-size: 1.5rem;
  }

  .already-account {
    font-size: 0.9rem;
  }

  .divider {
    font-size: 1rem;
  }

  .social-login-buttons img {
    width: 35px;
    height: 35px;
  }
}



</style>
