<template>
  <div>
    <SeparatorLine />

    <ActivitiesQuestions 
      v-if="currentStep === 'questions'"
      :tripId="tripId"
      :activitiesDocument="localActivitiesDocument"
      @update-step="updateStep"
      @handleActivityDocument="handleActivityDocument"
    />

    <PlanningGenerator 
      v-if="currentStep === 'planningGenerator'"
      :tripId="tripId"
      :activitiesDocument="localActivitiesDocument" 
    />
  </div>
</template>

<script>
import ActivitiesQuestions from '../components/ActivitiesComponents/ActivitiesQuestions.vue';
import PlanningGenerator from '../components/ActivitiesComponents/PlanningGenerator.vue';
import SeparatorLine from '../components/SeparatorLine.vue';

export default {
  name: "ActivitiesPage",
  components: {
    SeparatorLine,
    ActivitiesQuestions,
    PlanningGenerator
  },
  data() {
    return {
      localActivitiesDocument: this.activitiesDocument || '',
      currentStep: "questions"
    };
  },
  props: ['tripId', 'activitiesDocument'],
  created() {
    console.log("Trip ID:", this.tripId); // Ensure tripId is received
    console.log("Activities Document ID:", this.activitiesDocument); // Ensure activitiesDocument is received
  },
  watch: {
    activitiesDocument(newVal) {
      this.localActivitiesDocument = newVal;
    }
  },
  methods: {
    updateStep(step) {
      console.log("Mise à jour de l'étape vers:", step);
      this.currentStep = step;
      console.log("Current Step:", this.currentStep);
    },
    handleActivityDocument(newDocId) {
      console.log("Document de l'activité :", newDocId);
      this.localActivitiesDocument = newDocId;
      console.log("Local Activities Document:", this.localActivitiesDocument);
    }
  },
};
</script>

<style scoped>
/* Styles */
</style>
