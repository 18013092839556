<template>
  <div>
    <SiteHeaderLanding :isScrolled="isScrolled" :isScrolledStart="isScrolledStart" />
    <div ref="contentContainer" @scroll="handleScroll">
      <div ref="step1">
        <AccueilStep1 />
      </div>
      <AccueilStep2 />
      <AccueilStep3 />
      <AccueilStep4 />
      <AccueilStep5 />
    </div>
  </div>
</template>

<script>
import SiteHeaderLanding from '@/components/SiteHeaderLanding.vue';
import AccueilStep1 from '@/components/AccueilStep1.vue';
import AccueilStep2 from '@/components/AccueilStep2.vue';
import AccueilStep3 from '@/components/AccueilStep3.vue';
import AccueilStep4 from '@/components/AccueilStep4.vue';
import AccueilStep5 from '@/components/AccueilStep5.vue';

export default {
  components: {
    SiteHeaderLanding,
    AccueilStep1,
    AccueilStep2,
    AccueilStep3,
    AccueilStep4,
    AccueilStep5
  },
  data() {
    return {
      isScrolled: false,
      isScrolledStart: false,
      step1Height: 0
    };
  },
  mounted() {
    this.step1Height = this.$refs.step1.offsetHeight;
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollY = window.scrollY;
      this.isScrolledStart = scrollY > 0 && scrollY <= (this.step1Height -180);
      this.isScrolled = scrollY > this.step1Height - 180;
    }

  }
}
</script>

<style scoped>

</style>


