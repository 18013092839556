<template>
  <div style="background-color: var(--light-gray); padding-bottom: 200px; margin-bottom: -200px">
    <SeparatorLine />
    <div class="modifyHotel-container">
      <HotelMain
      v-if="currentStep === 'selectHotels'" 
      :docRef="tripId" 
      :fromProfile="true" 
      @update-step="updateStep"
      @displayHotelDetails="displayHotelDetails"
      />
      <HotelDetails
      v-else-if="currentStep === 'selectHotelDetails'" 
      :docRef="tripId"
      :hotelRef="hotelRef"
      :fromProfile="true" 
      @update-step="updateStep"
      />
    </div>
  </div>
</template>

<script>
import SeparatorLine from '@/components/SeparatorLine.vue'; 
import HotelMain from '@/components/HotelMain.vue'; 
import HotelDetails from '@/components/HotelDetails.vue'; 

export default {
  components: {
    HotelMain,
    HotelDetails,
    SeparatorLine 
  },
  props: {
    tripId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentStep: "selectHotels",
      hotelRef:""
    };
  },
  methods: {
    updateStep(step){
      this.currentStep = step;
    },
    displayHotelDetails(hotelRef) {
      console.log("Hotel Ref avant affectation : ", hotelRef);
      if (hotelRef) {
        this.hotelRef = hotelRef;
        console.log("Hotel Ref : ", this.hotelRef);
        console.log("Doc Ref avant changement de page : ", this.docRef);
      } else {
        console.log("hotelRef est manquant");
      }
    },
  }
}
</script>

<style scoped>

.modifyHotel-container {
  width: 100%;
  margin: 0vh auto;
}

@media (max-width: 768px) {
  .modifyHotel-container {
    margin: 0 auto;
  }
}

</style>