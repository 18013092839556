<template>
  <div>
    <h2>Bientôt disponible ...</h2>
    <p>Tu pourras bientôt créer ton profil de voyageur. Cela permettra de guider Alego pour te proposer des destinations davantage personnalisées !</p>
  </div>
</template>

<script>
export default {
  name: 'UserTravelerProfile'
};
</script>
