// src/functions/generateDayPlan.js

const haversine = require('haversine-distance');

/**
 * Calculer la matrice des distances entre chaque activité.
 * @param {Array} activities - Liste des activités avec coordonnées.
 * @returns {Array} - Matrice 2D des distances.
 */
function calculateDistanceMatrix(activities) {
    const matrix = [];
    for (let i = 0; i < activities.length; i++) {
        matrix[i] = [];
        for (let j = 0; j < activities.length; j++) {
            if (i === j) {
                matrix[i][j] = 0;
            } else {
                const pointA = { lat: activities[i].lat, lon: activities[i].lng };
                const pointB = { lat: activities[j].lat, lon: activities[j].lng };
                matrix[i][j] = haversine(pointA, pointB);
            }
        }
    }
    return matrix;
}

/**
 * Estimer le temps de déplacement basé sur la distance.
 * @param {number} distance - Distance entre deux points en mètres.
 * @returns {number} - Temps estimé en minutes.
 */
function estimateTravelTime(distance) {
    return distance / 30.33;
}

/**
 * Calculer les distances entre les activités consécutives.
 * @param {Array} activities - Liste des activités triées.
 * @returns {Array} - Liste des distances entre activités consécutives.
 */
function calculateConsecutiveDistances(activities) {
    const distances = [];
    for (let i = 0; i < activities.length - 1; i++) {
        const pointA = { lat: activities[i].lat, lon: activities[i].lng };
        const pointB = { lat: activities[i + 1].lat, lon: activities[i + 1].lng };
        distances.push(haversine(pointA, pointB));
    }
    return distances;
}

/**
 * Répartir les activités entre matinée et après-midi en respectant les contraintes de durée.
 * @param {Array} activities - Liste des activités triées.
 * @param {Array} distances - Liste des distances entre les activités.
 * @returns {Object} - Objets contenant les activités pour la matinée et l'après-midi.
 */
function splitActivitiesByTimeWithConstraints(activities, distances) {
    const morning = [];
    const afternoon = [];
    let morningDuration = 0;
    let afternoonDuration = 0;

    // Maximum durations for morning and afternoon
    const maxMorningDuration = 140; 
    const maxAfternoonDuration = 240; 

    for (let i = 0; i < activities.length; i++) {
        const activity = activities[i];
        const travelTime = i > 0 ? estimateTravelTime(distances[i - 1]) : 0;

        if (morningDuration + activity.duration + travelTime <= maxMorningDuration) {
            morning.push(activity);
            morningDuration += activity.duration + travelTime;
        } else if (afternoonDuration + activity.duration + travelTime <= maxAfternoonDuration) {
            afternoon.push(activity);
            afternoonDuration += activity.duration + travelTime;
        }
    }

    return { morning, afternoon };
}


/**
 * Équilibrer les types d'activités pour chaque demi-journée.
 * @param {Array} activities - Liste des activités pour une demi-journée.
 * @returns {Array} - Liste d'activités équilibrée par type.
 */
function balanceActivityTypes(activities) {
    const types = { 'Loisirs sportifs': 0, 'Balade': 0, 'Culture': 0, 'Reposante': 0 };
    activities.forEach(activity => types[activity.type]++);
    return activities.sort((a, b) => types[a.type] - types[b.type]);
}

/**
 * Génère un planning optimisé pour une journée à partir des activités proposées.
 * @param {Array} activities - Liste des activités avec nom, durée, localisation, et type.
 * @returns {Object} - Planning de la journée optimisé avec répartition matin/après-midi et les distances.
 */
function generateDayPlan(activities) {
    const distanceMatrix = calculateDistanceMatrix(activities);
    const sortedActivities = activities.sort((a, b) => {
        const indexA = activities.indexOf(a);
        const indexB = activities.indexOf(b);
        return distanceMatrix[0][indexA] - distanceMatrix[0][indexB];
    });

    const distances = calculateConsecutiveDistances(sortedActivities);
    const { morning, afternoon } = splitActivitiesByTimeWithConstraints(sortedActivities, distances);
    const balancedMorning = balanceActivityTypes(morning);
    const balancedAfternoon = balanceActivityTypes(afternoon);

    return {
        morning: balancedMorning,
        afternoon: balancedAfternoon,
        distances: {
            morning: calculateConsecutiveDistances(balancedMorning),
            afternoon: calculateConsecutiveDistances(balancedAfternoon)
        }
    };
}

/**
 * Génère plusieurs plannings optimisés pour une journée à partir des activités proposées.
 * @param {Array} activities - Liste des activités avec nom, durée, localisation, et type.
 * @param {number} numberOfPlans - Nombre de plannings à générer.
 * @returns {Array} - Liste de plannings pour la journée.
 */
async function generateMultipleDayPlans(activities, numberOfPlans = 3) {
    console.time('generateMultipleDayPlans');
    const plans = [];

    for (let i = 0; i < numberOfPlans; i++) {
        const shuffledActivities = [...activities].sort(() => 0.5 - Math.random());
        const plan = generateDayPlan(shuffledActivities);
        plans.push(plan);
    }

    console.timeEnd('generateMultipleDayPlans');
    return plans;
}

module.exports = { generateMultipleDayPlans };
