<!-- src/views/VoyagePage.vue -->

<template>
  <div class="voyage-container">
    <ChatComponent />
  </div>
</template>


<script>
import ChatComponent from '../components/ChatComponent.vue';

export default {
  components: { ChatComponent },
  name: 'VoyagePage'
};
</script>

<style scoped>
.voyage-container {
  background-color: var(--light-gray);
  min-height: 87vh;
}

@media (max-width: 768px) {
  .voyage-container {
    min-height: 70vh;
  }
}  
</style>
