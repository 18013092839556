<template>
  <div>
    <SeparatorLine />
    <PlanningGenerator 
      v-if="currentStep === 'planningGenerator'"
      :tripId="tripId"
      :activitiesDocument="activitiesDocument" 
    />
  </div>
</template>

<script>
import PlanningGenerator from '../components/ActivitiesComponents/PlanningGenerator.vue';
import SeparatorLine from '../components/SeparatorLine.vue';

export default {
  name: "ModifyActivities",
  components: {
    SeparatorLine,
    PlanningGenerator
  },
  props: {
    tripId: {
      type: String,
      required: true
    },
    activitiesDocument: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentStep: "planningGenerator"
    };
  },
  created() {
    console.log("Trip ID:", this.tripId); // Ensure tripId is received
    console.log("Activities Document ID:", this.activitiesDocument); // Ensure activitiesDocument is received
  },
  methods: {
    updateStep(step) {
      console.log("Mise à jour de l'étape vers:", step);
      this.currentStep = step;
      console.log("Current Step:", this.currentStep);
    }
  }
};
</script>
