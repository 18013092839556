<template>
  <div v-if="isVisible" class="overlay">
    <div class="overlay-content">
      <button class="close-button" @click="closeOverlay">×</button>
      <h2 class="overlay-title">FAIRE UN RETOUR</h2>
      <p class="overlay-subtitle">
        <strong style="color: var(--jaune)">Ton avis compte !</strong> Aides-nous à améliorer notre plateforme en partageant tes retours et suggestions. Merci de ta contribution pour rendre ton expérience de planification de voyage encore meilleure !
      </p>
      <hr class="divider" />
      <form @submit.prevent="submitFeedback">
        <div class="form-group">
          <label for="feedback-type">Type de retour <span class="required">*</span> :</label>
          <div class="button-group">
            <button
              type="button"
              class="feedback-button"
              :class="{ selected: feedbackType === 'Expérience utilisateur' }"
              @click="feedbackType = 'Expérience utilisateur'"
            >
              Expérience utilisateur
            </button>
            <button
              type="button"
              class="feedback-button"
              :class="{ selected: feedbackType === 'Amélioration' }"
              @click="feedbackType = 'Amélioration'"
            >
              Amélioration
            </button>
            <button
              type="button"
              class="feedback-button"
              :class="{ selected: feedbackType === 'Bug technique' }"
              @click="feedbackType = 'Bug technique'"
            >
              Bug technique
            </button>
          </div>
        </div>
        <div class="form-group">
          <label for="message">Message <span class="required">*</span> :</label>
          <textarea id="message" v-model="message" placeholder="Description du retour"></textarea>
        </div>
        <div class="form-group">
          <label for="upload">Charger une image :</label>
          <div
            class="upload-area"
            @dragover.prevent
            @dragenter.prevent
            @drop.prevent="handleDrop"
            @click="triggerFileSelect"
          >
            <span v-if="!imageFileName" class="upload-icon">📤</span>
            <span v-else class="file-name">{{ imageFileName }}</span>
            <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" />
          </div>
        </div>
        <div class="require-submit">
          <p class="required-note">*: champs obligatoires</p>
          <button type="submit" class="submit-button">Envoyer</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { db, storage } from "@/firebase";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useToast } from "vue-toastification";

export default {
  name: "OverlayFeedback",
  data() {
    return {
      feedbackType: "",
      message: "",
      imageFile: null,
      imageFileName: "", // Add a variable to store the file name
    };
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeOverlay() {
      this.$emit("close");
    },
    async submitFeedback() {
      const toast = useToast();
      try {
        let imageUrl = null;
        if (this.imageFile) {
          imageUrl = await this.uploadImage(this.imageFile);
        }

        await addDoc(collection(db, "feedbacks"), {
          feedbackType: this.feedbackType,
          message: this.message,
          imageUrl: imageUrl,
          timestamp: new Date(),
        });

        toast.success("Feedback soumis avec succès !");
        this.closeOverlay();
      } catch (error) {
        console.error("Erreur lors de la soumission du feedback:", error);
        toast.error("Une erreur est survenue lors de la soumission de votre feedback.");
      }
    },
    async uploadImage(file) {
      const storageRef = ref(storage, `feedback-images/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      return await getDownloadURL(snapshot.ref);
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        this.imageFile = file;
        this.imageFileName = file.name; // Set the file name to be displayed
      } else {
        const toast = useToast();
        toast.error("Veuillez télécharger un fichier image valide.");
        this.imageFile = null;
        this.imageFileName = ""; // Clear the file name if it's not valid
      }
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith("image/")) {
        this.imageFile = file;
        this.imageFileName = file.name; // Set the file name to be displayed
      } else {
        const toast = useToast();
        toast.error("Veuillez déposer un fichier image valide.");
        this.imageFile = null;
        this.imageFileName = ""; // Clear the file name if it's not valid
      }
    },
    triggerFileSelect() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999000;
}

.form-group label {
  font-size: 0.9rem;
  text-align: left;
  display: block;
}

.overlay-content {
  background-color: white;
  padding: 10px 50px 30px 50px;
  border-radius: 10px;
  max-width: 40%;
  width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  position: relative;
  color: var(--dark-gray);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--dark-gray);
}

.overlay-title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.overlay-subtitle {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 20px;
  color: #666;
}

.divider {
  margin: 30px auto 20px auto;
  border: none;
  height: 1px;
  background-color: var(--dark-gray);
  width: 40%;
}

.form-group {
  margin-bottom: 15px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.feedback-button {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
  color: var(--dark-gray);
  cursor: pointer;
  font-size: 0.8rem;
}

.feedback-button.selected {
  background-color: var(--jaune);
  color: white;
  border-color: var(--jaune);
}

.feedback-button:hover {
  background-color: #e0e0e0;
}

textarea {
  width: 97%;
  max-width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  margin-top: 10px;
  height: 100px;
}

.upload-area {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;  
  position: relative;
  text-align: center;
}

.upload-icon {
  font-size: 1.5rem;
}

.file-name {
  font-size: 0.9rem;
  color: var(--dark-gray);
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.submit-button {
  padding: 15px 60px;
  background-color: var(--jaune);
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 0;
  align-self: flex-end;
}

.submit-button:hover {
  background-color: var(--vert);
}

.required {
  color: red;
}

.required-note {
  font-size: 0.8rem;
  color: red;
  text-align: left;
  margin-top: 10px;
}

.require-submit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
  .overlay-content {
    max-width: 60%;
    padding: 20px;
    height: 100vh;
  }
  .close-button {
    padding: 0;
    margin-top: 10px;
  }
  .overlay-subtitle {
    font-size: 0.8rem;
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-group label {
    font-size: 0.8rem;
    margin-top: 10px;
  }
  .feedback-button {
    margin-top: 10px;
  }
  .overlay-title h2 {
    margin-top: 20px;
  }
  textarea {
    width: 94%;
  }
}

@media screen and (max-width: 768px) {
  .overlay-content {
    max-width: 80%;
    padding: 20px;
  }

  .feedback-button {
    font-size: 0.7rem;
    padding: 8px;
  }

  .overlay-title {
    font-size: 1.2rem;
  }

  .overlay-subtitle {
    font-size: 0.7rem;
  }

  .divider {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .overlay-content {
    max-width: 95%;
    padding: 10px 15px 20px 15px;
  }

  .feedback-button {
    font-size: 0.6rem;
    padding: 5px;
  }

  .overlay-title {
    font-size: 1rem;
  }

  .overlay-subtitle {
    font-size: 0.6rem;
  }

  .submit-button {
    padding: 10px 40px;
    font-size: 0.9rem;
  }
}
</style>
