import { getFirestore, doc, getDoc } from "firebase/firestore";
import { format } from 'date-fns';

export default async function generateHTMLContentPlanning(tripData) {
    const db = getFirestore();

    // Vérifiez si le champ activitiesDocumentId est présent
    if (!tripData.activitiesDocumentId) {
        return ''; // Retourner une chaîne vide si le champ est absent
    }
    
    // Charger le document depuis la collection `activitiesInformations`
    const docRef = doc(db, "activitiesInformations", tripData.activitiesDocumentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const activitiesData = docSnap.data();

        // Générer le HTML pour chaque jour du planning
        const planningHtml = activitiesData.planning.map((day, index) => {
            const morningActivities = day.morning || [];
            const afternoonActivities = day.afternoon || [];

            // Si les deux sont "vides", ne pas afficher la journée
            if (morningActivities.length === 0 && afternoonActivities.length === 0) {
                return ''; // Ne rien retourner pour cette journée
            }

            // Générer le HTML pour chaque jour
            let dayHtml = `
                <div class="day-header">
                    <span class="day-label">Jour ${index + 1}</span> - ${format(new Date(tripData.departureDate * 1000), 'eee. dd MMM')}
                </div>
            `;

            // Section pour le matin
            if (morningActivities.length > 0) {
                dayHtml += `
                    <div class="section">
                        <h2>Matin</h2>
                `;
                morningActivities.forEach(activity => {
                    dayHtml += `
                        <div class="activity-card">
                            <div class="img-act"><img src="${activity.imageUrl || 'activity-image.jpg'}" alt="${activity.name || 'Activité'}"></div>
                            <div class="activity-details">
                                <h3>${activity.name || 'Nom de l’activité'}</h3>
                                <p>
                                    <img src="img/horloge.png" alt="Temps Icon">
                                    ${activity.duration ? `${activity.duration} minutes` : 'Temps activité'}
                                </p>
                            </div>
                        </div>
                    `;
                });
                dayHtml += '</div>'; // Fermer la section matin
            }

            // Section pour l'après-midi
            if (afternoonActivities.length > 0) {
                dayHtml += `
                    <div class="section">
                        <h2>Après-midi</h2>
                `;
                afternoonActivities.forEach(activity => {
                    dayHtml += `
                        <div class="activity-card">
                            <div class="img-act"><img src="${activity.imageUrl || 'activity-image.jpg'}" alt="${activity.name || 'Activité'}"></div>
                            <div class="activity-details">
                                <h3>${activity.name || 'Nom de l’activité'}</h3>
                                <p>
                                    <img src="img/localisateur.png" alt="Adresse Icon">
                                    ${activity.address || 'Adresse activité'}
                                </p>
                                <p>
                                    <img src="img/horloge.png" alt="Temps Icon">
                                    ${activity.duration ? `${activity.duration} minutes` : 'Temps activité'}
                                </p>
                            </div>
                        </div>
                    `;
                });
                dayHtml += '</div>'; // Fermer la section après-midi
            }

            return dayHtml;
        }).join('');

        // Template HTML
        const template = `
<!DOCTYPE html>
<html lang="fr">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Planning d'activités</title>

    <style>
/* Base styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: white;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.container {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    padding: 10px;
    background-color: #fcfcfc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
}

h1 {
    text-align: center;
    color: #12474b;
    margin-bottom: 20px;
}

.ville {
    text-align: center;
    margin: 0;
    padding-bottom: 30px;
    font-size: 30px;
    font-weight: 400;
    color:#12474b;
}

.day-header {
    font-size: 15px;
    color: #12474b;
    background-color: white;
    font-weight: bold;
    text-align: center;
    border: 1px solid #12474b;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
}

.day-label {
    background-color: #12474b;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    margin-right: 5px;
}

.section {
    margin-bottom: 20px;
}

h2 {
    color: #12474b;
    font-size: 15px;
    margin-bottom: 10px;
}

/* Activity card styles */
.activity-card {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.img-act{
    width: 150px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 25px;
}

.activity-details {
    display: flex;
    flex-direction: column;
}

.activity-details h3 {
    font-size: 13px;
    margin: 0;
    color: #274E4F;
}

.activity-details p {
    margin: 5px 0;
    font-size: 10px;
    color: #666;
}

.activity-details p img {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    margin-right: 5px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .activity-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .activity-card img {
        width: 100%;
        margin-bottom: 10px;
    }
}

    </style>
</head>
<body>

    <div class="container">
        <h1>Mon planning d'activités</h1>
        <p class="ville">${tripData.cityTo || 'Ville inconnue'}</p>
        
        ${planningHtml || '<p>Aucune activité prévue pour ce voyage.</p>'}
    </div>

</body>
</html>`;

        return template;
    } else {
        console.error("No such document!");
        return '<p>Erreur : document non trouvé.</p>';
    }
}
