// storeTransportations.js

import { db } from '../firebase.js';
import { collection, addDoc } from 'firebase/firestore';

// Fonction pour enregistrer les informations de vol
async function saveFlightData(flightData, docRef) {
  try {
    const newDocRef = await addDoc(collection(db, 'flightInformations'), {
      ...flightData,
      linkedDocumentId: docRef // Changement de nom pour éviter la confusion
    });
    console.log(`Informations de vol enregistrées avec l'ID : ${newDocRef.id}`);
    return newDocRef.id; // Retourner l'ID du nouveau document
  } catch (error) {
    console.error("Erreur lors de l'enregistrement des informations de vol :", error);
    throw new Error("Failed to save flight data");
  }
}

// Fonction pour enregistrer les informations de transport terrestre
async function saveGroundTransportationsData(groundTransportations, docRef) {
  try {
    const newDocRef = await addDoc(collection(db, 'groundTransportationsInformations'), {
      groundTransportations, // Enregistrer toutes les informations des trains
      linkedDocumentId: docRef // Champ pour lier le document
    });
    console.log(`Informations de transport terrestre enregistrées avec l'ID : ${newDocRef.id}`);
    return newDocRef.id; // Retourner l'ID du nouveau document
  } catch (error) {
    console.error("Erreur lors de l'enregistrement des informations de transport terrestre :", error);
    throw new Error("Failed to save ground transportations data");
  }
}

export { saveFlightData, saveGroundTransportationsData };
