<template>
  <div style="background-color: var(--light-gray); padding-bottom: 200px; margin-bottom: -200px">
      
    <SeparatorLine />

      <div class="modifyTransport-container">  
        <TransportationsMain :docRef="tripId"
        :isModifying="true"
        :fromProfile="true" />
      </div>
      
  </div>
</template>

<script>
import SeparatorLine from '@/components/SeparatorLine.vue'; 
import TransportationsMain from '@/components/TransportationsMain.vue'; 

export default {
  components: {
    TransportationsMain,
    SeparatorLine 
  },
  props: {
    tripId: {
      type: String,
      required: true
    }
  }
}
</script>


<style scoped>

.modifyTransport-container {
  width: 100%;
  margin: 0vh auto;
  background-color: var(--light-grey) !important;
}

@media (max-width: 768px) {
  .modifyTransport-container {
    margin: 0 auto;
    width: 100%;
  }
}

</style>