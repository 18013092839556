<template>
  <div class="cta-section">
    <div class="cta-content">
      <div class="cta-text">
        <h2>Essayez dès maintenant, Alego !</h2>
        <router-link to="/voyage" class="cta-button-link">
          <button class="cta-button">Planifier</button>
        </router-link>
      </div>
      <div class="cta-image">
        <img src="@/assets/img/landing/mobiles.png" alt="Phones Image">
      </div>
    </div>
  </div>

  <div class="suppliers-section">
    <h2>Nos fournisseurs</h2>
    <div class="suppliers-logos">
      <img src="@/assets/img/landing/tictactrip.png" alt="Tictactrip Logo">
      <img src="@/assets/img/landing/kiwi.png" alt="Kiwi.com Logo">
      <img src="@/assets/img/landing/ratehawk.png" alt="Rate Hawk Logo">
    </div>
  </div>
</template>


<script>
export default {
  name: 'AccueilStep5',

}
</script>


<style scoped>

/* Section CTA (Call to Action) */
.cta-section {
  background-color: var(--jaune); /* Couleur de fond du CTA */
  padding: 60px 20px;
  text-align: center;
  border-radius: 50px;
  width: 80%;
  margin: auto;
}

.cta-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  width: 80%; 
  margin: auto;
}

.cta-text, .cta-image {
  width: 50%; /* Chaque élément prend 50% de la largeur */
}

.cta-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}

.cta-text h2 {
  color: white;
  font-size: 2rem;
  margin: 0;
}

.cta-button {
  background-color: var(--vert);
  color: white;
  border: none;
  padding: 15px 35px;
  border-radius: 50px;
  font-size: 1.5rem;
  cursor: pointer;
  width: 40%;
  margin: auto;
}

.cta-button:hover {
  background-color: #0e2e34;
}

.cta-image img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

/* Section Fournisseurs */
.suppliers-section {
  padding: 100px 30px;
  text-align: center;
}

.suppliers-section h2 {
  color: var(--vert);
  font-size: 2rem;
  margin-bottom: 70px;
}

.suppliers-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  flex-wrap: wrap;
}

.suppliers-logos img {
  max-height: 100px;
  height: auto;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {

  .cta-section {
    border-radius: 0px;
  }

  .cta-content {
    flex-direction: column-reverse; /* Inverser l'ordre pour avoir l'image en premier */
    align-items: center;
    width: 100%;
    max-width: 100vh;
  }

  .cta-text, .cta-image {
    width: 100%; /* Chaque élément prend 100% de la largeur sur mobile */
  }

  .cta-text h2 {
    font-size: 1.2rem;
  }

  .cta-button {
    width: 60%; /* Augmente la largeur du bouton sur mobile */
    padding: 10px 20px; /* Ajuste le padding pour mieux s'adapter à l'écran */
    font-size: 1rem;
  }

  .cta-section {
    padding: 40px 20px; /* Réduit le padding global sur mobile */
    width: 90%; /* Limite la largeur à 90% sur mobile pour qu'elle ne prenne pas toute la largeur */
    max-width: 500px; /* Limite la largeur maximale pour les écrans plus grands mais toujours en mode mobile */
    margin: auto;
  }

  .suppliers-section {
    padding: 20px;
  }

  .suppliers-section h2 {
    font-size: 1.3rem;
    margin: 40px auto;
  }

  .suppliers-logos {
    flex-direction: column; /* Empile les images les unes sous les autres */
    gap: 30px; /* Réduit l'espacement entre les logos */
    margin-bottom: 30px;
  }

  .suppliers-logos img {
    width: 50%; /* Ajuste la largeur des images pour qu'elles soient plus petites */
    height: auto;
  }

}


</style>
